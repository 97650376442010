import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { productVideosAssetSchema } from 'pages/product/schemas';
import { chipmunk } from 'utils/chipmunk';

export const fetchScreeners = (productId: number): Promise<IObject[]> => {
  return chipmunk.run(async ({ unfurl }) => {
    const filters = [
      ['preview_image_id', 'exist'],
      ['classification', 'eq', 'video/screener'],
    ];

    const res = await unfurl('am.asset', 'search', {
      body: {
        product_ancestry_id: productId,
        search: {
          filters,
        },
      },
      schema: productVideosAssetSchema,
    });
    let videos = res.objects.map(({ id, name, classification, duration, preview_image, products, languages }) => {
      return {
        id,
        name,
        classification,
        duration,
        preview_image,
        product_id: products[0].product_id,
        language_name: languages[0].name,
      };
    });
    // uniqSort
    videos.sort((a, b) => a.product_id - b.product_id || a.name.localeCompare(b.name));
    videos = [...new Map(videos.map((video) => [video['id'], video])).values()];
    return videos;
  });
};
