import { Model, Product3Types, Sort } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { errorHandler } from 'utils/error-handler';

export type ItemId = string | number;

export const fetchSeasons = async (rootProductId: ItemId) => {
  const rootProduct = (
    await chipmunk.action(Model.PRODUCTS, 'get', {
      params: { product_ids: [rootProductId] },
      schema: `id, sequence_number, display_title, type`,
    })
  ).object;

  const hasChildren = [Product3Types.SERIES, Product3Types.SEASON].includes(rootProduct.type);

  let descendents = [];

  if (hasChildren) {
    const { objects } = await getNextDescendants([rootProductId]);

    descendents = objects;
  }

  return { rootProduct, descendents };
};

const getNextDescendants = async (product_ids: ItemId[]) => {
  return chipmunk.run(
    async () => {
      return await chipmunk.action(Model.PRODUCTS, 'search', {
        body: {
          sort: Sort.SEQUENCE_NUMBER,
          search: {
            filters: [['ancestor_ids', 'eq', product_ids]],
          },
          schema: `id, title, display_title, parent_id`,
        },
      });
    },
    (e: Error) => errorHandler(e, { title: 'Failed to fetch products descendants' }),
  );
};
