import React from 'react';
import { observer } from 'mobx-react';

import { DeviceSelection, MeetingJoinDetails } from '@mediafellows/react-video-conference';

const DeviceSetup: React.FC = observer(() => {
  return (
    <div className="chime-app chime-app--theme-dark">
      <section id="main">
        <div className="chime-devices__layout">
          <h1 className="chime__header">Device settings</h1>
          <DeviceSelection />
          <MeetingJoinDetails btnClass={'chime__button--gold'} />
        </div>
      </section>
    </div>
  );
});

export default DeviceSetup;
