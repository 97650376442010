import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'store';

const MeetingWrapper = observer(({ children }) => {
  const { conference } = useStore().conferenceStore;
  const bannerMedia = conference?.banner_media;
  const { title } = conference || {};

  return (
    <div className="chime-app chime-app--meeting chime-app--theme-dark">
      <div className="d-flex align-items-center">
        <div className="chime__simple-header-meeting">
          <div className="d-flex">
            <img
              className="chime__simple-header-logo w-25 p-4"
              src="/static-assets/img/logo.svg"
              loading="lazy"
              alt="Lionsgate"
            />

            <span className="d-flex align-items-center">{title}</span>
          </div>
        </div>

        {bannerMedia && <img className="chime__custom-banner" src={bannerMedia} loading="lazy" alt="eOne" />}
      </div>
      <section id="main">
        <div className="chime-meeting__layout">{children}</div>
      </section>
    </div>
  );
});

export default MeetingWrapper;
