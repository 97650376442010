import React from 'react';
import { useLocation } from 'react-router';
import { Routes } from '../../routes';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import './styles.scss';
import { logout } from 'store/session-store/session-store';

export const AccountSidemenu: React.FC = () => {
  const { pathname } = useLocation();

  const handleSignOut = async (): Promise<void> => {
    await logout();
  };

  const menuItems = [
    { title: 'Settings', path: Routes.ACCOUNT_SETTINGS },
    { title: 'Saved lists', path: Routes.SAVED_LISTS },
    { title: 'Recommendations', path: Routes.RECOMMENDATIONS_LIST },
    { title: 'Continue watching', path: Routes.CONTINUE_WATCHING },
    { title: 'Sign Out', action: handleSignOut },
  ];

  return (
    <div className="account-sidemenu row">
      <div className="account-sidemenu__inner col-2 p-6">
        <h4>My Account</h4>
        <ul>
          {menuItems.map(({ title, path, action }, index) => {
            if (action) {
              return (
                <li key={index}>
                  <div role={'button'} onClick={action} className="custom-link">
                    {title}
                  </div>
                </li>
              );
            }

            const isSelected = pathname.includes(path);

            return (
              <li key={index} className={cx({ 'account-sidemenu__item--selected': isSelected })}>
                <Link to={path} className="custom-link">
                  {title}
                </Link>
                {isSelected && <div className="account-sidemenu__glow--selected" />}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
