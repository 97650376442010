import React, { useRef } from 'react';
import { Loading, ProductList } from 'components';
import { useParams } from 'react-router-dom';
import { IVideoplayerApi, VideoPlayer } from '@mediafellows/videoplayer';
import useSWR from 'swr';
import { FetchActions, Product3Types } from 'types';
import { fetchCollections } from 'utils/api/fetch-collections';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { fetchCollectionItems } from 'utils/api/fetch-collection-items';

export const EventPage = () => {
  const player = useRef<HTMLDivElement & IVideoplayerApi>(null);
  const { name } = useParams<{ name }>();
  const { data } = useSWR(FetchActions.HOME_SHOWCASES, () => fetchCollections('home'));
  const { objects: showcases = [] } = data || {};

  const currentEventShowcase = showcases?.filter(
    (showcase: UmGenericListShowcase) => showcase?.purpose.toLowerCase() === name?.toLowerCase(),
  );

  const currentEventShowcaseIds = currentEventShowcase.map((list) => list.id);

  const { data: currentEventShowcaseData, isLoading } = useSWR(
    currentEventShowcaseIds?.length ? `event-showcase-items` : null,
    () => fetchCollectionItems({ listIds: [currentEventShowcaseIds] }),
  );

  const { objects: currentEventShowcaseListItems = [] } = currentEventShowcaseData || {};

  const filteredListItems = currentEventShowcaseListItems.filter((item) => item.entity).map((item) => item.entity);
  const previewAsset = filteredListItems.find((item) => item?.['@type']?.includes('asset'));
  const products = filteredListItems.filter((item) => item?.type?.includes(Product3Types.BASE_TYPE));
  const isVideo = previewAsset?.classification.includes('video');
  const group = currentEventShowcase[0];

  if (!group) {
    return null;
  }

  return (
    <>
      <div className="px-4 pt-11 pt-lg-13 text-center">
        <h3 className="">{group.name}</h3>
      </div>
      <div className="container-fluid--custom">
        <div className="row mb-5">
          <div className="row">
            <div className="col-lg-6 col-12">{group.meta?.description}</div>
          </div>
          <div className="col-md-6 col-12 mx-auto d-block pt-3">
            {isVideo && (
              <VideoPlayer
                ref={player}
                video={previewAsset}
                autoplay={false}
                posterUrl={previewAsset?.preview_image?.url}
              />
            )}

            {!isVideo && <img src={previewAsset?.preview_image?.url} className="img-fluid" />}
          </div>
        </div>
        <div className="row">
          {isLoading && (
            <div className="col-12">
              <Loading />
            </div>
          )}
          {!isLoading && (
            <div className="col-12">
              <ProductList
                showProgress={true}
                products={products}
                total={products.length}
                keyName={'events'}
                containerClass={''}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
