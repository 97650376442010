import React from 'react';
import './interest-selection.scss';

const genreOfInterest = [
  'Action',
  'Adventure',
  'Animation',
  'Comedy',
  'Crime',
  'Cult',
  'Drama',
  'Historical',
  'Horror',
  'Musical',
  'Sci-Fi',
  'War',
  'Westerns',
];

const programs = ['TV Shows', 'Movies'];

export const InterestSelection: React.FC<{ state: any }> = ({ state }) => {
  const [interests, setInterests] = state;

  const handleInterest = (e) => {
    const value = e.target.value;
    let updated = [...interests];

    if (interests.includes(value)) {
      updated = updated.filter((item) => item !== value);
    } else {
      updated.push(value);
    }

    setInterests(updated);
  };

  return (
    <div className="col-12 mb-3 mt-3 interest-selection">
      <div className="mb-6 mt-6">
        <p>Programming of interest</p>
        {programs.map((item) => {
          return (
            <div className="form-check form-check-inline" key={item}>
              <input
                className="form-check-input"
                type="checkbox"
                id={item}
                role="button"
                value={item}
                checked={interests.includes(item)}
                onChange={handleInterest}
              />
              <label className="form-check-label custom-link align-middle" htmlFor={item} role="button">
                {item}
              </label>
            </div>
          );
        })}
      </div>
      <div className="mb-6 mt-6">
        <p>Genres of interest</p>
        {genreOfInterest.map((item) => {
          return (
            <div className="form-check form-check-inline" key={item}>
              <input
                role="button"
                className="form-check-input"
                type="checkbox"
                id={item}
                value={item}
                checked={interests.includes(item)}
                onChange={handleInterest}
              />
              <label className="form-check-label custom-link align-middle" htmlFor={item} role="button">
                {item}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
