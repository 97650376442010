import React from 'react';
import { Route, Redirect } from 'react-router';
import { Routes } from 'routes';
import { useSessionStore } from 'store/session-store/session-store';

const PublicRoute = ({ children, ...rest }) => {
  const session = useSessionStore((state) => state.session);

  const handleRender = () => {
    if (!session || session.isPublic || session.isRecommendation) {
      return children;
    }

    return <Redirect to={Routes.HOME} />;
  };

  return <Route {...rest} render={handleRender} />;
};

export default React.memo(PublicRoute);
