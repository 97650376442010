import { IRequestError } from '@mediafellows/chipmunk/dist/src/request';
import { notifications } from '@mantine/notifications';

interface ErrorHandlerOptions {
  title?: string;
}

// TO-DO
export const errorHandler = (e: IRequestError, options: ErrorHandlerOptions = {}) => {
  const { title = 'Something went wrong!' } = options;

  notifications.show({
    color: 'red',
    title: title,
    message: e?.object?.description || '',
  });
};
