import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Routes } from 'routes';
import { loadSession, logout, useSessionStore } from 'store/session-store/session-store';
import { Model, Recommendation } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { cookie } from 'utils/cookie';
import { errorHandler } from 'utils/error-handler';

interface Error {
  status: number;
  name: string;
  message: string;
}

export const useRecommendation = ({
  token,
}: {
  token: string;
}): { recommendation: Recommendation | undefined; isLoading: boolean; error: string } => {
  const [recommendation, setRecommendation] = useState<Recommendation>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useHistory();
  const [error, setError] = useState('');

  useEffect(() => {
    const validateToken = async () => {
      try {
        const {
          object: { recommendation, session_id },
        } = await chipmunk.action(Model.RECOMMENDATIONS_TOKEN, 'validate', { params: { token } });

        cookie.set('sessionId', session_id);

        chipmunk.updateConfig({
          headers: {
            'Session-Id': session_id,
          },
        });
        await loadSession();
        setRecommendation(recommendation);
        setIsLoading(false);
      } catch (e) {
        const error = e as Error;

        // invalid token
        if (error.status === 404) {
          const errorMessage = 'Invalid recommendation token';
          setError(errorMessage);
          notifications.show({
            title: errorMessage,
            message: null,
            color: 'red',
          });
          setIsLoading(false);
          return;
        }

        // expired/revoked token
        if (error.status === 410) {
          const err = JSON.parse((e as Error)?.message);
          const errorMessage = err?.message;
          setError(errorMessage);
          notifications.show({
            title: errorMessage || null,
            message: null,
            color: 'red',
          });
          setIsLoading(false);
          return;
        }

        // login required
        if (error.status === 419) {
          const session = useSessionStore.getState().session;
          const err = JSON.parse((e as Error)?.message);
          const errorMessage = err?.message;
          setError(errorMessage);

          notifications.show({
            title: 'Please sign in to see your recommendation',
            message: null,
            color: 'green',
          });

          if (session?.isPrivate) {
            await logout();
          }
          navigate.push(Routes.SIGN_IN, { from: window.location.pathname });
          return;
        }
        const err = JSON.parse((e as Error)?.message);
        const errorMessage = err?.message;
        setError(errorMessage);
        errorHandler(error, { title: 'Recommendation failed to validate' });
        setIsLoading(false);
      }
    };

    validateToken();
  }, [token, navigate]);

  return { recommendation, isLoading, error };
};
