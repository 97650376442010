import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Icon } from '../index';
import { Routes } from 'routes';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cx from 'classnames';
import { MAIN_CATEGORY, Modal, OFFCANVAS } from 'types';
import { TvMoviesContent } from './tv-movies-content';
import { AccountContent } from './account-content';
import { useDebounce } from 'utils/hooks/use-debounce';
import './style.scss';
import { SidebarContent } from './sidebar-content';
import { useSearchStore } from 'store/search-store/search-store';
import { applyFilters } from 'store/apply-filters';
import { toggleOffcanvas, useOffcanvasStore } from 'store/offcanvas-store';
import { useDidUpdate } from '@mantine/hooks';
import { useSessionStore } from 'store/session-store/session-store';
import { modals } from '@mantine/modals';

const smallClass = 'body--header-small';
const body = document.body;

type HeaderProps = {
  className?: string;
};

const Header = ({ className }: HeaderProps) => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const session = useSessionStore((state) => state.session);
  const activeName = useOffcanvasStore((state) => state.activeName);
  const offcanvas = useOffcanvasStore((state) => state.offcanvas);
  const { isAuthenticated } = session || {};
  const headerRef = useRef(null);
  const existingSearchValue = useSearchStore((state) => state.filters?.q?.value as string);
  const [searchValue, setSearchValue] = useState(existingSearchValue || '');
  const debouncedSearchValue = useDebounce(searchValue, 250);

  // mobile version
  useEffect(() => {
    if (existingSearchValue) {
      history.push(Routes.SEARCH);
    }
  }, [debouncedSearchValue, existingSearchValue, history, offcanvas]);

  //non-mobile
  useEffect(() => {
    if (debouncedSearchValue) {
      applyFilters({ values: ['q', debouncedSearchValue], store: useSearchStore });

      history.push(Routes.SEARCH);
    } else {
      offcanvas?.hide();
    }
  }, [debouncedSearchValue, history, offcanvas]);

  // reset search on route change
  useDidUpdate(() => {
    if (!pathname.includes(Routes.SEARCH)) {
      applyFilters({ values: ['q', ''], store: useSearchStore });
      setSearchValue('');
    }
  }, [pathname]);

  // const isTransparent =
  //   pathname.includes(Routes.CATEGORY) ||
  //   pathname.includes(Routes.CATALOGUE) ||
  //   pathname.includes(Routes.PRODUCT) ||
  //   pathname.includes(Routes.ACCOUNT_SETTINGS) ||
  //   pathname.includes(Routes.CONTINUE_WATCHING) ||
  //   pathname.includes(Routes.FAVORITES) ||
  //   pathname.includes(Routes.RECOMMENDATIONS_LIST) ||
  //   pathname.includes(Routes.SELECTIONS) ||
  //   pathname.includes(Routes.SELECTION) ||
  //   pathname.includes(Routes.DASHBOARD);

  const isTransparent = true;

  useScrollPosition(
    ({ currPos: { y } }) => {
      if (y !== 0 && !body.classList.contains(smallClass)) {
        body.classList.add(smallClass);
        return;
      }

      if (y === 0 && body.classList.contains(smallClass)) {
        body.classList.remove(smallClass);
      }
    },
    [],
    null,
    false,
    100,
  );

  const getComponent = (name: string): JSX.Element => {
    switch (name) {
      case MAIN_CATEGORY.TV:
      case MAIN_CATEGORY.MOVIES:
        return <TvMoviesContent type={MAIN_CATEGORY[name.toUpperCase()]} />;
      case OFFCANVAS.ACCOUNT:
        return <AccountContent />;
      case OFFCANVAS.SIDEBAR:
        return <SidebarContent />;
      default:
        return <AccountContent />;
    }
  };

  const handleOpenSearch = async (): Promise<void> => {
    modals.openContextModal({
      modal: 'search',
      id: Modal.SEARCH_MODAL,
      innerProps: null,
      withCloseButton: false,
    });
  };

  return (
    <>
      <nav
        id="header"
        ref={headerRef}
        className={cx('header fixed-top animated shadow-sm', className, {
          'header--transparent': isTransparent,
        })}
      >
        <div className="container-fluid--custom header__container d-flex">
          <Link to={Routes.HOME} className="header__logo-wrap position-relative">
            <img className="header__logo" loading="lazy" src="/static-assets/img/logo.svg" alt="Lionsgate Logo" />
          </Link>

          <div className="d-none d-xxl-block header__space-large"></div>
          <div className="d-none d-xl-block header__space-large"></div>
          <div className="d-none d-lg-block d-xl-none header__space-medium"></div>

          <div className="d-none d-lg-flex flex-row">
            {isAuthenticated && (
              <>
                <div
                  className={`custom-link pointer me-5 d-flex align-items-center ${
                    activeName && 'header__menuitem-active'
                  }`}
                >
                  <Link to={Routes.MOVIES} className="custom-link">
                    Movies
                  </Link>
                  <div
                    className="offcanvas-action d-flex align-items-center"
                    onClick={() => toggleOffcanvas(MAIN_CATEGORY.MOVIES, getComponent(MAIN_CATEGORY.MOVIES))}
                  >
                    <div className="header-offcanvas-chevron">
                      <svg
                        width="7"
                        height="6"
                        viewBox="0 0 7 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={cx(activeName === MAIN_CATEGORY.MOVIES && 'header-offcanvas-chevron-up')}
                      >
                        <path d="M3.82227 5.5L0.791177 0.249999L6.85335 0.25L3.82227 5.5Z" fill="white" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div
                  className={`custom-link pointer me-5 d-flex align-items-center ${
                    activeName && 'header__menuitem-active'
                  }`}
                >
                  <Link to={Routes.TV} className="custom-link">
                    Television
                  </Link>
                  <div
                    className="offcanvas-action d-flex align-items-center"
                    onClick={() => toggleOffcanvas(MAIN_CATEGORY.TV, getComponent(MAIN_CATEGORY.TV))}
                  >
                    <div className="header-offcanvas-chevron">
                      <svg
                        width="7"
                        height="6"
                        viewBox="0 0 7 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={cx(activeName === MAIN_CATEGORY.TV && 'header-offcanvas-chevron-up')}
                      >
                        <path d="M3.82227 5.5L0.791177 0.249999L6.85335 0.25L3.82227 5.5Z" fill="white" />
                      </svg>
                    </div>
                  </div>
                </div>

                <Link to={Routes.SAVED_LISTS} className="custom-link">
                  Saved Lists
                </Link>
              </>
            )}
          </div>

          <div style={{ flex: '1' }}></div>

          <div className="text-nowrap d-flex align-items-center justify-content-end">
            {isAuthenticated && (
              <div className="d-flex align-items-center me-4 d-flex d-lg-none" onClick={handleOpenSearch}>
                <Icon name="search" />
              </div>
            )}
            {isAuthenticated && (
              <>
                <div className="d-flex align-items-center d-none d-lg-flex header__search-wrapper">
                  <input
                    placeholder={'Search Lionsgate'}
                    type="text"
                    autoComplete="off"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    className={cx('form-control form-control-sm header__search')}
                  />
                  <Icon name="search" />
                </div>

                <div className="d-none d-xxl-block header__space-large"></div>
                <div className="d-none d-xl-block header__space-large"></div>
                <div className="d-none d-lg-block d-xl-none header__space-small"></div>

                <div
                  className={`custom-link pointer d-inline-flex align-items-center offcanvas-action ${
                    activeName && 'header__menuitem-active'
                  }`}
                  onClick={() => toggleOffcanvas(OFFCANVAS.ACCOUNT, getComponent(OFFCANVAS.ACCOUNT))}
                >
                  <span className="pe-2 d-none d-sm-block">Account</span>
                  <Icon name="person-fill" />

                  <div className="header-offcanvas-chevron">
                    <svg
                      width="7"
                      height="6"
                      viewBox="0 0 7 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={cx(activeName === OFFCANVAS.ACCOUNT && 'header-offcanvas-chevron-up')}
                    >
                      <path d="M3.82227 5.5L0.791177 0.249999L6.85335 0.25L3.82227 5.5Z" fill="white" />
                    </svg>
                  </div>
                </div>

                <div
                  className="d-inline-block pointer text-primary d-lg-none ms-3"
                  onClick={() => toggleOffcanvas(OFFCANVAS.SIDEBAR, getComponent(OFFCANVAS.SIDEBAR))}
                >
                  <Icon name="menu" />
                </div>
              </>
            )}
            {!isAuthenticated && (
              <Link to={Routes.SIGN_IN} className="custom-link">
                Sign In
              </Link>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
