import React, { useCallback, useRef } from 'react';
import { isVideo } from 'utils';
import { VideoPlayer, IVideoplayerApi } from '@mediafellows/videoplayer';
import { Thumbnail } from '../../thumbnail/thumbnail';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import { useSessionStore } from 'store/session-store/session-store';
import { ContextModalProps } from '@mantine/modals';
import { fetchVideoURL } from 'utils/api/fetch-video-url';
import useSWR from 'swr';
import { FetchActions } from 'types';

export const PreviewAssetModal = ({ context, id, innerProps: { product } }: ContextModalProps<{ product: any }>) => {
  const player = useRef<HTMLDivElement & IVideoplayerApi>(null);
  const asset = product?.asset;
  const user = useSessionStore((state) => state.user);
  const { data: videoURL } = useSWR(asset ? [FetchActions.ASSET_VIDEO_URL, asset.id] : null, () =>
    fetchVideoURL(asset.id),
  );
  const url = videoURL?.url || videoURL?.hls;

  const onInitialized = useCallback((): void => {
    if (product?.timecode && player?.current) {
      player.current.seek(product.timecode, true);
    }
  }, [product]);

  return (
    <div className="modal-body">
      <div className="d-flex align-items-center mb-3">
        <div className="flex-1 me-3 text-white">
          <Link className="text-decoration-none" to={`${Routes.PRODUCT}/${product.id}`}>
            <h3 className="text-truncate mb-3">{product?.display_title}</h3>
          </Link>
          <p className="mb-0">{asset?.name}</p>
        </div>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={() => context.closeModal(id)}
        />
      </div>
      {asset && isVideo(asset) && (
        <div className="position-relative">
          {url && !url?.includes('watermark-cdn') && user && !user?.disable_watermark && (
            <span className="email-watermark">{user.email}</span>
          )}
          <VideoPlayer ref={player} video={asset} onInitialized={onInitialized} autoplay={true}></VideoPlayer>
        </div>
      )}

      {asset && !isVideo(asset) && <Thumbnail showOverlay={false} image={asset.preview_image.url} />}
    </div>
  );
};
