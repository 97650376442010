import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonSpinner, Icon } from 'components';
import cx from 'classnames';
import { useSearchStore } from 'store/search-store/search-store';
import { applyFilters } from 'store/apply-filters';
import { ContextModalProps } from '@mantine/modals';

const SearchModal = ({ context, id }: ContextModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const existingSearchValue = useSearchStore.getState().filters?.q?.value as string;
  const [searchValue, setSearchValue] = useState(existingSearchValue || '');

  useEffect(() => {
    if (!searchValue) return;
    setValue('search', searchValue);
  }, [searchValue, setValue]);

  const submitCallback = async ({ search }) => {
    setSearchValue(search);
    applyFilters({ values: ['q', search], store: useSearchStore });
    context.closeModal(id);
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title"></h5>
        <div className="close" aria-label="Close" onClick={() => context.closeModal(id)}>
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="modal-body px-7 pb-3">
        <form id="editListForm" noValidate={true} onSubmit={handleSubmit(submitCallback)}>
          <div className="form-group">
            <label htmlFor="list-name" className="form-label">
              Search
            </label>
            <input
              type="text"
              placeholder={'Search'}
              {...register('search', {
                required: true,
              })}
              className={cx('form-control', { 'is-invalid': errors?.search })}
              id="list-name"
            />
            {errors?.search && (
              <div className="invalid-feedback">
                {errors?.search?.type === 'required' && 'Search text is required.'}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer d-flex justify-content-center mb-3 mt-3">
        <div className="d-flex justify-content-center flex-column text-center">
          <button type="submit" form="editListForm" className="btn btn-lg btn-outline-primary">
            <ButtonSpinner title="Search" isLoading={false} />
          </button>
        </div>
      </div>
    </>
  );
};

export default SearchModal;
