import React, { useState } from 'react';
import { ButtonSpinner, Icon } from 'components';
import cx from 'classnames';
import { chipmunk } from 'utils';
import './style.scss';
import { ContextModalProps } from '@mantine/modals';

const isValidPassword = (value: string) => {
  return /^[a-z0-9#_\-!]+$/gi.test(value);
};

const doChangePassword = async (
  user_id: any,
  current_password: string,
  password: string,
  password_confirmation: string,
): Promise<boolean> => {
  try {
    const res = await chipmunk.action('um.user/password', 'update', {
      params: { user_id },
      body: {
        current_password,
        password,
        password_confirmation,
      },
      ROR: true,
    });

    console.info(res, typeof res);

    return true;
  } catch (e) {}

  return false;
};

const ChangePasswordModal = ({ context, id, innerProps: { userId } }: ContextModalProps<{ userId }>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [changePasswordError, setChangePasswordError] = useState(false);

  const canUpdate = Boolean(currentPassword && newPassword && confirmPassword);

  const currentPasswordError = Boolean(currentPassword && !isValidPassword(currentPassword));
  const newPasswordError = Boolean(newPassword && !isValidPassword(newPassword));
  const confirmPasswordError = newPassword && confirmPassword && newPassword !== confirmPassword;

  const doSetCurrentPassword = (value: string) => {
    setChangePasswordError(false);
    setCurrentPassword(value);
  };

  const changePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    const result = await doChangePassword(userId, currentPassword, newPassword, confirmPassword);

    if (!result) {
      setChangePasswordError(true);
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title"></h5>
        <div className="close" aria-label="Close" onClick={() => context.closeModal(id)}>
          <Icon name="x-lg" />
        </div>
      </div>

      <div className="modal-body px-7">
        <form id="changePasswordForm" noValidate={true} onSubmit={changePassword}>
          <div className="form-group">
            <p>Change password</p>

            <div>
              <label htmlFor="current-password" className="form-label">
                Current password
              </label>
              <input
                type="password"
                id="current-password"
                placeholder="Current password"
                value={currentPassword}
                disabled={isLoading}
                onChange={(e) => doSetCurrentPassword(e.target.value)}
                className={cx('form-control', { 'is-invalid': currentPasswordError || changePasswordError })}
              />

              {currentPasswordError && <div className="invalid-feedback">Invalid password</div>}
              {changePasswordError && <div className="invalid-feedback">Error changing password</div>}
            </div>

            <div>
              <label htmlFor="new-password" className="form-label">
                New password
              </label>

              <input
                type="password"
                id="new-password"
                placeholder="New password"
                value={newPassword}
                disabled={isLoading}
                onChange={(e) => setNewPassword(e.target.value)}
                className={cx('form-control', { 'is-invalid': newPasswordError })}
              />
              {newPasswordError && <div className="invalid-feedback">Invalid password</div>}
            </div>

            <div>
              <label htmlFor="confirm-password" className="form-label">
                Confirm password
              </label>

              <input
                type="password"
                id="confirm-password"
                placeholder="Confirm password"
                value={confirmPassword}
                disabled={isLoading}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={cx('form-control', { 'is-invalid': confirmPasswordError })}
              />

              {confirmPasswordError && <div className="invalid-feedback">Passwords do not match</div>}
            </div>
          </div>
        </form>
      </div>

      <div className="modal-footer d-flex justify-content-center">
        <div className="d-flex justify-content-center flex-column text-center">
          <button
            type="submit"
            form="changePasswordForm"
            disabled={!canUpdate || isLoading}
            className="btn btn-lg btn-outline-primary"
          >
            <ButtonSpinner title={'Change password'} isLoading={isLoading} />
          </button>
          <a
            href="#"
            className="custom-link my-4"
            onClick={(e) => {
              e.preventDefault();
              context.closeModal(id);
            }}
          >
            Cancel
          </a>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordModal;
