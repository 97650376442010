import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { productVideosAssetSchema } from 'pages/product/schemas';
import { chipmunk } from 'utils/chipmunk';

const mapVideos = (assets) => {
  return assets.map((asset) => {
    return {
      product_id: asset.products?.[0]?.product_id ?? null,
      language_name: asset.languages?.[0]?.name ?? null,
      id: asset.id,
      name: asset.name,
      classification: asset.classification,
      duration: asset.duration,
      preview_image: asset.preview_image,
    };
  });
};

export const fetchOtherVideos = (
  seasons: IObject[],
  allVideos: IObject[],
): Promise<{ screenerOtherVideos: IObject[]; trailerOtherVideos: IObject[] }> => {
  return chipmunk.run(async ({ unfurl }) => {
    const promises = [];
    const screenerOtherVideos: IObject[] = [];
    const trailerOtherVideos: IObject[] = [];
    const filters = [
      ['preview_image_id', 'exist'],
      ['main_classification', 'eq', 'video'],
    ];
    seasons.map((season) =>
      promises.push(
        unfurl('am.asset', 'search', {
          body: {
            product_ancestry_id: season.id,
            search: {
              filters,
            },
          },
          schema: productVideosAssetSchema,
        }),
      ),
    );
    const data = await Promise.all(promises);
    const seasonVideosIds = data.flatMap((item) => item.objects).map((item) => item.id);
    const seriesOnlyVideos = allVideos.filter((video) => !seasonVideosIds.includes(video.id));
    const videos = mapVideos(seriesOnlyVideos).sort(
      (a, b) => a.product_id - b.product_id || a.name.localeCompare(b.name),
    );
    videos.map((video) => {
      console.log(video.classification);
      if (video.classification === 'video/screener') {
        return screenerOtherVideos.push(video);
      }
      return trailerOtherVideos.push(video);
    });
    return { screenerOtherVideos, trailerOtherVideos };
  });
};
