import { get, toInteger } from 'lodash';
import { storageFetch, storageDrop } from '@mediafellows/chipmunk';

import { rememberLocation } from './path';
import { chipmunk } from 'utils/chipmunk';
import { Routes } from 'routes';
import { loadSession, useSessionStore } from 'store/session-store/session-store';
import { notifications } from '@mantine/notifications';
import { history } from 'utils/history';

export class RecommendationCheckError extends Error {
  constructor(m: string) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RecommendationCheckError.prototype);
  }
}

export const setCampaign = async () => {
  const session = useSessionStore.getState().session;

  const campaignId = toInteger(storageFetch('campaignId'));

  const sessionCampaignId = session?.campaign_id;

  if (campaignId && campaignId !== sessionCampaignId) {
    await chipmunk.run(
      async (chipmunk) => {
        await chipmunk.action('um.session', 'assign_campaign', {
          body: { campaign_id: campaignId },
        });
        await loadSession();
        storageDrop('campaignId');
      },
      () => {
        console.warn('failed to set campaign id');
      },
    );
  }
};

export interface ILocation {
  pathname: string;
  search: string;
  [x: string]: any;
}

export const recommendationParamsCheck = async (location: ILocation) => {
  rememberLocation(location);
  useSessionStore.setState({ is2FARecoRedirect: true });
  await setCampaign();
};

export const recommendationValidityCheck = (recommendation: any) => {
  const session = useSessionStore.getState().session;

  if (!recommendation) {
    notifications.show({
      message:
        'This Recommendation is not valid for your user account. Please request a personal link to view this content.',
      color: 'red',
    });
    history.push(Routes.HOME);
    throw new RecommendationCheckError('recommendation not available');
  }

  if (recommendation.views_left === -1 || new Date(recommendation && recommendation.expires_at) < new Date()) {
    notifications.show({
      message: "We're sorry, this link is no longer valid. Please ask for a new recommendation.",
      color: 'red',
    });
    history.push(Routes.HOME);
    throw new RecommendationCheckError('recommendation no longer valid');
  }

  const noLoginRequired = !get(recommendation, 'campaign.require_login');
  if (!session.isAuthenticated && !noLoginRequired) {
    notifications.show({
      message: 'You have to login to view this recommendation',
      color: 'red',
    });
    history.push(Routes.HOME);
    throw new RecommendationCheckError('recommendation requires login');
  }
};
