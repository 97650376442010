import { chipmunk } from 'utils';
import { Model } from 'types';
import { errorHandler } from 'utils/error-handler';

export const fetchCollections = (page: string) =>
  chipmunk.run(
    () =>
      chipmunk.action(Model.LISTS, 'search', {
        body: {
          search: { filters: [['meta.page', 'eq', page]] },
          sort: 'sequence_number',
        },
      }),
    (e: Error) => errorHandler(e, { title: ' Fail to load showcases' }),
  );
