import { map, assign } from 'lodash';

// TO-DO: Figure out if this is still needed
export const formatBasketData = (data: any) => {
  const requestStorageItem = localStorage.getItem('orf-requested-baskets');
  const requestedBaskets = requestStorageItem && JSON.parse(requestStorageItem);
  let basketData = data.filter((b) => b.name !== 'Main Basket');

  basketData = map(basketData, (basket) =>
    assign(basket, {
      isMainBasket: basket.name === 'Main Basket',
      name: basket.name === 'Main Basket' ? 'Default Wishlist' : basket.name,
      locked_at: basket.locked_at || (requestStorageItem && requestedBaskets.hasOwnProperty(basket.id)),
    }),
  );

  basketData.sort((a, b) => a.id - b.id);
  return basketData;
};
