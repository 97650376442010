import React from 'react';
import { useParams } from 'react-router-dom';
import { Loading, ProductShow } from 'components';
import useSWR from 'swr';
import { FetchActions } from 'types';
import { fetchAllProductData } from 'utils/api/fetch-all-product-data';

const Product = () => {
  const { productId } = useParams<{ productId: string }>();
  const { data, isLoading, mutate } = useSWR(`${FetchActions.PRODUCT}-${productId}`, () =>
    fetchAllProductData(Number(productId), 'product'),
  );
  const productData = data || {};

  if (isLoading) {
    return (
      <section className="container pt-10">
        <div className="row">
          <Loading />
        </div>
      </section>
    );
  }

  return <ProductShow productData={productData} mutate={mutate} />;
};

export default Product;
