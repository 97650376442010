import React, { useState } from 'react';
import { ButtonSpinner, Icon } from 'components';
import { ContextModalProps } from '@mantine/modals';

interface ConfirmationModalProps {
  title?: string;
  cancelText?: string;
  hasCancelButton?: boolean;
  bodyText?: string;
  confirmText?: string;
  confirmAction?: () => Promise<boolean>;
}

const ConfirmationModal = ({
  context,
  id,
  innerProps: {
    title,
    bodyText,
    confirmText = 'Confirm',
    confirmAction,
    cancelText = 'Cancel',
    hasCancelButton = true,
  },
}: ContextModalProps<ConfirmationModalProps>) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);

    const result = await confirmAction?.();

    if (result) {
      context.closeModal(id);
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <div className="close" aria-label="Close" onClick={() => context.closeModal(id)}>
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="modal-body px-7 text-center pb-3">{bodyText}</div>
      <div className="modal-footer d-flex justify-content-center pt-2">
        <div className="d-flex justify-content-center flex-column text-center">
          <button disabled={isLoading} onClick={handleConfirm} className="btn btn-lg btn-outline-primary">
            <ButtonSpinner title={confirmText} isLoading={isLoading} />
          </button>
          {hasCancelButton && (
            <a
              href="#"
              className="custom-link my-4"
              onClick={(e) => {
                e.preventDefault();
                context.closeModal(id);
              }}
            >
              {cancelText}
            </a>
          )}
        </div>
      </div>
    </>
  );
};
export default ConfirmationModal;
