import { notifications } from '@mantine/notifications';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { Model } from 'types';
import { chipmunk } from 'utils/chipmunk';

export const fetchRecommendations = (): Promise<IObject[]> => {
  return chipmunk.run(
    async (chipmunk) => {
      const res = await chipmunk.action(Model.RECOMMENDATIONS, 'received', {
        params: {
          sort: 'created_at',
          order: 'desc',
        },
        schema: `remaining_views, user_id, product_ids, id, subject, created_at, expires_at, recommendation_group_id, token`,
      });

      // do not show expired recommendations
      const filteredRecommendations = res.objects.filter(
        (item) => item.remaining_views !== -1 || new Date(item.expires_at) < new Date(),
      );

      return filteredRecommendations;
    },
    () => {
      notifications.show({
        message: 'Failed to load recommendations',
        color: 'red',
      });
    },
  );
};
