import { IResult } from '@mediafellows/chipmunk';
import { chipmunk, tuco } from 'utils';
import { productSchema, productVideosAssetSchema } from 'pages/product/schemas';
import { errorHandler } from 'utils/error-handler';

interface fetchCollectionItemsParams {
  listIds: (string | number)[];
  per?: number;
  page?: number;
}

export const fetchCollectionItems = (params: fetchCollectionItemsParams): Promise<IResult> => {
  const { listIds = [], per = 100, page = 1 } = params;

  return chipmunk.run(
    () =>
      tuco('getListItems', {
        listIds,
        per,
        page,
        productSchema,
        assetSchema: productVideosAssetSchema,
      }),
    (e: Error) => errorHandler(e, { title: 'Fail to load showcase data' }),
  );
};
