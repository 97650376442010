import Cookies from 'cookies-js';
import merge from 'lodash/merge';

interface ICookieOpts {
  path?: string;
  domain?: string;
  expires?: number;
  secure?: boolean;
}

declare let APP_ENV;

const defaultOpts: ICookieOpts = {
  expires: 30 * 24 * 60 * 60, // 1 month
  secure: APP_ENV !== 'development',
};

export const cookie = {
  set: (name, value, opts: ICookieOpts = {}) => {
    opts = merge({}, defaultOpts, opts);
    return Cookies.set(name, value, opts);
  },

  get: (name) => {
    return Cookies.get(name);
  },

  remove: (...names: string[]) => {
    names.forEach((name) => Cookies.expire(name));
  },
};
