import { useEffect } from 'react';
import { get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

import { chipmunk, cookie, getSession, queryStringParams } from 'utils';
import { clear, loadSession } from 'store/session-store/session-store';
import { notifications } from '@mantine/notifications';

export const Impersonate = () => {
  const history = useHistory();

  const { sessionId, redirect } = useParams<{ sessionId: string; redirect: string }>();
  useEffect(() => {
    (async () => {
      try {
        const adminUiRef = get(queryStringParams(document.location), 'ref');

        clear();

        cookie.set('sessionId', sessionId);
        if (adminUiRef) cookie.set('adminUiRef', adminUiRef);

        chipmunk.updateConfig({ headers: { 'Session-Id': sessionId } });
        await getSession(); // raises an error when unsuccessful without trying to get a public session

        loadSession();
      } catch (err) {
        notifications.show({
          message: 'Impersonate failed',
          color: 'red',
        });
      } finally {
        const [target, id] = redirect ? redirect?.split(':') : [];

        switch (target) {
          case 'product':
            history.push(`/product/${id}`);
            break;

          default:
            history.push('/');
        }
      }
    })();
  });

  return null;
};
