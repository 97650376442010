import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonSpinner, Icon } from 'components';
import { Modal } from 'types';
import cx from 'classnames';
import { chipmunk } from 'utils';
import { notifications } from '@mantine/notifications';
import { ContextModalProps, modals } from '@mantine/modals';

const EditListModal = ({
  context,
  id,
  innerProps: { basket, callbackProductId, mutateBaskets = null, onSave = null, openSaveToList = false },
}: ContextModalProps<{
  basket?: any;
  openSaveToList?: boolean;
  callbackProductId?: string;
  onSave?: () => void;
  mutateBaskets?: () => void;
}>) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isEdit = !!basket;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!basket) return;
    setValue('listName', basket.name);
  }, [basket, setValue]);

  const submitCallback = async (data) => {
    const name = data?.listName;

    if (isEdit) {
      const newBasket = { ...basket, name };
      setIsLoading(true);
      await chipmunk.run(async (chipmunk) => {
        return chipmunk.action('pm.basket', 'member.update', {
          params: { basket_id: basket.id },
          body: newBasket,
        });
      });
      setIsLoading(false);
      notifications.show({
        message: 'List updated',
        color: 'green',
      });
    } else {
      setIsLoading(true);
      await chipmunk.action('pm.basket', 'create', {
        body: { name },
      });
      mutateBaskets?.();
      setIsLoading(false);
      notifications.show({
        message: 'List created',
        color: 'green',
      });
    }

    if (openSaveToList) {
      context.closeModal(id);
      modals.openContextModal({
        modal: 'saveToList',
        id: Modal.SAVE_TO_LIST,
        innerProps: {
          productId: callbackProductId,
        },
        withCloseButton: false,
      });
    } else {
      context.closeModal(id);
      onSave?.();
    }
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title"></h5>
        <div className="close" aria-label="Close" onClick={() => context.closeModal(id)}>
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="modal-body px-7 pb-3">
        <form id="editListForm" noValidate={true} onSubmit={handleSubmit(submitCallback)}>
          <div className="form-group">
            {!isEdit && <p>Create new list</p>}
            {isEdit && <p>Edit list name</p>}
            <label htmlFor="list-name" className="form-label">
              List name
            </label>
            <input
              type="text"
              placeholder={isEdit ? 'Custom list title' : 'Ex. My new favorites'}
              {...register('listName', {
                required: true,
              })}
              className={cx('form-control', { 'is-invalid': errors?.listName })}
              id="list-name"
            />
            {errors?.listName && (
              <div className="invalid-feedback">
                {errors?.listName?.type === 'required' && 'List name is required.'}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer d-flex justify-content-center mt-3">
        <div className="d-flex justify-content-center flex-column text-center">
          <button type="submit" form="editListForm" disabled={isLoading} className="btn btn-lg btn-outline-primary">
            <ButtonSpinner title={isEdit ? 'Save Changes' : 'Create'} isLoading={isLoading} />
          </button>
          <a
            href="#"
            className="custom-link my-4"
            onClick={(e) => {
              e.preventDefault();
              context.closeModal(id);
            }}
          >
            Cancel
          </a>
        </div>
      </div>
    </>
  );
};
export default EditListModal;
