import React from 'react';
import { IconName } from '../icon/icon';
import { Icon } from '../index';

export const ButtonSpinner: React.FC<{ title: string; isLoading: boolean; icon?: IconName }> = (props) => {
  const { title, isLoading, icon } = props;
  return (
    <div className="d-flex align-items-center">
      {!isLoading && icon && <Icon name={icon} width={18} height={18} className="me-3" />}
      {isLoading && <div className="spinner-border spinner-border-sm text-white me-3" role="status" />}
      <span>{title}</span>
    </div>
  );
};
