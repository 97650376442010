import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HorizontalThumbs, Loading, ProductShow } from 'components';

import { FetchActions } from 'types';
import useSWR from 'swr';
import { fetchAllProductData } from 'utils/api/fetch-all-product-data';
import { useRecommendation } from 'utils/hooks/use-recommendation';
import { Flex, Title } from '@mantine/core';
import { recommendationParamsCheck } from 'utils/recommendations';
import { fetchRecommendationProducts } from 'utils/api/fetch-recommendation-products';
import { Routes } from 'routes';
import { getThumbText } from 'utils/general';

const RecommendationsProduct = () => {
  const history = useHistory();
  const { location } = history;

  const { productId, recommendationId = '' } = useParams<{ recommendationId: string; productId: string }>();

  const { recommendation, error, isLoading: isLoadingRecommendation } = useRecommendation({ token: recommendationId });

  useEffect(() => {
    (async () => {
      await recommendationParamsCheck(location);
    })();
  }, [location]);

  const { data, isLoading, mutate } = useSWR(
    recommendation && productId ? [`${FetchActions.ALL_PRODUCT_DATA}-${productId}`, recommendation, productId] : null,
    ([, recommendation, productId]) => fetchAllProductData(Number(productId), 'recommendation', recommendation),
  );

  const { data: overviewProducts = [] } = useSWR(
    recommendation && !productId ? [FetchActions.RECOMMENDATION_PRODUCTS, recommendation.product_ids] : null,
    ([, productIds]) => fetchRecommendationProducts(productIds),
  );

  const productData = data || {};

  if (isLoading || isLoadingRecommendation) {
    return (
      <section className="container pt-10">
        <div className="row">
          <Loading />
        </div>
      </section>
    );
  }

  if (!productId && recommendation) {
    return (
      <div className="container-fluid--custom pt-13 pt-lg-14">
        <Title>{recommendation.subject}</Title>
        <div className="col-12 mt-5 mt-lg-8 order-lg-last">
          <HorizontalThumbs
            scale="vga"
            onClick={(i, product_id) =>
              history.push(`${Routes.RECOMMENDATIONS_PRODUCT}/${recommendationId}/${product_id}`)
            }
            items={overviewProducts}
            texts={overviewProducts.map((item) => getThumbText(item))}
          />
        </div>
      </div>
    );
  }

  if (recommendation && productData.product) {
    return <ProductShow recommendation={recommendation} productData={productData} mutate={mutate} />;
  }

  if (error) {
    return (
      <Flex justify="center" align="center" mih={500}>
        <Title order={4} className="mb-3">
          This recommendation has expired or is invalid.
        </Title>
      </Flex>
    );
  }
};

export default RecommendationsProduct;
