import React from 'react';
import { Modal } from 'types';
import { ButtonSpinner } from 'components';
import { modals } from '@mantine/modals';

type ProductShowTitleProps = {
  className?: string;
  product: Record<string, any>;
  duration?: string;
};

const ProductShowTitle = ({ className, product, duration }: ProductShowTitleProps) => {
  const productId = product.id;

  const handleSaveToList = () => {
    modals.openContextModal({
      modal: 'saveToList',
      id: Modal.SAVE_TO_LIST,
      innerProps: {
        productId,
      },
      withCloseButton: false,
    });
  };

  const { default_layer, title } = product || {};
  const { year_of_production } = default_layer.meta || {};

  return (
    <div className={className}>
      <div className="d-flex">
        <h2 className="product-show__title mb-1 text-white">{title}</h2>
        <button type="submit" className="product-show__button btn ms-2" onClick={handleSaveToList} title="Add to list">
          <ButtonSpinner icon={'star-fill'} title="Add to list" isLoading={false} />
        </button>
      </div>
      <div className="d-flex gap-3 mb-3">
        {[year_of_production, duration]
          .filter((x) => x)
          .map((x, index) => (
            <div key={index}>{x}</div>
          ))}
      </div>
    </div>
  );
};

export default ProductShowTitle;
