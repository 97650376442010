import { assetListSchema } from '@mediafellows/tuco/dist/lib/schemas';
import { productSchema } from 'pages/product/schemas';
import { chipmunk, tuco } from 'utils/chipmunk';
import { errorHandler } from 'utils/error-handler';

export const fetchContinueWatching = () => {
  return chipmunk.run(
    async (chipmunk) => {
      const productData = await tuco('getVideoContinuations', { useMM3: true, productSchema: productSchema });
      const { objects: products = [] } = productData || {};

      const assetData = await chipmunk.action('am.asset', 'get', {
        params: { asset_ids: products.map((a) => a.videoId) },
        schema: assetListSchema,
      });
      const { objects: assets = [] } = assetData || {};

      return products.map((product) => {
        const asset = assets.find(({ id }) => id === product.videoId) || {};

        return {
          ...product,
          asset,
          classification: asset.classification,
          ['@type']: asset['@type'],
        };
      });
    },
    (e: Error) => {
      errorHandler(e, { title: 'failed to load continue watching' });
    },
  );
};
