import { notifications } from '@mantine/notifications';
import { connect as pigeonConnect, enableUserNotifications, addListener } from '@mediafellows/pigeon2-client';
import { NotificationsClassname } from 'types';

import { chipmunk } from 'utils/chipmunk';

export const pigeon = async (userId?: string): Promise<void> => {
  await pigeonConnect(chipmunk);
  if (userId) enableUserNotifications();

  addListener('message', ({ message, type }) => {
    switch (type) {
      case 'success':
        notifications.show({
          message,
          className: NotificationsClassname.SUCCESS,
        });
        break;

      case 'warning':
        notifications.show({
          message,
          color: 'red',
        });
        break;

      case 'error':
        notifications.show({
          message,
          color: 'red',
        });
        break;
    }
  });
};
