import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import cx from 'classnames';
import './form-box.scss';

export const FormBox = ({
  children,
  colClass = 'col-7',
  showLogo = true,
}: {
  children: React.ReactNode;
  colClass?: string;
  showLogo?: boolean;
}) => {
  return (
    <div className={cx('form-box container-fluid mt-13')}>
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="row justify-content-center">
            {showLogo && (
              <div className="col-12">
                <div className="text-center">
                  <Link to={Routes.SIGN_IN}>
                    <img
                      loading="lazy"
                      className="form-box__logo"
                      src="/static-assets/img/logo-distribution.svg"
                      alt="Lionsgate"
                    />
                  </Link>
                </div>
              </div>
            )}
            <div className={colClass}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
