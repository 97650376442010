import { chipmunk } from 'utils';

export const fetchBasketData = (userId: number | string) => {
  const params: { [key: string]: number | string } = {};
  if (typeof userId === 'number') {
    params.owner_id = userId;
  }
  return chipmunk.run(
    () =>
      chipmunk.action('pm.basket', 'get', {
        schema: `id, name, main, products_count, created_at, updated_at, locked_at`,
        params,
      }),
    (error: Error) => console.error(error),
  );
};
