import React, { useCallback } from 'react';
import { getThumbText } from 'utils';
import { FeaturedThumbnail, HorizontalThumbs, Loading } from 'components';
import { Routes } from 'routes';
import { FetchActions, Modal, Product, Product3Types } from 'types';
import { Link, useHistory } from 'react-router-dom';

import './style.scss';
import { useSessionStore } from 'store/session-store/session-store';
import useSWR from 'swr';
import { fetchContinueWatching } from 'utils/api/fetch-continue-watching';
import { modals } from '@mantine/modals';
import { fetchCollections } from 'utils/api/fetch-collections';
import { fetchCollectionItems } from 'utils/api/fetch-collection-items';
import { HeroCarousel } from '../../components/hero-carousel/hero-carousel';

export const Home = () => {
  const user = useSessionStore((state) => state.user);
  const history = useHistory();

  // these are 'home' showcases (also known as collection - collections, showcases - same thing)
  // you can find them here - https://lgt.mediastore.dev/website-home
  const { data } = useSWR(FetchActions.HOME_SHOWCASES, () => fetchCollections('home'));
  const { objects: showcases = [] } = data || {};

  // mobile collections are only used in the mobile app
  const filtered = showcases.filter((list) => !list.purpose.includes('mobile'));
  const filteredIds = filtered.map((list) => list.id);

  // separate hero and other home collections
  const heroCollection = filtered.find((list) => list.purpose.includes('hero'));
  const homeCollections = filtered.filter((list) => !list.purpose.includes('hero'));

  // load listItems from all home collections
  // each collection can accept different item types (check in the admin) - products, assets, news and more and more
  // in `home` collections we have a mix of product and asset entities
  const { data: showcaseItemsData } = useSWR(filteredIds?.length ? `homepage-showcase-items` : null, () =>
    fetchCollectionItems({ listIds: [filteredIds] }),
  );

  const { objects: homeShowcaseListItems = [] } = showcaseItemsData || {};

  // there is a BE bug - if you change items in showcases, entity (product or asset) of old items will be null
  const filteredListItems = homeShowcaseListItems.filter((item) => item.entity);
  const homeListItems = filteredListItems.filter((item) => item.list_id !== heroCollection?.id);
  const heroListItems = filteredListItems.filter((item) => item.list_id === heroCollection?.id);

  // hero showcase contains only asset list items
  const heroAssets = heroListItems.map((item) => item.entity);

  // check fetchVideoContinuations in gcui - might be better
  const { data: continueWatching } = useSWR(FetchActions.CONTINUE_WATCHING, fetchContinueWatching);

  const handleThumbClick = useCallback(
    (_: number, product_id) => {
      history.push(`${Routes.PRODUCT}/${product_id}`);
    },
    [history],
  );

  const handleContinueWatching = useCallback(
    (_: number, id) => {
      const product = continueWatching.find((item) => item.id === Number(id));
      modals.openContextModal({
        modal: 'previewAssets',
        id: Modal.PREVIEW_ASSETS,
        innerProps: {
          product,
        },
        withCloseButton: false,
      });
    },
    [continueWatching],
  );

  if (!user)
    return (
      <div className="mt-15">
        <Loading />
      </div>
    );

  return (
    <>
      <div className="home-page container-fluid--custom mt-13">
        <div className="home-page__hero">
          <div className="row">
            <div className="col-6">
              <h3>Welcome, {user?.first_name}</h3>
            </div>
            {Boolean(heroAssets?.length) && <HeroCarousel assets={heroAssets} />}
          </div>
          <div className="row d-flex align-items-end h-100">
            <div className="d-none col-12 col-lg-6">
              We hope that you will enjoy exploring our catalogue, where you can watch trailers and screeners from any
              browser, whether desktop or mobile. You can create and share lists of your favorites, or browse packages
              recommended to you personally.
            </div>
          </div>
        </div>
        <div className="d-none row row-cols-1 row-cols-sm-2 mb-10">
          {heroAssets?.map((asset, index) => {
            if (index === 0) return null;

            const product = asset.products?.[0] as Product;

            return (
              <div className="col" key={`featured-${index}`}>
                <FeaturedThumbnail
                  showCategory={true}
                  item={product}
                  imgUrl={asset?.preview_image?.url}
                  onClick={() => handleThumbClick(index, { id: asset?.product?.id })}
                />
              </div>
            );
          })}
        </div>

        {Boolean(continueWatching?.length) && (
          <>
            <div className="row mt-6">
              <div className="d-flex align-items-baseline">
                <h3 className="text-capitalize mb-2">Continue Watching</h3>
                <Link className="mx-3 view-all-link" to={`${Routes.CONTINUE_WATCHING}`}>
                  View All
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <HorizontalThumbs
                  showVideoProgress={true}
                  scale="vga"
                  onClick={handleContinueWatching}
                  items={continueWatching.slice(0, 7)}
                  texts={continueWatching.slice(0, 7).map((item) => getThumbText(item))}
                />
              </div>
            </div>
          </>
        )}

        {homeCollections?.map(({ name, id, purpose }) => {
          const products = homeListItems
            .filter((item) => item.list_id === id)
            .map((item) => item.entity)
            .filter((item) => item?.type?.includes(Product3Types.BASE_TYPE));

          if (!products.length) return null;

          return (
            <div className="row mt-6" key={`home-collection-${id}`}>
              <div className="col-12">
                <div className="row">
                  <div className="d-flex align-items-baseline">
                    <h3 className="text-capitalize mb-2">{name}</h3>
                    <Link className="mx-3 view-all-link" to={`${Routes.EVENTS}/${purpose}`}>
                      View All
                    </Link>
                  </div>
                </div>
                <HorizontalThumbs
                  scale="vga"
                  onClick={handleThumbClick}
                  items={products}
                  texts={products.map((item) => getThumbText(item, false))}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
