import { StoreApi, UseBoundStore } from 'zustand';
import { ZSearchStore } from 'store/search-store/search-store';
import { PmLgtLayer3MotionPictureStandard } from '@mediafellows/mm3-types/generated/definitions/pm.lgt.layer3.motion_picture.standard';
import { PmGenericProduct3, McGenericRecommendationProduct } from '@mediafellows/mm3-types';

export enum General {
  OFFCANVAS_ACTIVE = 'offcanvas--active',
  OFFCANVAS_HIDNIG = 'offcanvas--hiding',
  OFFCANVAS_SHOWING = 'offcanvas--showing',
}

export enum Modal {
  EDIT_LIST = 'edit-list',
  SAVE_TO_LIST = 'save-to-list',
  PREVIEW_ASSETS = 'preview-assets',
  CONFIRMATION_MODAL = 'confirmation-modal',
  SEARCH_MODAL = 'search-modal',
  CHANGE_PASSWORD = 'change-password',
}

export enum MAIN_CATEGORY {
  TV = 'tv',
  MOVIES = 'movies',
}

export enum OFFCANVAS {
  ACCOUNT = 'account',
  SIDEBAR = 'sidebar',
  GENRE_FILTERS = 'genre-filters',
  SEARCH_FILTERS = 'search-filters',
  SAVED_LIST_FILTERS = 'saved-list-filters',
}

export const CATEGORIES = {
  tv: [
    { frontend: 'drama', backend: 'drama' },
    { frontend: 'comedy', backend: 'comedy' },
    { frontend: 'unscripted', backend: 'unscripted' },
    { frontend: 'event series', backend: 'event series' },
    { frontend: 'kids', backend: 'childrens' },
    { frontend: 'formats', backend: 'formats' },
  ],
  movies: [
    { frontend: 'drama', backend: 'drama', group: 'drama' },
    { frontend: 'comedy', backend: 'comedy', group: 'comedy' },
    { frontend: 'action', backend: 'action', group: 'action' },
    { frontend: 'family', backend: 'family' },
    { frontend: 'thriller', backend: 'thriller' },
    { frontend: 'horror', backend: 'horror' },
    { frontend: 'documentary', backend: 'documentary' },
  ],
};

export const RATINGS = ['G', 'PG', 'PG-13', 'R', 'NC-17', 'NR'];

export const FILM_TYPES = ['Collection', 'Franchise', 'Movie', 'Short'];

export const TV_TYPES = [
  'Ad',
  'Episode',
  'Excerpt',
  'Movie of the Week',
  'Non-episodic Show',
  'Promotion',
  'Season',
  'Series',
  'Special',
  'Supplemental',
  'TV',
];

export const VIDEO_CLASSIFICATIONS = {
  'video/bonus': 'Bonus',
  'video/epk': 'EPK',
  'video/interview': 'Interview',
  'video/making_of': 'Making Of',
  'video/promo': 'Promo',
  'video/screener': 'Screener',
  'video/trailer': 'Trailer',
  'video/featurette': 'Featurette',
};

export enum Product3Types {
  BASE_TYPE = 'Product3',
  EPISODE = 'Product3::MotionPicture::Episode',
  EPISODE_VERSION = 'Product3::MotionPicture::EpisodeVersion',
  FILM_VERSION = 'Product3::MotionPicture::FilmVersion',
  PROGRAM = 'Product3::MotionPicture::Program',
  FORMAT = 'Product3::MotionPicture::Format',
  SEASON = 'Product3::MotionPicture::Season',
  SERIES = 'Product3::MotionPicture::Series',
}

export const PRODUCTS_CLASSIFICATIONS = {
  [Product3Types.SEASON]: 'Season',
  [Product3Types.SERIES]: 'Series',
};

export type ItemId = string | number;

export type Address = {
  id?: string;
  label?: string;
  street: string;
  supplement: string;
  zip_code: string;
  city: string;
  state?: string;
  country_id?: string;
  country?: string;
};

export type Organization = {
  '@type'?: string;
  name?: string;
  addresses?: Address[];
};
export interface Contact {
  '@type': string;
  access_level?: string;
  activated_at: string;
  mfa_auth_method: string;
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  function: string;
  organization: Organization;
  interests: number[];
  disable_watermark: boolean;
  responsible_user_id: ItemId;
}

export interface Session {
  ['@type']: string;
  id: string;
  user?: Contact;
  campaign_id: number;
  affiliation: { id: string };
  role: { id: number; name?: string; designation: string };
  '@associations'?: Record<string, unknown>;
  isPublic: boolean;
  isRecommendation: boolean;
  isAuthenticated: boolean;
  mfa_check_required: boolean;
  isEvent: boolean;
  isPrivate: boolean;
}

export type FilterValue = boolean | string | number;
export type FilterArray = (string | number)[];
export type ProductTypeFilterValue = 'movies' | 'tv';
export type SearchFilter =
  | [string, string, FilterValue | FilterArray]
  | [string, FilterValue | FilterArray]
  | [string, string, string | number, string | number];

export interface DefaultFilterRecord {
  value: FilterValue | FilterArray | ProductTypeFilterValue;
  range?: FilterArray;
  aggregationsRange?: boolean;
  payload: SearchFilter | null;
}
export interface DefaultFilters {
  year_of_production?: DefaultFilterRecord;
  duration?: DefaultFilterRecord;
  category_ids?: DefaultFilterRecord;
  product_type?: DefaultFilterRecord;
  rating?: DefaultFilterRecord;
  q?: DefaultFilterRecord;
  id?: DefaultFilterRecord;
}

export type FilterStore = ZSearchStore;
export type GenericSearchStore = UseBoundStore<StoreApi<FilterStore>>;

export enum Order {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum Sort {
  DISPLAY_TITLE = 'display_title',
  TIFF_TITLE_JP = 'default_layer.meta.title_jp',
  YEAR_OF_PRODUCTION = 'year_of_production',
  ORIGINAL_RELEASE_DATE = 'original_release_date',
  PRODUCTS_YEAR_OF_PRODUCTION = 'default_layer.meta.year_of_production',
  PRODUCTS_ORIGINAL_RELEASE_DATE = 'default_layer.meta.original_release_date',
  CATEGORY = 'default_layer.meta.category_ids',
  COUNTRY_OF_ORIGIN = 'default_layer.meta.country_of_origin_ids',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  NAME = 'name',
  PUBLISHED_AT = 'published_at',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  TITLE = 'title',
  FILE_SIZE = 'file_size',
  SUBJECT = 'subject',
  LAST_LOGIN = 'last_login_at',
  FLAT_SEQUENCE_NUMBER = 'flat_sequence_number',
  SEQUENCE_NUMBER = 'sequence_number',
  LATEST_RELEASE_DATE = 'latest_release_date',
}

export enum Actions {
  RESET = 'reset',
  RESET_ALL = 'reset-all',
}

export type ApplyFiltersOptions = {
  values?: [filterName: string, value: FilterValue | FilterArray];
  action?: Actions;
  store: GenericSearchStore;
};

export enum ActiveFiltersNames {
  product_type = 'category',
  category_ids = 'genres',
  year_of_production = 'year',
  duration = 'runtime',
  rating = 'rating',
}

export enum FetchActions {
  PRODUCTS_BY_GROUP = 'products-by-group',
  FEATURED_TV = 'featured-tv',
  FEATURED_MOVIES = 'featured-movies',
  PRODUCT = 'product',
  ALL_PRODUCT_DATA = 'all-product-data',
  EVENT_PAGE = 'event-page',
  EVENT_PAGE_SE = 'event-page-special-events',
  RECOMMENDATION_COLLECTION = 'recommendation-collection',
  CONTINUE_WATCHING = 'continue-watching',
  RECOMMENDED_PRODUCTS = 'recommended-products',
  HERO_PRODUCTS = 'hero-products',
  RECOMMENDATION_USERS = 'recommendation-users',
  RECOMMENDATION_PRODUCTS = 'recommendation-products',
  RECOMMENDATIONS = 'recommendations',
  RECOMMENDATION = 'recommendation',
  HOME_SHOWCASES = 'home-showcases',
  GENRE_SHOWCASES = 'genre-showcases',
  REGISTRATION_SHOWCASES = 'registration-showcases',
  SALES_REPRESENTATIVES = 'sales-representatives',
  ASSET_VIDEO_URL = 'asset-video-url',
  PRODUCT_OTHER_VIDEOS = 'product-other-videos',
}

export type BasicsStoreCategories = {
  id: number;
  name: string; // TODO add more specific type.
};

export type Category = {
  id: number;
  name: string;
};

export type Language = {
  id: string;
  name: string;
};

export enum NotificationsClassname {
  SUCCESS = 'success-notification',
  ERROR = 'error-notification',
}

export type ChipmunkError = Error & { status: number };

export enum Model {
  CMS_PAGE = 'mm3:cms.page',
  PRODUCTS = 'mm3:pm.product3',
  PRODUCT_LAYERS = 'mm3:pm.layer3',
  PRODUCT_STANDARD_LAYER = 'mm3:pm.layer3/motion_picture/standard',
  PRODUCT_ANCESTRY_INFO = 'pm.product/ancestry_info',
  PRODUCT_ASSET = 'pm.product/asset',
  ANALYTICS = 'ac.analytics_data',
  ASSETS = 'am.asset',
  NEWS = 'mm3:am.news',
  SUBTITLES = 'am.asset/artefact/subtitle',
  ASSET_METADATA = 'am.asset/metadata',
  ASSET_DIGITAL_IMAGE = 'am.asset/digital/image',
  ASSET_VIDEO_URL = 'am.asset/video_url',
  ASSET_LAYER = 'am.layer',
  ASSET_SCHEDULED_JOB = 'am.scheduled_job',
  DIVISIONS = 'um.division',
  EVENTS = 'um.group/event',
  CONTACTS = 'um.user',
  ORGANIZATIONS = 'um.organization',
  ASSET_GROUP_ITEMS = 'am.group/item',
  PRODUCT_GROUP_ITEMS = 'pm.group/item',
  CONTACT_GROUP_SET = 'um.group/set',
  STORAGE_ASSET_HOST_TASK_PREVIEW_IMAGE_EXTRACTION = 'am.storage/asset_host/task/preview_image_extraction',
  DOWNLOAD = 'am.download',
  DOWNLOAD_BATCH = 'am.download/batch',
  WORKFLOW = 'jc.workflow',
  RECOMMENDATIONS = 'mm3:mc.recommendation',
  RECOMMENDATIONS_TOKEN = 'mm3:mc.recommendation/token',
  RECOMMENDATIONS_DETAILS = 'mc.campaign/recommendation',
  CONTACT_STATISTICS_DATA = 'um.statistics_data',
  PRODUCT_STATISTICS_DATA = 'pm.statistics_data',
  ASSET_STATISTICS_DATA = 'am.statistics_data',
  BASKETS = 'pm.basket',
  ASSET_BASKET = 'am.basket',
  // THIS IS PREFERABLE WAY to work with any kind of groups (events, recommendations, selections)
  // Please try to avoid introducing new preset target for that matter
  GROUPS = 'um.group',
  GROUP_SCHEDULED_JOB = 'um.scheduled_job',
  LISTS = 'mm3:um.list',
  COLLECTIONS = 'mm3:um.list/collection',
  SHOWCASES = 'mm3:um.list/showcase',
  LIST_ITEMS = 'mm3:um.list/item',
  MEETINGS = 'mm3:cal.meeting',
  LOCATIONS = 'mm3:cal.location',
  INVITE = 'mm3:cal.invite',
  CATEGORIES = 'pm.layer/motion_picture/standard/category',
  CONFERENCE = 'cc.conference',
  BUYER_DOMAINS = 'um.buyer/domain',
  AFFILIATION = 'um.affiliation',
  SESSION = 'um.session',
  CAST_CREW = 'mm3:pm.cast_crew',
  PREVIEW_IMAGE = 'am.preview_image',
  SUBSCRIPTIONS = 'sm.subscription',
}

export interface Product extends Omit<PmGenericProduct3, 'id'> {
  id: number;
  default_layer?: PmLgtLayer3MotionPictureStandard;
  product_id?: number;
}

export type AssetMainClassification = 'video' | 'audio' | 'image' | 'document';
export interface Asset {
  ['@type']: string;
  display_title: string;
  access_level?: string;
  archive_number?: string;
  asset_identifier?: string;
  aspect_ratio?: string;
  asset_type?: string;
  author?: string;
  barcode?: string;
  caption?: string;
  classification?: string;
  comments?: string;
  created_at?: string;
  default_layer?: AssetLayer;
  default_layer_id?: number;
  deleted_at?: string;
  division?: string;
  duration?: number;
  effective_permissions?: string[];
  external_reference?: string;
  file_name?: string;
  file_size?: number;
  file_status?: string;
  format?: string;
  geography_ids?: string[];
  geographies?: ITreeNode[];
  height?: number;
  id: ItemId;
  identifier_crid?: string;
  identifier_eidr?: string;
  identifier_isan?: string;
  identifier_umid?: string;
  identifier_uri?: string;
  identifier_visan?: string;
  ingest_notes?: string;
  keywords?: string;
  language_ids?: string[];
  main_classification?: AssetMainClassification;
  marketing_use: boolean;
  md5?: string;
  name?: string;
  number_of_pages?: number;
  // organization?: IOrganization;
  // owner?: IContact;
  owner_id?: number;
  // owner_division?: IDivision;
  owner_division_id?: string;
  // owner_organization?: IOrganization;
  owner_organization_id?: number;
  pdf_version?: string;
  permissions?: string[];
  // preview_image: IPreviewImage;
  preview_image_id?: string;
  producer?: string;
  product_id?: number;
  // products?: Product[];
  products_count?: number;
  protection_levels?: string[];
  // product_asset?: IProductAsset;
  published_at: string;
  resolution?: string;
  selected?: boolean;
  access_change_jobs_count?: number;
  skip_auto_improvements?: boolean;
  status?: string;
  storage?: { is_restorable?: boolean };
  subject?: string;
  // subtitles?: ISubtitle[];
  tags?: string[];
  title?: string;
  updated_at?: string;
  user_md5?: string;
  width?: number;
  classification_tag?: string;
  progress?: number;
  timecode?: number;
  stream_url?: string;
}

export interface AssetLayer {
  id?: string;
  copyright_notice?: string;
  notes?: string;
  description?: string;
  caption?: string;
  talent_in_image?: string;
  photographer?: string;
  name: string;
  news_date?: string;
  news_location?: string;
}

export interface ITreeNode {
  id: string;
  name: string;
  parent_id?: string;
}

export type Recommendation = McGenericRecommendationProduct & {
  token?: number;
  email_address?: string;
};
