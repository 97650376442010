import React from 'react';
import { Thumbnail } from '../index';
import { previewImage } from '@mediafellows/tuco/dist/lib/helpers';
import './style.scss';
import { getType } from 'utils';

export type FeaturedThumbnailProps = {
  item: any;
  imgUrl?: string;
  onClick?: () => void;
  showCategory?: boolean;
};

export const FeaturedThumbnail = ({ item, onClick, imgUrl, showCategory }: FeaturedThumbnailProps) => {
  if (!item) return null;

  const { default_layer, title } = item;
  const { year_of_production, rating, product_type } = default_layer || {};
  const img = imgUrl || previewImage(item)?.url;
  const type = getType(product_type);

  return (
    <div className="featured-thumbnail" onClick={onClick}>
      <Thumbnail image={img} scale={'hd'} showOverlay={false} />
      <div className="featured-thumbnail__gradient" />
      <div className="featured-thumbnail__glow" />
      <div className="featured-thumbnail__content p-3 p-lg-6 p-xl-8">
        <div className="container p-0 g-0 d-none d-xxl-block position-fix">
          <div className="h2 mb-0 d-sm-none d-md-block">{title}</div>
          <div className="row">
            <div className="col-12 col-xxl-7 my-0 my-lg-4 my-xxl-6">
              <hr />
            </div>
            <div className="col-12 d-flex align-items-center">
              <div className="me-3 me-lg-8 me-xxl-8">
                <h6 className="text-uppercase">Released</h6>
                <div>{year_of_production}</div>
              </div>
              <div className="me-3 me-lg-8 me-xxl-8">
                <h6 className="text-uppercase">Rating</h6>
                <div>{rating}</div>
              </div>
              {showCategory && type && (
                <div>
                  <h6 className="text-uppercase">Category</h6>
                  <div>{type}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container p-0 g-0 d-xxl-none">
          <div className="row-12">
            <div className="col-12 col-xxl-7">
              <div className="h2 mb-0 d-sm-none d-md-block">{title}</div>
              <div className="h4 mb-0 d-none d-sm-block d-md-none">{title}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xxl-7 my-0 my-lg-4 my-xxl-6">
              <hr />
            </div>
            <div className="col-12 d-flex align-items-center">
              <div className="me-3 me-lg-8 me-xxl-8">
                <h6 className="text-uppercase">Released</h6>
                <div>{year_of_production}</div>
              </div>
              <div className="me-3 me-lg-8 me-xxl-8">
                <h6 className="text-uppercase">Rating</h6>
                <div>{rating}</div>
              </div>
              {showCategory && type && (
                <div>
                  <h6 className="text-uppercase">Category</h6>
                  <div>{type}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
