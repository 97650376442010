import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { IGetMarketingAssets } from '@mediafellows/tuco/dist/lib/tasks';
import { productSchemas, recommendationsSchemas } from 'pages/product/schemas';
import { chipmunk, tuco } from 'utils/chipmunk';
import { videoProgress } from 'utils/general';

export const fetchMarketingAssets = (id: number, type: 'product' | 'recommendation'): Promise<IObject> => {
  return chipmunk.run(async () => {
    const opts: IGetMarketingAssets = {
      product_ids: id,
      schema: type === 'product' ? productSchemas.marketingAsset : recommendationsSchemas.marketingAsset,
    };
    const data = await tuco('getMarketingAssets', opts);
    const { objects: assets = [] } = data || {};
    assets.map((asset) => (asset.progress = videoProgress(asset?.duration, asset?.video_continuation?.last_timecode)));
    return assets;
  });
};
