import React, { useState } from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { Routes } from 'routes';
import { chipmunk, displayError } from 'utils';
import { ButtonSpinner, FormBox } from 'components';
import { notifications } from '@mantine/notifications';
import { NotificationsClassname } from 'types';
import { useHistory } from 'react-router-dom';

export const ForgottenPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const submitCallback = async ({ email }): Promise<void> => {
    try {
      setIsLoading(true);

      await chipmunk.action('um.password', 'create', {
        params: { email },
        headers: { 'Session-Id': null },
      });

      setIsLoading(false);
      notifications.show({
        message:
          'Thank you! You will be sent a link via email to reset your password (if the email is known by our system)',
        className: NotificationsClassname.SUCCESS,
      });
      history.push(Routes.HOME);
    } catch (e) {
      notifications.show({
        message: 'Please enter valid email.',
        color: 'red',
      });
    }
  };

  return (
    <FormBox colClass="col-12 col-md-6 col-xl-5 col-xxl-4 mt-13">
      <h3>Forgot your password?</h3>
      <p className="mt-4">Enter your email address below and we’ll send you a link to reset it.</p>
      <form className="row g-3 mt-8" noValidate={true} onSubmit={handleSubmit(submitCallback)}>
        <div className="col-12 mb-3">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
          <input
            id="email"
            type="email"
            {...register('email', {
              required: true,
              minLength: 8,
            })}
            placeholder={'Ex. johnsmith@isp.com'}
            autoComplete={'email'}
            className={cx('form-control', { 'is-invalid': errors?.email })}
          />
          {displayError(errors?.email, 'Email')}
        </div>
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center flex-column">
            <div>
              <button type="submit" disabled={isLoading} className="btn btn-lg btn-outline-primary">
                <ButtonSpinner title="Reset Password" isLoading={isLoading} />
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormBox>
  );
};
