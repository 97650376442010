import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import pdfobject from 'pdfobject';
import { darkTheme } from 'amazon-chime-sdk-component-library-react';
import {
  ChimeApp,
  useMeetingCode,
  IConferenceTheme,
  IConferenceDataContext,
} from '@mediafellows/react-video-conference';
import { getSocket } from '@mediafellows/pigeon2-client';

import { useStore } from 'store';
import { chipmunk } from 'utils';
import { visitorId } from 'utils/page-pings';
import Home from './home';
import Ending from './ending';
import MeetingWrapper from './meeting-wrapper';
import DeviceSetup from './device-setup';
import './style.scss';
import { useSessionStore, login as loginUser } from 'store/session-store/session-store';
import { notifications } from '@mantine/notifications';
import { NotificationsClassname } from 'types';

export const getChimeTheme = (): IConferenceTheme => ['dark', darkTheme];
export const VideoConference = observer(() => {
  const code = useMeetingCode();
  const store = useStore();
  const [slimUser, setSlimUser] = useState(null);
  const { conferenceStore } = store;
  const session = useSessionStore((state) => state.session);
  const { conference } = conferenceStore;
  const { user, isPublic } = session || {};
  const [socket, setSocket] = useState(null);

  const openToast = useCallback((msg, type = 'success'): void => {
    if (type === 'error') {
      notifications.show({
        message: msg,
        color: 'red',
      });
      return;
    }
    notifications.show({
      message: msg,
      className: NotificationsClassname.SUCCESS,
    });
  }, []);

  const onVideoMuted = useCallback(() => {
    notifications.show({
      message: 'Your video player is muted. Please unmute using the button at the bottom left of the player screen.',
      color: 'red',
    });
  }, []);

  const onSuggestStopVideo = useCallback(() => {
    notifications.show({
      message: 'Low internet speed detected. Please turn off your webcam for a better screening experience.',
      color: 'red',
    });
  }, []);

  const onAttendeeStoppedVideo = useCallback((attendee) => {
    const name = attendee?.name;
    if (!name) return;
    notifications.show({
      message: `'${name}' has disabled their webcam`,
      color: 'red',
    });
  }, []);

  const login = useCallback(async (email: string, password: string) => {
    await loginUser(email, password);
    try {
      await loginUser(email, password);
    } catch (err) {
      notifications.show({
        message: err.text || 'Failed to sign in',
        color: 'red',
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      const conference = (
        await chipmunk.action('cc.conference', 'get', {
          params: { code },
        })
      ).object;

      const slimUser = (await chipmunk.action('um.session', 'slim')).object?.user;
      setSlimUser(slimUser);

      if (!user) return;

      conferenceStore.conference = conference;
      conferenceStore.isAdmin = conference.owner_id === user.id;

      if (!isPublic) {
        conferenceStore.userName = `${user.first_name} ${user.last_name}`;
      }
      setSocket(getSocket());
    })();
  }, [code, store, isPublic, user, conferenceStore, setSlimUser]);

  const data = useMemo(
    (): IConferenceDataContext => ({
      theme: getChimeTheme(),
      session: { user: slimUser, isPublic },
      handlers: { openToast, onVideoMuted, onSuggestStopVideo, onAttendeeStoppedVideo, login },
      conferenceStore,
      useInternalModals: true,
      visitorId,
      chipmunk,
      pdfobject,
      socket,
      components: {
        Home,
        Ending,
        DeviceSetup,
        MeetingWrapper,
      },
    }),
    [
      slimUser,
      socket,
      isPublic,
      conferenceStore,
      openToast,
      onVideoMuted,
      onSuggestStopVideo,
      onAttendeeStoppedVideo,
      login,
    ],
  );

  if (!socket) return null;

  return (
    <>
      {conference && (
        <div>
          <ChimeApp data={data} />
        </div>
      )}
    </>
  );
});
