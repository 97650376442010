import { createTheme, Modal } from '@mantine/core';

export const theme = createTheme({
  components: {
    Modal: Modal.extend({
      styles: (_) => {
        return {
          content: {
            padding: '1rem',
            color: '#fff',
            background: 'rgba(0, 0, 0, 0.25)',
            border: ' 1px solid rgba(255, 255, 255, 0.25)',
            borderRadius: 0,
            width: '90vw',
            maxWidth: '500px',
          },
          inner: {
            alignContent: 'center',
            display: 'grid',
          },
          overlay: {
            backdropFilter: 'blur(15px)',
          },
        };
      },
    }),
  },
});
