import React from 'react';

export const Typography = () => {
  return (
    <div className="header-offset container">
      <div className="row">
        <div className="col-12">
          <h1 className="mb-6">Headline 1</h1>
          <h2 className="mb-6">Headline 2</h2>
          <h3 className="mb-6">Headline 3</h3>
          <h4 className="mb-6">Headline 4</h4>
          <h5 className="mb-6">Headline 5</h5>
          <h6 className="mb-6">Headline 6</h6>
          <p>
            Body - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
          <p className="fw-bolder">
            Body - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
          <a href="/" className="custom-link">
            Navlink Example
          </a>
          <p className="small mt-3">Footer text</p>
          <p className="small text-muted">Footer text - muted</p>
        </div>
      </div>
    </div>
  );
};
