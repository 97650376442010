const config = {
  SITE_TITLE: 'Lionsgate',
  GLOBALS: {
    PUBLIC_SESSIONS: 'false',
    AFFILIATION_ID: 'lgt',
    USE_DRM: 'chrome',
    // GOOGLE_ANALYTICS_ID: 'UA-6489883-32',
    GOOGLE_ANALYTICS_ID: '',
  },
  socialLinks: {
    facebook: 'https://www.facebook.com/lionsgate/',
    twitter: 'https://twitter.com/Lionsgate',
    linkedIn: 'https://www.linkedin.com/company/lionsgate',
    instagram: 'https://www.instagram.com/lionsgate/',
    youtube: 'https://www.youtube.com/c/LionsgateMovies',
  },
  CONTENT_SECURITY_POLICY: {
    'img-src': ['https://*.clarity.ms', 'https://*.bing.com'],
    'script-src': ['https://*.clarity.ms', 'https://sdk.amazonaws.com'],
    'connect-src': ['https://*.clarity.ms', 'https://dynamodb.us-east-1.amazonaws.com'],
  },
};

export default config;
