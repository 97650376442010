import { notifications } from '@mantine/notifications';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { chipmunk } from 'utils/chipmunk';

export const fetchRecommendationUsers = (usersIds: number | number[]): Promise<IObject[]> => {
  return chipmunk.run(
    async (chipmunk) => {
      const res = await chipmunk.action('um.user', 'get', {
        params: { user_ids: usersIds },
        schema: `id, first_name, last_name, email`,
      });

      const users = res.objects;
      return users;
    },
    () => {
      notifications.show({
        message: 'Failed to get a recommendation users',
        color: 'red',
      });
    },
  );
};
