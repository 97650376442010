import React, { useCallback, useEffect, useState } from 'react';
import { useRanger } from 'react-ranger';
import cx from 'classnames';
import './ranger.scss';

export const Ranger: React.FC<{
  min?: number;
  max?: number;
  stepSize?: number;
  values?: number[];
  customTicks?: number[];
  onChange?: (values: number[]) => void;
}> = (props) => {
  const { min = 0, max = 100, stepSize = 1, values = [0, 5], onChange, customTicks } = props;
  const [localValues, setLocalValues] = useState(values);

  useEffect(() => {
    const [localStart, localEnd] = localValues;
    const [start, end] = values;

    if (localStart !== start || localEnd !== end) {
      setLocalValues(values);
    }
  }, [values, localValues]);

  const handleChange = useCallback(
    (v) => {
      setLocalValues(v);
      onChange?.(v);
    },
    [onChange],
  );

  const { getTrackProps, ticks, segments, handles } = useRanger({
    min,
    max,
    stepSize,
    ticks: customTicks,
    values: localValues,
    onChange: handleChange,
  });

  return (
    <div className="ranger">
      <div className="ranger__track" {...getTrackProps()}>
        {ticks.map(({ value, getTickProps }, i) => (
          <div key={i} className="ranger__tick" {...getTickProps()}>
            <div className="ranger__tick-label">{value}</div>
          </div>
        ))}
        {segments.map(({ getSegmentProps }, i) => (
          <div className={cx(`ranger__segment ranger__segment--${i}`)} key={i} {...getSegmentProps()} />
        ))}
        {handles.map(({ value, active, getHandleProps }, i) => (
          <button
            key={i}
            {...getHandleProps({
              style: {
                appearance: 'none',
                border: 'none',
                background: 'transparent',
                outline: 'none',
              },
            })}
          >
            <div className={cx('ranger__handle', { 'ranger__handle--active': active })}>
              <div className="ranger__handle-value">{value}</div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
