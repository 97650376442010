import React, { useEffect } from 'react';
import { chipmunk } from 'utils';
import { TopSection, ProductList } from 'components';
import { Actions, Model, OFFCANVAS } from 'types';
import { useSearchStore, ZSearchStore } from 'store/search-store/search-store';
import { useShallow } from 'zustand/react/shallow';
import { applyFilters } from 'store/apply-filters';
import useSWRInfinite from 'swr/infinite';
import { toggleOffcanvas } from 'store/offcanvas-store';
import { CategoryFilters } from 'pages/category/category-filters';
import { useInViewport } from '@mantine/hooks';
import { productSchema } from 'pages/product/schemas';
import { useSessionStore } from 'store/session-store/session-store';

// TODO: Lookg through the logic of these helper functions and try to make it more readable and maybe separate them on dedicated file/s
const similarity = (s1 = '', s2 = '') => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength == 0) return 1.0;
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength + '');
};

const editDistance = (s1: string, s2: string) => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();
  const costs: number[] = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue: number = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
};

const getSortedProducts = (products = [], searchQuery: string) => {
  if (!searchQuery) return products;
  products.sort(
    (a, b) =>
      similarity(b.title?.toLowerCase(), searchQuery.toLowerCase()) -
      similarity(a.title?.toLowerCase(), searchQuery.toLowerCase()),
  );
  return products;
};

export const Search = () => {
  const initialLoadDone = useSessionStore((state) => state.initialLoadDone);

  const { ref, inViewport } = useInViewport();

  const { filters, page, per, sort, order } = useSearchStore(
    useShallow(
      ({ filters, page, per, sort, order, activeFilters }): Partial<ZSearchStore> => ({
        filters,
        per,
        sort,
        order,
        page,
        activeFilters,
      }),
    ),
  );

  const searchQuery = filters?.q?.value as string;

  const handleClearClick = () => {
    applyFilters({ action: Actions.RESET, store: useSearchStore });
  };

  const swr = useSWRInfinite(
    (pageIndex) => (initialLoadDone ? ['search-load-products', pageIndex + 1, per, sort, order, filters] : null),
    (params) => {
      const [, size, per, sort, order, filters] = params;

      const updatedFilters = Object.values(filters)
        .filter((item) => item.payload)
        .map((item) => item.payload);

      updatedFilters.push(['parent_id', 'not_exist']);
      return chipmunk.run(
        () =>
          chipmunk.action(Model.PRODUCTS, 'search', {
            body: {
              sort,
              order,
              page: size,
              per,
              search: {
                filters: updatedFilters,
              },
            },
            schema: productSchema,
          }),
        (e: Error) => console.log(e),
      );
    },
    { keepPreviousData: true, parallel: true },
  );

  const { data, isLoading, size, setSize } = swr;
  const lastPageData = data?.length ? data[data.length - 1] : {};
  const { pagination } = lastPageData;
  const { total_count = 0, total_pages } = pagination ?? {};

  const products = data?.flatMap((page) => page.objects) ?? [];

  const initialLoading = !initialLoadDone || (typeof data === 'undefined' && isLoading);
  const isLoadingMore = !initialLoading && (isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'));
  const noResults = !initialLoading && products?.length === 0;

  useEffect(() => {
    if (initialLoading || noResults || !inViewport || isLoadingMore) {
      return;
    }

    // page increment will call setSize
    if (size < total_pages) {
      useSearchStore.setState((state) => ({
        page: state.page + 1,
      }));
    }
  }, [inViewport, isLoadingMore, size, total_pages, initialLoading, noResults]);

  // control size from the store
  useEffect(() => {
    if (page !== size) {
      setSize(page);
    }
  }, [page, setSize, size]);

  return (
    <div className="category">
      <TopSection
        title={searchQuery ? `"${searchQuery}" Results` : 'Search'}
        onFiltersClick={() =>
          toggleOffcanvas(OFFCANVAS.SEARCH_FILTERS, <CategoryFilters key={OFFCANVAS.SEARCH_FILTERS} />)
        }
        onClearClick={handleClearClick}
        isSmall
        store={'search'}
      />

      {noResults && (
        <section className="py-10 text-center">
          <h5>No titles found</h5>
        </section>
      )}

      {!initialLoading && !noResults && (
        <>
          <ProductList
            products={getSortedProducts(products, searchQuery)}
            keyName={'search'}
            total={total_count}
            showType={true}
          />
          {isLoadingMore && (
            <div
              className="fixed-bottom p-3 rounded m-auto d-flex align-items-center bg-primary--main mb-10 justify-content-center"
              style={{ maxWidth: 170 }}
            >
              <div className="spinner-border text-primary me-3" role="status" />
              <h5 className="m-0">Loading ...</h5>
            </div>
          )}
        </>
      )}

      <div ref={ref} className="category__placeholder" />
    </div>
  );
};
