import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { productVideosAssetSchema } from 'pages/product/schemas';
import { Model } from 'types';
import { chipmunk } from 'utils/chipmunk';

const mapVideos = (assets) => {
  return assets.map((asset) => {
    return {
      product_id: asset.products?.[0]?.product_id ?? null,
      language_name: asset.languages?.[0]?.name ?? null,
      id: asset.id,
      name: asset.name,
      classification: asset.classification,
      duration: asset.duration,
      preview_image: asset.preview_image,
    };
  });
};

export const fetchAllVideos = (seasons: any[]): Promise<IObject[]> => {
  return chipmunk.run(async (chipmunk) => {
    const promises = [];
    const filters = [
      ['preview_image_id', 'exist'],
      ['main_classification', 'eq', 'video'],
    ];
    seasons.map((season) =>
      promises.push(
        chipmunk.unfurl(Model.ASSETS, 'search', {
          body: {
            product_ancestry_id: season.id,
            search: {
              filters,
            },
          },
          schema: productVideosAssetSchema,
        }),
      ),
    );
    const data = (await Promise.all(promises)).map((promise, index) => {
      seasons[index].screeners = [];
      seasons[index].trailers = [];
      const { objects } = promise;
      const videos = mapVideos(objects).sort((a, b) => a.product_id - b.product_id || a.name.localeCompare(b.name));
      videos.map((video) => {
        if (video.classification === 'video/screener') {
          return seasons[index].screeners.push(video);
        }
        return seasons[index].trailers.push(video);
      });
      return promise;
    });

    const videos = mapVideos(data[0].objects).sort(
      (a, b) => a.product_id - b.product_id || a.name.localeCompare(b.name),
    );
    return videos;
  });
};
