import { Contact, Session } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { errorHandler } from 'utils/error-handler';
import { getSession } from 'utils/session';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { cookie } from 'utils/cookie';
import { VISIT_KEY, sendPagePing } from 'utils/page-pings';

export type ZSessionStore = {
  session: Session | null;
  user: Contact | null;
  initialLoadDone: boolean;
  is2FArequired: boolean;
  is2FARecoRedirect: boolean;
};

export const useSessionStore = create<ZSessionStore>()(
  devtools(() => ({
    session: null,
    user: null,
    initialLoadDone: false,
    is2FArequired: false,
    is2FARecoRedirect: false,
  })),
);

export const loadSession = (): Promise<Session> =>
  chipmunk.run(
    async () => {
      const session = await getSession();
      session.isPublic = session['@type'] === 'session/public';
      session.isRecommendation = session['@type'] === 'session/recommendation';
      session.isAuthenticated = session['@type'] === 'session';
      session.isEvent = session['@type'] === 'session/event';
      session.isAdmin = session?.role?.designation === 'All';

      const sessionId = session.id;
      const roleId = session?.role?.id;
      const affiliationId = session?.affiliation?.id;

      cookie.set('sessionId', sessionId);
      cookie.set('roleId', roleId);
      cookie.set('affiliationId', affiliationId);

      chipmunk.updateConfig({
        headers: {
          'Session-Id': sessionId,
          'Role-Id': roleId,
          'Affiliation-Id': affiliationId,
        },
      });

      useSessionStore.setState(() => ({
        session,
        is2FArequired: session.mfa_check_required,
        initialLoadDone: true,
      }));
      return session;
    },
    (e: Error) => {
      errorHandler(e, { title: 'Failed to load session' }); // TODO
      chipmunk.updateConfig({
        headers: {
          'Session-Id': null,
          'Role-Id': null,
          'Affiliation-Id': null,
        },
      });
      useSessionStore.setState({ session: null, initialLoadDone: true });
    },
  );

export const login = (email: string, password: string): Promise<Session> => {
  return chipmunk.run(
    async ({ updateConfig, action }) => {
      updateConfig({
        headers: {
          'Session-Id': null,
          'Role-Id': null,
        },
      });

      const device_id = await getFingerprint();

      const res = await action('um.session', 'create', {
        body: { email, password, device_id },
      });

      cookie.set('sessionId', res.object.id);

      updateConfig({
        headers: { 'Session-Id': res.object.id },
      });

      return loadSession();
    },
    (e: Error) => {
      cookie.remove('sessionId');
      loadSession();
      errorHandler(e, { title: 'Failed to sign in' });
    },
  );
};

export const loadUser = (ping = false): Promise<Contact> => {
  return chipmunk.run(
    async ({ action }) => {
      const session = useSessionStore.getState().session;
      const userId = session.user.id;

      const { object } = await action('um.user', 'get', {
        params: { user_ids: userId },
        schema: `id, address, first_name, last_name, email, gender, title, function, phones, interests, organization { addresses, name }, skip_marketing_emails, preview_image, responsible_user_id, disable_watermark`,
      });

      const user = object as Contact;
      useSessionStore.setState({ user });

      if (ping) {
        await sendPagePing('logged-in');
      }

      return user;
    },
    (e: Error) => errorHandler(e, { title: 'Failed to load user data' }), // TODO
  );
};

export const logout = async () => {
  clear();
  await loadSession();
};

export const clear = () => {
  useSessionStore.setState({ session: null });
  cookie.remove('sessionId', VISIT_KEY);

  chipmunk.updateConfig({
    headers: {
      'Session-Id': null,
      'Role-Id': null,
    },
  });
};
