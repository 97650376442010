import { create } from 'zustand';
import { defaultRuntimeEnd, defaultRuntimeStart, defaultYearEnd, defaultYearStart } from 'utils/general';
import { devtools } from 'zustand/middleware';
import { DefaultFilters, Order, Sort } from 'types';

export const defaultFilters: DefaultFilters = {
  q: {
    value: '',
    payload: null,
  },
  category_ids: {
    value: [],
    payload: null,
  },
  product_type: {
    value: '',
    payload: null,
  },
  year_of_production: {
    value: [defaultYearStart, defaultYearEnd],
    range: [defaultYearStart, defaultYearEnd],
    aggregationsRange: false,
    payload: null,
  },
  duration: {
    value: [defaultRuntimeStart, defaultRuntimeEnd],
    range: [defaultYearStart, defaultYearEnd],
    aggregationsRange: false,
    payload: null,
  },
};

export type ZSearchStore = {
  activeFilters: string[];
  defaultFilters: DefaultFilters;
  filters: DefaultFilters;
  order: Order;
  page: number;
  per: number;
  sort: Sort;
};

export const useSearchStore = create<ZSearchStore>()(
  devtools(() => ({
    activeFilters: [],
    defaultFilters,
    filters: defaultFilters,
    order: Order.DESCENDING,
    page: 1,
    per: 24,
    sort: Sort.YEAR_OF_PRODUCTION,
  })),
);
