import React from 'react';
import cx from 'classnames';
import './styles.scss';

interface StripesProps {
  children?: any;
  className?: string;
}

export const FooterStripes: React.FC<StripesProps> = (props) => {
  const { children, className } = props || {};

  return (
    <div className={cx('footer-stripes position-absolute overflow-hidden w-100 h-100', className)}>
      <div className="footer-stripes__container">
        <div className="footer-stripes__stripe1"></div>
        <div className="footer-stripes__stripe2"></div>
        <div className="footer-stripes__stripe3"></div>

        {children}
      </div>
      <div className="footer-stripes__glowspot-container">{/* <div className="footer-stripes__glowspot"></div> */}</div>
    </div>
  );
};
