import '@mantine/notifications/styles.layer.css';
import '@mantine/core/styles.layer.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router';
import { setRootStore, storeContext } from 'store';
import { RootStore } from 'store/root-store';
import { history } from 'utils/history';
import { AppRoutes } from 'routes';
import { PlayerConfig } from '@mediafellows/videoplayer';
import { chipmunk, visitId, visitorId } from 'utils';
import {
  Offcanvas,
  SearchModal,
  EditListModal,
  SaveToListModal,
  ConfirmationModal,
  PreviewAssetModal,
  ChangePasswordModal,
  ScrollToTop,
} from 'components';

import './assets/styles/main.scss';
import './assets/styles/notifications-styles.scss';

import { useSessionStore } from 'store/session-store/session-store';
import { useLoadGeneralData } from 'utils/hooks/use-load-general-data';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { theme } from './theme';

PlayerConfig.configure({
  chipmunk: () => chipmunk,
  analytics: true,
  visitId: () => visitId(),
  visitorId: () => visitorId(),
});

export const StoreProvider = ({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
  const store = setRootStore(new RootStore());
  const initialLoadDone = useSessionStore((state) => state.initialLoadDone);
  useLoadGeneralData();

  return (
    <storeContext.Provider value={store}>
      {initialLoadDone && (
        <Router history={history}>
          <ScrollToTop />
          {children}
        </Router>
      )}
    </storeContext.Provider>
  );
};

const App = () => {
  return (
    <MantineProvider theme={theme} defaultColorScheme={'dark'}>
      <StoreProvider>
        <ModalsProvider
          modals={{
            search: SearchModal,
            editList: EditListModal,
            saveToList: SaveToListModal,
            confirmation: ConfirmationModal,
            previewAssets: PreviewAssetModal,
            changePassword: ChangePasswordModal,
          }}
        >
          <Notifications position="top-right" zIndex={9999} />
          <AppRoutes />
          <Offcanvas />
        </ModalsProvider>
      </StoreProvider>
    </MantineProvider>
  );
};

ReactDOM.createRoot(document.getElementById('app')).render(<App />);
