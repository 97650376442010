import React, { useState } from 'react';
import { Icon } from 'components';
import { Modal, Model } from 'types';
import './styles.scss';
import { useBaskets } from 'utils/hooks/use-baskets';
import { useBasketsProducts } from 'utils/hooks/use-basket-products';
import { chipmunk } from 'utils';
import { useSessionStore } from 'store/session-store/session-store';
import { notifications } from '@mantine/notifications';
import { ContextModalProps, modals } from '@mantine/modals';

const SaveToListModal = ({ context, id, innerProps: { productId } }: ContextModalProps<{ productId?: string }>) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const session = useSessionStore((state) => state.session);
  const { basketData: baskets, isBasketsLoading, mutateBaskets } = useBaskets(session?.user?.id);
  const { basketProducts } = useBasketsProducts(baskets);

  const handleCreate = (e) => {
    e.preventDefault();
    context.closeModal(id);
    modals.openContextModal({
      modal: 'editList',
      id: Modal.EDIT_LIST,
      innerProps: {
        openSaveToList: true,
        callbackProductId: productId,
      },
      withCloseButton: false,
    });
  };

  const handleSaveToList = (isPresentInBasket: boolean, basketId: number) => {
    const params = {
      basket_id: basketId,
      product_ids: [Number(productId)],
    };

    if (isPresentInBasket) {
      chipmunk.run(
        async ({ action }) => {
          await action(Model.PRODUCTS, 'remove_from_basket', { params });
          notifications.show({
            message: 'Removed from list',
            color: 'green',
          });
          mutateBaskets();
        },
        () => {
          notifications.show({
            message: 'Failed to remove item from saved list',
            color: 'red',
          });
        },
      );

      return;
    }

    chipmunk.run(
      async ({ action }) => {
        await action(Model.PRODUCTS, 'add_to_basket', { params });
        notifications.show({
          message: 'Added to list',
          color: 'green',
        });
        mutateBaskets();
      },
      () => {
        notifications.show({
          message: 'Failed to add item from saved list',
          color: 'red',
        });
      },
    );
  };

  if (isBasketsLoading) return null;

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title"></h5>
        <div className="close" aria-label="Close" onClick={() => context.closeModal(id)}>
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="modal-body savetolist-modal px-7">
        <h4>Save To List</h4>
        <ul className="mt-4">
          {baskets.map(({ id, name }, index) => {
            const products = basketProducts[id];
            const isPresentInBasket = products?.some((product) => product.id === productId);

            return (
              <li key={index} onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  role="button"
                  defaultChecked={isPresentInBasket}
                  onClick={() => handleSaveToList(isPresentInBasket, id)}
                />
                <span className="custom-link">{name}</span>
                {hoverIndex === index && <div className="savetolist-modal__selectedglow" />}
              </li>
            );
          })}

          <li onMouseEnter={() => setHoverIndex(baskets.length + 1)} onMouseLeave={() => setHoverIndex(null)}>
            <div className="d-flex justify-content-between">
              <a href="#" className="custom-link" onClick={handleCreate}>
                Create new list
              </a>
              <span className="savetolist-modal__plus">+</span>
            </div>

            {hoverIndex === baskets.length + 1 && <div className="savetolist-modal__selectedglow" />}
          </li>
        </ul>
      </div>
    </>
  );
};
export default SaveToListModal;
