import React from 'react';

export const Terms: React.FC<{}> = () => {
  return (
    <div className="container pt-15">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <h1 className="mb-10">Terms &amp; Conditions</h1>
          <section>
            <p>Last Revised May 10, 2011</p>
            <p>
              PLEASE READ THESE TERMS OF SERVICE AND THE RELATED PRIVACY POLICY LOCATED AT{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.lionsgate.com/legal/privacy-policy">
                https://www.lionsgate.com/legal/privacy-policy
              </a>{' '}
              CAREFULLY BEFORE USING THIS WEBSITE OR ANY OF THE “FORUMS” (AS DEFINED BELOW).
            </p>

            <p>
              <strong>Lionsgate</strong>
              <br />
              This “Site” (as defined below) is owned and operated by Lionsgate Entertainment Corp. on its own behalf or
              in combination with one of its film or television divisions or joint ventures (collectively referred to
              herein, solely for ease of reference, as “Company, “us” or “we”). These terms of service (the “Terms of
              Service”, “TOS” or “Agreement”) set forth the legal terms and conditions governing your use of our website
              and any other online and mobile websites and interactive applications operated by Company (collectively,
              the “Site”) (unless a different policy is provided on a particular site, application or service, in which
              case such different policy shall govern and control). Your use of this Site confirms your unconditional
              agreement to be bound by these Terms of Service and is subject to your continued compliance with these
              Terms of Service. If you do not agree to be bound by these Terms of Service, you may not access or
              otherwise use the Site. Before using the Site, please review the related Company Privacy Policy, located
              at{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.lionsgate.com/legal/privacy-policy">
                https://www.lionsgate.com/legal/privacy-policy
              </a>{' '}
              (the “Privacy Policy”), which is incorporated herein by this reference.
            </p>

            <p>
              <strong>Table of Contents</strong>
              <br />
              <a href="/terms#1">Eligibility</a>
              <br />
              <a href="/terms#2">Registration</a>
              <br />
              <a href="/terms#3">License to the Site</a>
              <br />
              <a href="/terms#4">Changes to Site and/or Terms of Service</a>
              <br />
              <a href="/terms#5">Trademarks, Copyrights and Restrictions</a>
              <br />
              <a href="/terms#6">Software</a>
              <br />
              <a href="/terms#7">Consent to Electronic Communications</a>
              <br />
              <a href="/terms#8">Submissions</a>
              <br />
              <a href="/terms#9">Linked Sites</a>
              <br />
              <a href="/terms#10">Purchases</a>
              <br />
              <a href="/terms#11">Forums and Public Communications</a>
              <br />
              <a href="/terms#12">Registration and Acceptance of Community Guidelines</a>
              <br />
              <a href="/terms#13">User Disputes</a>
              <br />
              <a href="/terms#14">Promotions</a>
              <br />
              <a href="/terms#15">No Resale/Exploitation</a>
              <br />
              <a href="/terms#16">Non-United States Residents</a>
              <br />
              <a href="/terms#17">Jurisdictional Issues</a>
              <br />
              <a href="/terms#18">Termination</a>
              <br />
              <a href="/terms#19">Disclaimer</a>
              <br />
              <a href="/terms#20">Limitation of Liability</a>
              <br />
              <a href="/terms#21">Linking Policy</a>
              <br />
              <a href="/terms#22">Copyright Notice</a>
              <br />
              <a href="/terms#23">Indemnification</a>
              <br />
              <a href="/terms#24">Filtering</a>
              <br />
              <a href="/terms#25">Miscellaneous</a>
              <br />
            </p>

            <p>
              <strong id="1">Eligibility</strong>
              The Site is intended solely for users thirteen (13) years of age and older. You represent and warrant
              either that you are eighteen (18) years of age or older, or if you are under the age of eighteen (18) that
              you are at least thirteen (13) and are accessing the Site with the knowledge and consent of your parent or
              legal guardian, who will also be deemed to have agreed to this Agreement. Certain parts of the Site may be
              subject in whole or in part to heightened age and/or other eligibility requirements.
            </p>
            <p>
              <strong id="2">Registration</strong>
              Certain parts or features of the Site may require registration or may otherwise ask you to provide
              information to participate in certain features or to access certain content. The decision to provide this
              information is purely voluntary and optional; however, if you elect not to provide such information, you
              may not be able to access certain content or participate in certain parts or features of the Site. You
              agree that you will not provide any false personal information to the Site, or create an account for
              anyone other than yourself without permission. You will also not create more than one personal profile,
              and if you select a username for your account, we reserve the right to remove or reclaim it if we believe
              appropriate (such as if a trademark owner complains about a username). If you register with the Site, you
              are responsible for maintaining the confidentiality of your password, if any, and for restricting access
              to your computer so that others may not access the password protected portion of the Site. You accept
              responsibility for all activities that occur under your account, email or password, if any, and agree you
              will not sell, transfer or assign your membership or any membership rights. Company may, in its sole
              discretion, and at any time, with or without notice, terminate your password and membership, for any
              reason or no reason at all. If we disable your account, you agree that you will not create another one
              without our permission.
            </p>

            <p>
              <strong id="3">License to the Site</strong>
              Company grants you a non-exclusive, non-transferable, limited right and license to access, use and
              privately display the Site and the materials thereon for your personal use only, provided that you comply
              fully with this TOS. You shall not interfere (or permit the use of your membership by a third party to
              interfere) or attempt to interfere with the operation or use of Site by other members in any way through
              any means or device including, but not limited to, spamming, hacking, uploading computer viruses or time
              bombs, or any other means expressly prohibited by any provision of this TOS.
            </p>

            <p>
              <strong id="4">Changes to Site and/or Terms and Conditions of Service</strong>
              Company reserves the right, from time to time, in its sole discretion, to change, modify, update,
              discontinue, remove, revise, delete or otherwise change any portion of the Site or this TOS, in whole or
              in part, at any time without further notice. For changes to these TOS that we deem material, we will place
              a notice on the site by revising the link on the homepage to read substantially as “Updated Terms of Use”
              for up to three (3) weeks or some other amount of time that we determine in our discretion. If you access
              or use the Site in any way after the TOS have been changed, you will be deemed to have read, understood
              and unconditionally consented to and agreed to such changes. The most current version of these TOS will be
              available on the site and will supersede all previous versions of these TOS.
            </p>

            <p>
              <strong id="5">Trademarks, Copyrights &amp; Restrictions</strong>
              The Site and all of the content it contains, or may in the future contain, including but not limited to
              text, video, pictures, graphics, designs, information, applications, software, music, audio files,
              articles, directories, guides, photographs as well as the trademarks, service marks, trade names, trade
              dress, copyrights, logos, domain names, code, patents and/or any other form of intellectual property
              (collectively, the “Material”) that relates to the Site (other than and except for “User Content” as
              defined herein) are owned by or licensed by Company or other third parties and are protected from any
              unauthorized use, copying and dissemination by copyright, trademark and other intellectual property and
              non-intellectual property laws and by international treaties. Except as expressly permitted in writing by
              site host , you shall not capture, reproduce, perform, transfer, sell, license, modify, create derivative
              works from or based upon, republish, reverse engineer, upload, edit, post, transmit, publicly display,
              frame, link, distribute or exploit, in whole or in part, any of the Material. Nothing contained in this
              Agreement or on the Site should be construed as granting, by implication, estoppel or otherwise, any
              license or right to use any Material in any manner without the prior written consent of Company or such
              third party that may own the Material or intellectual property displayed on the Site. UNAUTHORIZED USE,
              COPYING, REPRODUCTION, MODIFICATION, PUBLICATION, REPUBLICATION, UPLOADING, FRAMING, DOWNLOADING, POSTING,
              TRANSMITTING, DISTRIBUTING, DUPLICATING OR ANY OTHER MISUSE OF ANY OF THE MATERIAL IS STRICTLY PROHIBITED.
              Any use of the Material other than as permitted by this Agreement will violate this Agreement and may
              infringe upon our rights or the rights of the third party that owns the affected Material. You agree to
              report any violation of this Agreement by others that you become aware of. You are advised that Company
              will aggressively enforce its rights to the fullest extent of the law. Company may add, change,
              discontinue, remove or suspend any of the Material at any time, without notice and without liability.
            </p>

            <p>
              <strong id="6">Software</strong>
              From time to time, and at its sole discretion, Company may make available to users certain software that
              may be accessible or downloaded from this Site. In the event you access or download software from this
              Site, the software, including any files, images incorporated in or generated by the software, and data
              accompanying the software (collectively, the “Software”) are licensed to you by Company or a
              Company-approved third party software provider (“Third Party Provider”). Company does not transfer title
              to the Software to you. You own the medium on which the Software is recorded, but Company retains full and
              complete title to the Software, and all intellectual property rights therein. For purposes of these TOS,
              such Software shall be included in the definition of “Materials”. Furthermore, your use of any Software of
              a Third Party Provider shall be subject to the end user license agreement or any other terms of use set
              forth by such Third Party Provider for its Software.
            </p>

            <p>
              <strong id="7">Consent to Electronic Communications</strong>
              By registering with the Site, you understand that we may send you communications or data from Company
              regarding the Site via electronic mail, SMS, MMS, instant messaging, electronic chat, or other electronic
              delivery, including but not limited to (i) tasks, notices, videos, record keeping, and audio files; (ii)
              promotional information regarding the Site, (iii) updates; and (iv) notices about your use of the Site. By
              registering with Site you consent to such communications and understand that standard text and data
              charges may apply.
            </p>

            <p>
              <strong id="8">Unsolicited Submissions</strong>
              Company is pleased to hear from its customers and welcomes your comments regarding Company products,
              including Company’s online services. Unfortunately, however, Company’s long-standing company policy does
              not allow it to accept or consider creative ideas, suggestions, or materials other than those it has
              specifically requested (see below). While we value your feedback on our services and products, we request
              that you be specific in your comments on those services and products, and that you not submit any creative
              ideas, suggestions or materials, including but not limited to stories or character ideas, screenplays, or
              original artwork. We hope you will understand that it is the intent of this policy to avoid the
              possibility of future misunderstandings when projects developed by Company’s or its affiliates'
              professional staff might seem to others to be similar to their own creative work. Accordingly, we ask that
              you do not send us any original creative materials such as show designs, photographs, drawings, or
              original artwork that you expect to be compensated for or that you would like to keep private.
            </p>

            <p>
              If you send or post certain specific submissions at our request (e.g., via message boards or in connection
              with contests) or if you send us creative suggestions, ideas, notes, photographs, drawings, concepts, or
              any other information (each, a “Submission” and collectively, the “Submissions”) despite our request that
              you not send us any unsolicited Submissions or other creative materials, the Submission will be treated as
              non-confidential and non-proprietary in each instance. For purposes of this Paragraph, all User Content
              shall be deemed included in the definition of Submissions. None of the Submissions shall be subject to any
              obligation of confidence on the part of Company, and Company shall not be liable for any use or disclosure
              of any Submissions. Any Submission may be used by Company without restriction for any purpose whatsoever,
              including, without limitation, reproduction, disclosure, transmission, publication, broadcast or posting,
              and you hereby irrevocably waive, release and give up any claim that any use of such Submission violates
              any of your rights, including, without limitation, copyrights, trademarks, moral rights, privacy rights,
              proprietary or other property rights, publicity rights, or right to credit for the material or ideas.
              Company shall have and is hereby irrevocably granted the right, but not the obligation, to reproduce,
              modify, adapt, publish, broadcast, license, perform, post, sell, translate, incorporate, create derivative
              works from, exploit, distribute and otherwise use the Submission in any and all media, now known or
              hereafter devised, throughout the universe, in perpetuity, without according you any compensation or
              credit. By submitting a Submission to the Site or Company, you represent that such Submission is original
              with you and does not violate or infringe upon the rights of any third parties, including, without
              limitation, any intellectual property rights and rights of publicity and/or privacy. Submissions to the
              Site and/or Company will not be acknowledged or returned. You agree and understand that Company is not
              obligated to use any Submission you make to the Site or Company and you have no right to compel such use.
              You hereby acknowledge and agree that your relationship with Company is not a confidential, fiduciary, or
              other special relationship, and that your decision to submit any material to Company does not place
              Company in a position that is any different from the position held by members of the general public with
              regard to your Submission. You understand and acknowledge that Company has wide access to ideas, stories,
              designs, and other literary materials, and that new ideas are constantly being submitted to it or being
              developed by Company’s own employees. Many ideas or stories may be competitive with, similar or identical
              to your Submission in structure, purpose, function, theme, idea, plot, format or other respects. You
              acknowledge and agree that you will not be entitled to any compensation as a result of Company’s use of
              any such similar or identical material. Finally, you acknowledge that, with respect to any claim you may
              have relating to or arising out of Company’s actual or alleged exploitation or use of any material you
              submit to the Site and/or Company, the damage, if any, thereby caused will not be irreparable or otherwise
              sufficient to entitle you to injunctive or other equitable relief or to in any way enjoin the exploitation
              or other use of any Company film, television show, platform, product or service based on or allegedly
              based on the material, and your rights and remedies in any such event shall be strictly limited to the
              right to recover damages, if any, in an action at law. Applicable law may restrict or limit the foregoing
              provisions of this Paragraph. If so, without in any way limiting the foregoing, you agree that in no event
              shall Company’s liability exceed the amount payable to a writer under the Writers Guild of America Basic
              Agreement for a network prime time story or teleplay.
            </p>

            <p>
              <strong id="9">Linked Sites</strong>
              The Site may contain links to third party websites or resources, which may or may not be obvious (“Third
              Party Sites”) as well as software, text, graphics, articles, photographs, pictures, designs, sound, video,
              music, information, software applications and other content originating from third parties (collectively,
              “Third Party Applications, Software or Content”). Our provision of links to Third Party Sites is not an
              endorsement of any information, product or service that is offered on or reached through such Third Party
              Site or Third Party Application, Software or Content. Such Third Party Sites and Third Party Applications,
              Software or Content are not monitored or checked for accuracy, appropriateness, or completeness by us, and
              we are not responsible for the content or performance of any Third Party Sites accessed through the Site
              or any Third Party Applications, Software or Content posted on, available through or installed from the
              Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other
              policies of or contained in the Third Party Sites or the Third Party Applications, Software or Content. If
              you decide to leave the Site and access the Third Party Sites or to use or install any Third Party
              Applications, Software or Content, you do so at your own risk and you should be aware that our terms and
              policies no longer govern.
            </p>

            <p>
              YOU AGREE THAT YOUR USE OF THIRD-PARTY SITES OR THIRD PARTY APPLICATIONS, SOFTWARE OR CONTENT, INCLUDING,
              WITHOUT LIMITATION, YOUR USE OF ANY CONTENT, INFORMATION, DATA, ADVERTISING, PRODUCTS, OR OTHER MATERIALS
              ON OR AVAILABLE THROUGH SUCH WEBSITES AND RESOURCES, IS AT YOUR OWN RISK AND IS SUBJECT TO THE TERMS AND
              CONDITIONS OF USE APPLICABLE TO SUCH SITES AND RESOURCES.
            </p>

            <p>
              <strong id="10">Purchases</strong>
              AMAZON.COM WILL BE PROCESSING AND SHIPPING ANY PURCHASE ORDER MADE THROUGH THE SITE. COMPANY IS NOT
              RESPONSIBLE FOR ANY CONTENT APPEARING ON THE AMAZON.COM WEBSITE. PLEASE NOTE THAT AMAZON.COM’S CONDITIONS
              OF USE, SHIPPING RATES AND POLICIES AND RETURNS POLICY SHALL APPLY TO YOUR ORDER. AMAZON.COM’S CUSTOMER
              SERVICE WILL BE RESPONSIBLE FOR HANDLING YOUR CONCERNS AND ISSUES WITH YOUR ORDER. COMPANY EXPRESSLY
              DISCLAIMS ANY AND ALL RESPONSIBILITY FOR, OR LIABILITY TO ANY PERSON OR ENTITY ARISING OUT OF OR RELATED
              IN ANY WAY TO AMAZON.COM’S DECISIONS REGARDING SUCH MATTERS OR FOR ANY OTHER MATTERS RELATED TO
              AMAZON.COM’S WEBSITE. PLEASE NOTE THAT AMAZON.COM GIFT CERTIFICATES OR CREDITS WITH AMAZON.COM CANNOT BE
              USED IN CONNECTION WITH PURCHASES THROUGH THE SITE.
            </p>

            <p>
              <strong id="11">Forums and Public Communications</strong>
              As a convenience to its visitors, Company may provide, from time to time and at its sole discretion, one
              or more chat areas, message boards, bulletin boards, e-mail functions, instant messaging service,
              voice-mail, and other interactive areas as part of the Site (collectively, the “Forums”). Forums are
              provided by Company to you and others subject to this TOS, the “Community Guidelines” , (as defined below)
              the Privacy Policy, and other rules that may be published from time to time by Company in its sole
              discretion. The individual who posts messages, content or other information in the Forums (collectively,
              “User Content”) is responsible for the reliability, accuracy, and truthfulness of such content, and
              Company has no control over the same. Additionally, Company has no control over whether any such User
              Content is of a nature that users will find offensive, distasteful or otherwise unacceptable and expressly
              disclaims any responsibility for such material.
            </p>
            <p>
              Company does not endorse the User Content in the Forums and specifically disclaims any responsibility or
              liability to any person or entity (including, without limitation, persons who may use or rely on such
              material) for any loss, damage (whether actual, consequential, punitive or otherwise), injury, claim,
              liability or other cause of any kind or character based upon or resulting from any User Content provided
              through a Forum.
            </p>
            <p>
              Company does not and cannot review every message posted by users in the Forums, and is not responsible for
              the content of these messages or the views or opinions expressed by the users of the Forums. Information
              disclosed in the Forums is revealed to the public by design. Company reserves the right, but not the
              obligation, to delete, move or edit User Content, in whole or in part for any reason in Company’s sole
              discretion. In addition, Company may delete, move, edit or disclose User Content when it is required to do
              so by law or in a good faith belief that such action is necessary to protect and defend the rights and
              property of Company or to protect the safety of our users or the public. In no event does Company assume
              any obligation to monitor the Forums or remove any specific material.
            </p>
            <p>
              You understand that the uploading to and/or posting of any User Content in any Forum shall not be subject
              to any obligation of confidence on the part of Company, and Company shall not be liable for any use or
              disclosure of any User Content. In consideration for your use of the Forums and functionality, you agree
              to comply with the “Community Guidelines” set forth below. Without limiting Company’s other rights and
              remedies, individuals who violate the following Community Guidelines may, at Company’s sole discretion, be
              banned from using the Forums and/or the Site entirely.
            </p>

            <p>
              <strong id="12">Registration and Acceptance of Community Guidelines</strong>
              In consideration for your use of the Forums, you agree to (i) comply with this TOS and the Community
              Guidelines, (ii) provide Company with (A) accurate, complete and true information about yourself as
              required on the Forums registration form (your “Registration Information”) in order to create your Company
              Forum Account (your “Account”) and (iii) maintain and update, as applicable, your Registration Information
              with current and complete information. Users who violate this TOS, the Community Guidelines, or provide
              inaccurate, false, or non-current Registration Information may, at Company’s sole discretion, have their
              Account suspended or terminated, and may be permanently banned from using any Forum or the Site.
            </p>
            <p>
              ENTERING ANY FORUM WILL CONSTITUTE ACCEPTANCE OF THESE TERMS OF SERVICE AND YOUR COMPLIANCE WITH THE
              FOLLOWING GUIDELINES (THE “COMMUNITY GUIDELINES”) FOR USE OF THE FORUMS. IF YOU DO NOT AGREE TO ABIDE BY
              THESE TERMS OF SERVICE OR THE COMMUNITY GUIDELINES, PLEASE DO NOT ENTER ANY FORUM.
            </p>

            <p>
              <i>
                <b>Guidelines for Use of the Forums</b>
              </i>
              <br />
              You are entirely responsible and liable for all activities conducted by you and any authorized user of
              your Account in the Forums, including the transmission, posting, or other provision of User Content.
              Listed below are some, though not all, violations that may result in Company terminating or suspending
              your access to a Forum. You agree not to do any of the following actions while using any Forum:
              <br />
              Harass, threaten, embarrass or cause distress or discomfort upon another Forum participant, user, or other
              individual or entity;
            </p>

            <ol>
              <li>
                Transmit any User Content in any Forum that Company considers to be disruptive, unlawful, harmful,
                threatening, abusive, harassing, defamatory, vulgar, obscene, hateful, racially, ethnically or otherwise
                objectionable;
              </li>
              <li>
                Cause any chat room screen in any chat room to “scroll” faster than other users are able to type to it
                or any action of a similar disruptive effect;
              </li>
              <li>
                Misrepresent yourself, your age or your affiliation with any person or entity, impersonate in any Forum
                any person or entity, including but not limited to, a Company official, chat or message board leader,
                guide or host, or make false or misleading statements;
              </li>
              <li>
                Disrupt the normal flow of dialogue in a Company chat room or otherwise act in a manner that negatively
                affects other participants;
              </li>
              <li>
                Post or transmit any unsolicited advertising, promotional materials, or any other forms of solicitation
                in the Forums;
              </li>
              <li>
                Intentionally or unintentionally violate any applicable local, state, national or international law,
                including but not limited to any regulations having the force of law while using or accessing any Forum;
              </li>
              <li>Invade the privacy or violate any personal or proprietary right of any person or entity;</li>
              <li>
                Infringe the intellectual property rights or similar rights, including but not limited to copyrights and
                trademarks, of any person or entity
              </li>
              <li>
                Use the Site in any manner that could damage, impair, disable, overburden or harm the Site or circumvent
                the intended functionality of the Site;
              </li>
              <li>
                Collect information identifying users of the Site by electronic or other means without authorization
                from the person(s) affected;
              </li>
              <li>
                upload, post, transmit, send, share, store, distribute or otherwise make available on the Site any
                private or sensitive information or content about any third party, including, addresses, phone numbers,
                e-mail addresses, Social Security numbers and credit card numbers; and
              </li>
              <li>
                upload, post, transmit, send, share, store, distribute, or otherwise make available any viruses or any
                other computer code, files or programs designed to interrupt, destroy or limit the functionality of any
                computer software, hardware or other electronic or telecommunications equipment.
              </li>
            </ol>

            <p>
              By posting or uploading Content to the Site, any Forum or submitting any other User Content to Company,
              you automatically grant (or warrant that the owner of such rights has expressly granted) Company a
              perpetual, worldwide, royalty-free, irrevocable, non-exclusive right and license to reproduce, modify,
              adapt, publish, publicly perform, translate, sub-license, create derivative works from, exploit,
              distribute and otherwise use such materials or incorporate such User Content in or in connection with the
              Site or by or in any other media or technology now known or later developed throughout the universe in
              perpetuity. In addition, you represent and warrant that any and all User Content you upload, post,
              transmit, send, share, store, distribute, or otherwise make available on the Site complies with each of
              the foregoing Community Guidelines.
            </p>

            <p>
              <strong id="13">User Disputes</strong>
              You are solely responsible for your interactions with other Site users. We reserve the right, but have no
              obligation, to monitor disputes between you and other users.
            </p>

            <p>
              <strong id="14">Promotions</strong>
              From time to time, the Site may offer sweepstakes, contests or other promotions that require you to send
              material or information about yourself. Please note that sweepstakes, contests or promotions offered via
              the Site may be, and often are, governed by a separate set of rules that, in addition to describing such
              sweepstakes, contest or promotion, may have eligibility requirements, such as certain age or geographic
              area restrictions, terms and conditions governing the use of material you submit, and supplemental
              disclosures about how your personal information may be used. It is your responsibility to read such rules
              to determine whether or not you want to and are eligible to participate, register and/or enter. By
              entering any such sweepstakes, contest or other promotion, you agree to comply with abide by such rules
              and the decisions of the sponsor(s) identified therein, which shall be final and binding in all respects.
            </p>

            <p>
              <strong id="15">No Resale/Exploitation</strong>
              You understand and agree that you may not reproduce, copy, resell, manipulate, or exploit any part of the
              Site for any commercial purpose.
            </p>

            <p>
              <strong id="16">Non-United States Residents</strong>
              Company operates the Site in the United States. Company makes no representation that the Materials,
              including merchandise offered for sale on the Site and their copyrights, trademarks, patents, and
              licensing arrangements, are appropriate or available for use in locations other than the United States. If
              you access the Site from locations outside of the U.S. you do so on your own initiative and at your own
              risk, and you are solely responsible for compliance with local laws, if and to the extent local laws are
              applicable. With respect to shipments of merchandise to consumers, wherever they may reside, title to the
              merchandise and risk of loss shall pass to the buyer upon delivery of the merchandise to the common
              carrier.
            </p>

            <p>
              <strong id="17">Jurisdictional Issues</strong>
              The Site is controlled and operated by Company from its offices within the State of California, United
              States. Company makes no representation that materials in the Site are appropriate or available for use in
              other locations. Software from this Site is further subject to United States export controls. No software
              from this Site may be downloaded or otherwise exported or re-exported (i) into (or to a national or
              resident of) any countries that are subject to U.S. export restrictions; or (ii) to anyone on the U.S.
              Treasury Department's list of Specially Designated Nationals or the U.S. Commerce Department's Table of
              Deny Orders. By downloading or using the Software, you represent and warrant that you are not located in,
              under the control of, or a national or resident of any such country or on any such list.
            </p>

            <p>
              <strong id="18">Termination</strong>
              You understand and agree that Company may, in its sole discretion and at any time, terminate your
              password, Account or use of any Forum, and discard and remove any User Content posted or submitted by you
              to any Forum, and/or prohibit you from accessing the Site, in whole or in part, for any reason or no
              reason at all, at any time in its sole discretion, with or without notice. You understand and agree that
              Company may take any one or more of these actions without prior notice to you. Should Company take any of
              these actions, it may, in its sole discretion, immediately deactivate and/or delete any or all information
              about and concerning your Account, including your Registration Information and submitted User Content. You
              understand and agree that Company shall not have any liability to you or any other person for any
              termination of your access to any Forum and/or the removal of information concerning your Account. Company
              will determine your compliance with this Agreement in its sole discretion and its decision shall be final
              and binding and not subject to challenge or appeal. Any violation of this Agreement may result in
              restrictions on your access to all or part of the Site and may be referred to law enforcement authorities.
              No changes to or waiver of any part of this Agreement shall be of any force or effect unless formally
              posted or made in writing and signed by a duly authorized officer of Company. Upon termination of your
              membership or access to the Site, or upon demand by Company, you must destroy all materials obtained from
              the Site and all related documentation and all copies and installations thereof. You are advised that
              Company will aggressively enforce its rights to the fullest extent of the law.
            </p>

            <p>
              <strong id="19">Disclaimer</strong>
              The Site may be unavailable from time to time due to maintenance or malfunction of computer equipment or
              for various other reasons. Company assumes no responsibility for any delays, interruptions, errors,
              defects, omissions, or deletions, related to the communications line failure, operation or transmission,
              or alteration of, or theft or destruction or unauthorized access to, user communications. Company is not
              responsible for any technical or non-technical malfunction or other problems of any hosting services,
              computer systems, servers or providers, telephone networks or telephone services, computer or mobile phone
              equipment, software, failure of e-mail or players on account of technical problems or traffic congestion
              on the Internet or in connection with the Site, including injury or damage to a user’s or to any other
              person's computer, mobile phone, or other hardware or software, related to or resulting from using or
              downloading materials in connection with the Web and/or in connection with the Site.{' '}
            </p>
            <p>
              THE SITE, THE MATERIALS, AND THE SOFTWARE, IF APPLICABLE, ARE PROVIDED “AS IS” “WITH ALL FAULTS” AND “AS
              AVAILABLE” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED INCLUDING, WITHOUT LIMITATION,
              IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR THOSE
              ARISING BY STATUTE OR OTHERWISE IN LAW FROM A COURSE OF DEALING OR USAGE OF TRADE. TO THE FULLEST EXTENT
              PERMISSIBLE PURSUANT TO APPLICABLE LAW, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
              BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY
              DOES NOT WARRANT THAT THE AVAILABILITY OF OR THE FUNCTIONS CONTAINED IN THE SITE, THE MATERIALS, THE
              FORUMS OR THE SOFTWARE, WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE
              SITE OR THE SERVER THAT MAKES IT AVAILABLE OR THE SOFTWARE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
              OR THAT THE SITE, MATERIALS SOFTWARE OR SERVER DO NOT VIOLATE ANY PATENT OR OTHER INTELLECTUAL PROPERTY
              RIGHTS OF ANY PERSON OR ENTITY. COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR
              THE RESULTS OF THE USE OF THE SITE, THE MATERIALS, THE FORUMS OR THE SOFTWARE, IN TERMS OF THEIR
              CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT COMPANY) ASSUME THE ENTIRE COST OF ALL
              NECESSARY SERVICING, REPAIR, OR CORRECTION. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT FULLY APPLY TO YOU.
            </p>

            <p>
              <strong id="20">Limitation of Liability</strong>
              YOU AGREE THAT COMPANY AND ITS PARENTS, AFFILIATES, SUBSIDIARIES, LICENSORS AND ASSIGNS, AND EACH OF THEIR
              RESPECTIVE EMPLOYEES, OFFICERS AND DIRECTORS (COLLECTIVELY, THE “RELEASED PARTIES”) ARE NOT LIABLE TO YOU
              OR ANY THIRD PERSON FOR DAMAGES OF ANY KIND, WHETHER BASED IN TORT, CONTRACT, STRICT LIABILITY OR
              OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
              PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING OUT OF OR RESULTING IN ANY WAY FROM
              OR IN CONNECTION WITH THE SITE, THE MATERIAL, THE FORUMS, ANY TRANSACTIONS IN THE COMPANY SHOP, ANY
              LISTING OR ANY ERRORS OR OMISSIONS IN THE TECHNICAL OPERATION OF THE SITE, EVEN IF THE COMPANY IS AWARE OR
              HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER CAUSED IN WHOLE OR IN PART BY NEGLIGENCE,
              ACTS OF GOD, TELECOMMUNICATIONS FAILURE, THEFT OR DESTRUCTION OF, OR UNAUTHORIZED ACCESS TO THE SITE
              (COLLECTIVELY, THE “RELEASED MATTERS”). NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
              COMPANY’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
              TIMES BE LIMITED TO $1000. BY ACCESSING THE SITE, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH
              RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU
              ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF SECTION 1542 OF
              THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS:{' '}
            </p>
            <p>
              “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS
              OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
              AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
            </p>
            <p>
              You hereby waive any and all rights you have or may have under California Civil Code Section 1542, and/or
              any similar provision of law or successor statute to it, with respect to the Released Matters. In
              connection with this waiver and release, you acknowledge that you are aware that you may hereafter
              discover claims presently unknown or unsuspected, or facts in addition to or different from those which
              you now know or believe to be true. Nevertheless, you intend by this Agreement to release fully, finally
              and forever all Released Matters under this Agreement. In furtherance of such intention, the releases set
              forth in this Agreement shall be and shall remain in effect as full and complete releases notwithstanding
              the discovery or existence of any such additional or different claims or facts relevant hereto.
            </p>
            <p>
              Company makes no representation or warranty whatsoever regarding the completeness, accuracy, currency or
              adequacy of any information, facts, views, opinions, statements or recommendations contained on the Site
              and/or the Material. Reference to any product, process, publication or service of any third party by trade
              name, domain name, trademark, service mark, logo, manufacturer or otherwise does not constitute or imply
              its endorsement or recommendation by Company. Views and opinions of users of the Site do not necessarily
              state or reflect those of Company. Users are responsible for seeking the advice of professionals, as
              appropriate, regarding the information, opinions, advice or content available as part of the Site.
            </p>
            <p>
              The Internet may be subject to breaches of security. Company is not responsible for any resulting damage
              to any user's computer from any such security breach, or from any virus, bugs, tampering, unauthorized
              intervention, fraud, error, omission, interruption, deletion, defect, delay in operation or transmission,
              computer line failure or any other technical or other malfunction. You should also be aware that email
              submissions over the Internet may not be secure, and you should consider this before submitting any
              information to anyone over the internet. Company makes no representation or warranty whatsoever regarding
              the suitability, functionality, availability or operation of the Site.
            </p>

            <p>
              <strong id="21">Linking Policy</strong>
              If you link to the Site, we require that you follow these guidelines. You may link only to the home page,
              and the link must be in plain text, unless otherwise approved in writing by an authorized representative
              of Company. The link to this Site must not damage, dilute or tarnish the goodwill associated with any
              Company names and/or intellectual property, nor may the link create the false appearance that your website
              and/or organization is sponsored, endorsed by, affiliated and/or associated with Company. You may not
              “frame” the Site or alter its intellectual property or Material in any other way. You may not link to the
              Site from a site that is unlawful, abusive, indecent or obscene, that promotes violence or illegal acts,
              that contains expressions of racism, that is libelous, defamatory, scandalous, or inflammatory or is
              otherwise deemed inappropriate, as determined by Company in its sole discretion. Company reserves the
              right, in its sole discretion, to terminate a link with any website for any reason or no reason at all,
              including without limitation any website that Company deems to be inappropriate or inconsistent with or
              antithetical to the Site and/or these Terms of Use.
            </p>
            <p>
              Company is not responsible for the content or performance of any portion of the Internet including other
              World Wide Websites to which this Site may be linked or from which this Site may be accessed.{' '}
            </p>

            <p>
              <strong id="22">Copyright Notice</strong>
              If you believe that any Content appearing on the Site or in any Forum has been copied in a way that
              constitutes copyright infringement, please forward the following information to the Copyright Agent named
              below:
            </p>

            <p>
              1. Your name, address, telephone number, and email address; <br />
              2. A description of the copyrighted work that you claim has been infringed; <br />
              3. The exact URL or a description of where the alleged infringing material is located; <br />
              4. A statement by you that you have a good faith belief that the disputed use is not authorized by the
              copyright owner, its agent, or the law; <br />
              5. An electronic or physical signature of the person authorized to act on behalf of the owner of the
              copyright interest; and <br />
              6. A statement by you, made under penalty of perjury, that the above information in your Notice is
              accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
            </p>

            <p>
              <b>Copyright Agent</b>
              <br />
              Lionsgate
              <br />
              2700 Colorado Ave., Suite 200
              <br />
              Santa Monica, CA 90404
              <br />
              Attn: Business Affairs
              <br />
              E-Mail: <a href="mailto:general-inquiries@lionsgate.com">general-inquiries@lionsgate.com</a>
            </p>

            <p>
              By this filing, Company seeks to preserve any and all exemptions from liability that may be available
              under the copyright law, but does not necessarily stipulate that it is a service provider as defined in 17
              U.S.C Section 512(c) or elsewhere in the law.
            </p>

            <p>
              <strong id="23">Indemnification</strong>
              BY USING THE SITE YOU AGREE TO INDEMNIFY, DEFEND AND HOLD THE RELEASED PARTIES HARMLESS FROM AND AGAINST
              ANY THIRD PARTY CLAIMS, ALLEGED CLAIMS, DEMANDS, CAUSES OF ACTION, JUDGMENTS, DAMAGES, LOSSES,
              LIABILITIES, AND ALL COSTS AND EXPENSES OF DEFENSE, INCLUDING, WITHOUT LIMITATION, REASONABLE ATTORNEYS'
              FEES, ARISING OUT OF OR RELATING TO: YOUR BREACH OF YOUR REPRESENTATIONS, WARRANTIES, COVENANTS OR
              AGREEMENTS HEREUNDER; YOUR VIOLATION OF THESE TERMS OF USE OR ANY LAW; YOUR USE OF THIS SITE AND/OR THE
              MATERIAL IN VIOLATION OF THESE TERMS OF USE; INFORMATION OR MATERIAL POSTED OR TRANSMITTED THROUGH YOUR
              COMPUTER OR MEMBERSHIP ACCOUNT, EVEN IF NOT SUBMITTED BY YOU, THAT INFRINGES ANY COPYRIGHT, TRADEMARK,
              TRADE SECRET, TRADE DRESS, PATENT, PUBLICITY, PRIVACY OR OTHER RIGHT OF ANY PERSON OR DEFAMES ANY PERSON;
              ANY MISREPRESENTATION MADE BY YOU; AND/OR COMPANY’S USE OF YOUR INFORMATION. YOU WILL COOPERATE AS FULLY
              AND AS REASONABLY REQUIRED IN COMPANY’S DEFENSE OF ANY CLAIM. COMPANY RESERVES THE RIGHT, AT ITS OWN
              EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY
              YOU, AND YOU SHALL NOT IN ANY EVENT SETTLE ANY SUCH MATTER WITHOUT THE WRITTEN CONSENT OF COMPANY.
            </p>
            <p>
              <strong id="24">Filtering</strong>
              Pursuant to 47 U.S.C. Section 230(d), as amended, Company hereby notifies you that parental control
              protections (such as computer hardware, software or filtering services) are commercially available that
              may assist you in limiting access to material that is harmful to minors. Information identifying current
              providers of such protections is available at:
            </p>

            <p>
              <a
                href="http://dir.yahoo.com/Business_and_Economy/Shopping_and_Services/Communication_and_Information_Management/Internet_and_World_Wide_Web/Software/Blocking_and_Filtering/"
                target="blank"
              >
                http://dir.yahoo.com/Business_and_Economy/Shopping_and_Services/...
              </a>
            </p>

            <p>Please note that Lionsgate does not endorse any of the products or services listed at such site.</p>

            <p>
              <strong id="25">Miscellaneous</strong>
              <br />
              This TOS constitutes the entire agreement of the parties with respect to the subject matter hereof and
              supersedes all prior or contemporaneous written or oral agreements between the parties with respect to the
              subject matter hereof. This TOS may not be amended, nor any obligation waived, without Company’s written
              authorization. Any failure to enforce any provision of this TOS shall not constitute a waiver thereof or
              of any other provision thereof.
            </p>

            <p>
              This TOS shall be governed and construed in accordance with the laws of the State of California applicable
              to contracts entered into and fully performed in California (without regard to its conflicts of law
              principles that would cause the application of any other jurisdiction's laws) With respect to any disputes
              or claims not subject to arbitration, you agree not to commence or prosecute any action in connection
              therewith other than in the state and federal courts of California, and you hereby consent to, and waive
              all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and
              jurisdiction in the state and federal courts of California.
            </p>

            <p>
              By using the Site in any way, you unconditionally consent and agree that: (1) any claim, dispute, or
              controversy (whether in contract, tort, or otherwise) you may have against the officers, directors and
              employees of Company and its parent, subsidiaries, affiliates (all such individuals and entities
              collectively referred to herein as the “Company Entities”) arising out of, relating to, or connected in
              any way with the website or the determination of the scope or applicability of this agreement to
              arbitrate, will be resolved exclusively by final and binding arbitration administered by JAMS and
              conducted before a sole arbitrator in accordance with the rules of JAMS; (2) this arbitration agreement is
              made pursuant to a transaction involving interstate commerce, and shall be governed by the Federal
              Arbitration Act (“FAA”), 9 U.S.C. §§ 1-16; (3) the arbitration shall be held in Los Angeles, California;
              (4) the arbitrator’s decision shall be controlled by the terms and conditions of this Agreement and any of
              the other agreements referenced herein that the applicable user may have entered into in connection with
              the website; (5) the arbitrator shall apply California law consistent with the FAA and applicable statutes
              of limitations, and shall honor claims of privilege recognized at law; (6) there shall be no authority for
              any claims to be arbitrated on a class or representative basis, arbitration can decide only your and/or
              the applicable Company Entity’s individual claims; the arbitrator may not consolidate or join the claims
              of other persons or parties who may be similarly situated; (7) the arbitrator shall not have the power to
              award punitive damages against you or any Company Entity; (8) in the event that the administrative fees
              and deposits that must be paid to initiate arbitration against any Company Entity exceed $125 USD, and
              your are unable (or not required under the rules of JAMS) to pay any fees and deposits that exceed this
              amount, Company agrees to pay them and/or forward them on your behalf, subject to ultimate allocation by
              the arbitrator. In addition, if you are able to demonstrate that the costs of arbitration will be
              prohibitive as compared to the costs of litigation, Company will pay as much of your filing and hearing
              fees in connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from
              being cost-prohibitive; and (9) with the exception of subpart (6) above, if any part of this arbitration
              provision is deemed to be invalid, unenforceable or illegal, or otherwise conflicts with the rules of
              JAMS, then the balance of this arbitration provision shall remain in effect and shall be construed in
              accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision were not
              contained herein. If, however, subpart (6) is found to be invalid, unenforceable or illegal, then the
              entirety of this Arbitration Provision shall be null and void, and neither you nor Company shall be
              entitled to arbitrate their dispute. For more information on JAMS and/or the rules of JAMS, visit their
              website at{' '}
              <a href="http://www.jamsadr.com" target="blank">
                www.jamsadr.com
              </a>
              . You may also contact the Complaint Assistance Unit of the Division of Consumer Services of the
              Department of Consumer Affairs in writing at 400 R Street, Suite 1080, Sacramento, California 95814, or by
              telephone at (916) 445-1254 or (800) 952-5210 in connection with any potential claim in connection with
              the Site.
            </p>
            <p>
              You hereby irrevocably waive any right to seek and/or obtain rescission, equitable and/or injunctive
              relief related to Company’s production, distribution, license, and/or exploitation of any of their motion
              pictures, television shows, commercials and/or other content; and your exclusive remedy in connection
              therewith shall be an action for damages.
            </p>

            <p>
              If any provision of these terms shall be unlawful, void, or for any reason unenforceable, then that
              provision shall be deemed severable from these terms and shall not affect the validity and enforceability
              of any remaining provisions.
            </p>
            <p>
              This Site may contain “forward-looking statements” within the meaning of the Private Securities Litigation
              Reform Act of 1995. These forward-looking statements reflect our current views with respect to future
              events and are based on assumptions and are subject to risks and uncertainties. Except for our ongoing
              obligation to disclose material information as required by federal securities laws, we undertake no
              obligation to update you concerning any future revisions to any forward-looking statements. Factors that
              could cause actual results to differ materially from those expressed or implied by the forward-looking
              statements include, but are not limited to, those risk factors contained in our filings with the
              Securities and Exchange Commission.
            </p>

            <p>
              For any questions, suggestions, or concerns related to these Terms of Use, please email{' '}
              <a href="mailto:general-inquiries@lionsgate.com">general-inquiries@lionsgate.com</a>. Alternatively, you
              may contact Company via postal mail at Terms of Use Questions, c/o Lionsgate, Investor Relations, 2700
              Colorado Ave., Suite 200, Santa Monica, CA 90404.
            </p>

            <p>©2018 Lionsgate Entertainment Inc. All rights reserved.</p>
          </section>
        </div>
      </div>
    </div>
  );
};
