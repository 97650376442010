import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { ModeratorButton, AttendeeButton, DevicePermissionPrompt } from '@mediafellows/react-video-conference';
import { useDataContext } from '@mediafellows/react-video-conference/chime/hooks';
import { format } from 'date-fns';

const Home: FC = observer(() => {
  const { conferenceStore, session } = useDataContext();
  const { isAdmin, conference } = conferenceStore;
  const { welcome_text, meeting_ended_text, meeting_ended_alt_text } = conference || {};
  const { isPublic } = session;
  const finished = conference.status === 'finished';
  return (
    <main>
      <div className="chime__container--with-spacing chime__container--centered">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <img
                loading="lazy"
                className="form-box__logo mt-10"
                src="/static-assets/img/logo-distribution.svg"
                alt="Lionsgate"
              />
              <div className="row justify-content-center mt-10">
                <div className="col-10">
                  {conference && (
                    <div>
                      <h1 className="chime__header h2 fw-bolder mb-7">{conference.title}</h1>
                      {conference.starts_at && !finished && (
                        <div className={'chime__moderator-welcome fs-4'}>
                          This meeting is scheduled to begin {format(new Date(conference.starts_at), 'yyyy MMMM dd, p')}
                        </div>
                      )}

                      {finished && !isPublic && (
                        <div
                          className={'chime__moderator-welcome fs-4'}
                          dangerouslySetInnerHTML={{ __html: meeting_ended_text }}
                        />
                      )}

                      {finished && isPublic && (
                        <div
                          className={'chime__moderator-welcome fs-4'}
                          dangerouslySetInnerHTML={{ __html: meeting_ended_alt_text }}
                        />
                      )}
                    </div>
                  )}

                  {!finished && isAdmin && (
                    <>
                      <div
                        className={'chime__moderator-welcome fs-4'}
                        dangerouslySetInnerHTML={{ __html: welcome_text }}
                      />
                      <ModeratorButton className="btn btn-outline-primary btn-lg" />
                    </>
                  )}

                  {!finished && !isAdmin && (
                    <>
                      <AttendeeButton
                        memberBtnClass="btn btn-outline-primary btn-lg"
                        guestBtnClass="btn btn-outline-primary btn-lg"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DevicePermissionPrompt />
    </main>
  );
});

Home.displayName = 'Home';

export default Home;
