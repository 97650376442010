import React from 'react';

export const Contacts = () => {
  return (
    <div className="container mt-15 mb-5">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <h1 className="mb-10">Contact Us</h1>
          <p className="mb-0">
            For general information please contact <a href="mailto:lgtv@lionsgate.com">lgtv@lionsgate.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};
