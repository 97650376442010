import React from 'react';
import { TopSection, Loading, AccountSidemenu, ProductList } from 'components';
import { Routes } from 'routes';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';
import useSWR from 'swr';
import { fetchRecommendations } from 'utils/api/fetch-recommendations';
import { fetchRecommendationUsers } from 'utils/api/fetch-recommendation-users';
import { fetchRecommendationProducts } from 'utils/api/fetch-recommendation-products';
import { FetchActions } from 'types';

export const RecommendationsList = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: recommendations = [], isLoading: isRecommendationsLoaded } = useSWR(
    FetchActions.RECOMMENDATIONS,
    fetchRecommendations,
  );

  const usersIds = recommendations.map((item) => item.user_id).filter(Boolean);
  const productIds = [...new Set(recommendations.flatMap((item) => item.product_ids).filter(Boolean))];
  const { data: users = [], isLoading: isUsersLoaded } = useSWR(
    usersIds.length ? [FetchActions.RECOMMENDATION_USERS, usersIds] : null,
    ([, usersIds]) => fetchRecommendationUsers(usersIds),
  );
  const { data: products = [], isLoading: isProductsLoaded } = useSWR(
    productIds.length ? [FetchActions.RECOMMENDATION_PRODUCTS, productIds] : null,
    ([, productIds]) => fetchRecommendationProducts(productIds),
  );

  const loading = isRecommendationsLoaded || isUsersLoaded || isProductsLoaded;

  let allRecommendations: any[] = recommendations;
  const isDetailPage = !!id;
  let sectionTitle = 'Recommended';
  const rootBreadcrumb = [];
  let breadcrumbs = [];
  let subTitle;

  if (isDetailPage) {
    allRecommendations = [allRecommendations.find((x) => x.id == id)];
    sectionTitle = allRecommendations?.[0]?.subject;
    rootBreadcrumb.push('Recommendations');
    rootBreadcrumb.push(Routes.RECOMMENDATIONS_LIST);
    breadcrumbs = [{ name: sectionTitle }];
    const recoDate = allRecommendations?.[0]?.created_at;
    const recoSender = users?.find(({ id }) => allRecommendations?.[0].user_id === id);
    subTitle = (
      <div className="mb-5 text-gray-300">
        From{' '}
        <a href={`mailto:${recoSender?.email}`}>
          {recoSender?.first_name} {recoSender?.last_name}
        </a>{' '}
        on <span className="text-capitalize">{moment.utc(recoDate).format('MMMM, Do YYYY')}</span>
      </div>
    );
  }

  const hasRecos = !!allRecommendations?.length;

  return (
    <>
      <TopSection
        title={sectionTitle}
        rootBreadcrumb={rootBreadcrumb}
        breadcrumbs={breadcrumbs}
        subTitle={subTitle}
        isSmall={true}
      />
      <div className="container-fluid--custom">
        {!isDetailPage && <AccountSidemenu />}
        <div className="row">
          {loading && (
            <div className="col-12">
              <Loading />
            </div>
          )}
          {!loading && !hasRecos && (
            <div className="recommendation-lists col-12 col-lg-8">
              <div className="row">
                <div className="col-12">
                  <section className="py-10 text-center">
                    <h5>No recommendations found</h5>
                  </section>
                </div>
              </div>
            </div>
          )}
          {!loading &&
            hasRecos &&
            allRecommendations.map((reco, index) => {
              const recoTitle = reco?.subject;
              const recoDate = reco?.created_at;
              const recoSender = users?.find(({ id }) => reco.user_id === id);
              const recoProducts = products?.filter(({ id }) => reco.product_ids.includes(id));
              const recoProductsPreview = id ? recoProducts : recoProducts?.slice(0, 4);

              return (
                <div
                  className={cx('recommendation-lists col-12', {
                    'col-lg-12': isDetailPage,
                    'col-lg-8': !isDetailPage,
                  })}
                  key={index}
                >
                  <div className="row">
                    <div className="col-12">
                      {!isDetailPage && <h3 className="text-capitalize">{recoTitle}</h3>}
                      {!isDetailPage && (
                        <div className="row row-cols-1 row-cols-lg-2">
                          <div className="col">
                            <div className="mb-5 text-gray-300">
                              From{' '}
                              <a href={`mailto:${recoSender?.email}`}>
                                {recoSender?.first_name} {recoSender?.last_name}
                              </a>{' '}
                              on <span className="text-capitalize">{moment.utc(recoDate).format('MMMM, Do YYYY')}</span>
                            </div>
                          </div>
                          <div className="col d-flex justify-content-end mb-4 mb-lg-0">
                            <div className="text-nowrap">
                              <Link className="view-all-link" to={`${Routes.RECOMMENDATIONS_LIST}/${reco.id}`}>
                                View All ({recoProducts.length})
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}

                      <ProductList
                        keyName={'reco-lists'}
                        products={recoProductsPreview}
                        showTotalTitles={isDetailPage}
                        total={recoProductsPreview.length}
                        onClick={(id) => {
                          history.push(`${Routes.RECOMMENDATIONS_PRODUCT}/${reco.token}/${id}`);
                        }}
                        containerClass=""
                        columnsClass={cx('col-12 col-md-6', {
                          'col-lg-4': isDetailPage,
                          'col-lg-3': !isDetailPage,
                        })}
                      />

                      <hr className="mb-8" />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
