import React from 'react';
import { Route, Redirect } from 'react-router';
import { Routes } from 'routes';
import { rememberLocation } from 'utils/path';
import { useSessionStore } from 'store/session-store/session-store';

const PrivateRoute = ({ children, ...rest }) => {
  const session = useSessionStore((state) => state.session);
  const is2FARedirect = useSessionStore((state) => state.is2FArequired);

  const handleRender = ({ location }) => {
    if (is2FARedirect) {
      rememberLocation(location);
      return <Redirect to={Routes.TWO_FACTOR_AUTH} />;
    }

    if (!session || !session.isAuthenticated) {
      rememberLocation(location);
      return <Redirect to={Routes.SIGN_IN} />;
    }

    return children;
  };

  return <Route {...rest} render={handleRender} />;
};

export default PrivateRoute;
