import { chipmunk } from 'utils/chipmunk';
import { productSchema } from 'pages/product/schemas';
import { Model, Product } from 'types';
import { IResult } from '@mediafellows/chipmunk/dist/src/action';
import { errorHandler } from 'utils/error-handler';

export const fetchProduct = (id: number): Promise<IResult<Product>> => {
  return chipmunk.run(
    async ({ action }) => {
      const { object: product } = await action(Model.PRODUCTS, 'get', {
        params: { product_ids: [id] },
        schema: productSchema,
        headers: { 'Mpx-Trace': 'true' },
      });

      return product;
    },
    (e: Error) => {
      errorHandler(e, { title: 'Failed to load product' });
    },
  );
};
