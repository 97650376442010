import React, { useEffect } from 'react';
import { chipmunk } from 'utils';
import { useHistory, useParams } from 'react-router-dom';
import { Icon, Loading, ProductList, SavedListItem, TopSection } from 'components';
import { Actions, Modal, Model, NotificationsClassname, OFFCANVAS } from 'types';
import { Routes } from 'routes';
import useSWRInfinite from 'swr/infinite';
import './styles.scss';
import { toggleOffcanvas } from 'store/offcanvas-store';
import { CategoryFilters } from 'pages/category/category-filters';
import { useBaskets } from 'utils/hooks/use-baskets';
import { useBasketsProducts } from 'utils/hooks/use-basket-products';
import { BasketsStore, useBasketsStore } from 'store/baskets-store/baskets-store';
import { useShallow } from 'zustand/react/shallow';
import { applyFilters } from 'store/apply-filters';
import { useInViewport } from '@mantine/hooks';
import { useSessionStore } from 'store/session-store/session-store';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { productSchema } from 'pages/product/schemas';

export const SavedList = () => {
  const history = useHistory();
  const initialLoadDone = useSessionStore((state) => state.initialLoadDone);

  const { ref, inViewport } = useInViewport();

  const session = useSessionStore((state) => state.session);

  const { id } = useParams<{ id: string }>();
  const isRootRoute = !id;
  const isAllFavorites = id === 'favorites';

  const basket = useBaskets(session?.user?.id);
  const { basketData: baskets, isBasketsLoading, mutateBaskets } = basket;
  const basketProductsData = useBasketsProducts(baskets);
  const { basketProducts, isBasketProductsLoading } = basketProductsData;
  const isLoadingBaskets = isBasketsLoading || isBasketProductsLoading;

  const { filters, page, per, sort, order } = useBasketsStore(
    useShallow(
      ({ filters, page, per, sort, order }): Partial<BasketsStore> => ({
        filters,
        per,
        sort,
        order,
        page,
      }),
    ),
  );

  const swr = useSWRInfinite(
    (pageIndex) =>
      initialLoadDone && !isRootRoute && !isAllFavorites
        ? ['saved-list-load-products', pageIndex + 1, per, sort, order, filters, id]
        : null,
    async (params) => {
      const [, page, per, sort, order, filters, id] = params;

      const updatedFilters = Object.values(filters)
        .filter((item) => item.payload)
        .map((item) => item.payload);

      return chipmunk.run(
        () =>
          chipmunk.action(Model.PRODUCTS, 'search_basket', {
            params: { basket_id: id },
            body: {
              sort,
              order,
              page,
              per,
              only_roots: 'false',
              search: {
                filters: updatedFilters,
              },
            },
            schema: productSchema,
          }),
        (e: Error) => console.log(e),
      );
    },
    { keepPreviousData: true, parallel: true },
  );

  const { data, isLoading, setSize, size, mutate } = swr;
  const lastPageData = data?.length ? data[data.length - 1] : {};
  const { pagination } = lastPageData;
  const { total_count = 0, total_pages } = pagination ?? {};
  const products = data?.flatMap((page) => page.objects) ?? [];

  const initialLoading = !initialLoadDone || (typeof data === 'undefined' && isLoading);
  const isLoadingMore = !initialLoading && (isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'));
  const allFavoriteProducts = Object.values(basketProducts).flat();
  const noResults = !initialLoading && (isAllFavorites ? allFavoriteProducts?.length === 0 : products?.length === 0);

  useEffect(() => {
    if (initialLoading || noResults || !inViewport || isLoadingMore) {
      return;
    }

    // page increment will call setSize
    if (size < total_pages) {
      useBasketsStore.setState((state) => ({
        page: state.page + 1,
      }));
    }
  }, [inViewport, isLoadingMore, size, total_pages, initialLoading, noResults]);

  // control size from the store
  useEffect(() => {
    if (page !== size) {
      setSize(page);
    }
  }, [page, setSize, size]);

  const handleCreate = () => {
    modals.openContextModal({
      modal: 'editList',
      id: Modal.EDIT_LIST,
      innerProps: {
        mutateBaskets,
      },
      withCloseButton: false,
    });
  };

  const handleEdit = async (basket): Promise<void> => {
    modals.openContextModal({
      innerProps: {
        basket,
        onSave: mutateBaskets,
      },
      modal: 'editList',
      withCloseButton: false,
    });
  };

  const handleDeleteBasket = async (basketId, redirect = false): Promise<void> => {
    modals.openContextModal({
      modal: 'confirmation',
      id: Modal.CONFIRMATION_MODAL,
      innerProps: {
        bodyText: 'Do you want to delete this list?',
        confirmText: 'Delete',
        confirmAction: () => {
          return chipmunk.run(
            async ({ action }) => {
              await action('pm.basket', 'member.delete', {
                params: { basket_id: basketId },
              });

              mutateBaskets();
              notifications.show({
                message: 'List deleted',
                className: NotificationsClassname.SUCCESS,
              });
              if (redirect) history.push(`${Routes.SAVED_LISTS}`);
              return true;
            },
            () => {
              notifications.show({
                message: 'List cannot be deleted',
                color: 'red',
              });
              return false;
            },
          );
        },
      },
      withCloseButton: false,
    });
  };

  const hasBaskets = Boolean(baskets.length);

  const handleClearClick = async () => {
    applyFilters({ action: Actions.RESET, store: useBasketsStore });
  };

  const currentBasket = baskets?.find((basket) => basket.id == id);
  const name = isAllFavorites ? 'All Favorites' : currentBasket?.name;

  const handleProductDelete = async (e) => {
    const productId = e.currentTarget.dataset.id;
    modals.openContextModal({
      modal: 'confirmation',
      id: Modal.CONFIRMATION_MODAL,
      innerProps: {
        bodyText: 'Do you want to delete this item?',
        confirmText: 'Delete',
        confirmAction: () => {
          return chipmunk.run(
            async ({ action }) => {
              await action(Model.PRODUCTS, 'remove_from_basket', {
                params: { basket_id: Number(id), product_ids: [Number(productId)] },
              });
              mutate();
              notifications.show({
                message: 'Item removed from list',
                color: 'green',
              });
              return true;
            },
            () => {
              notifications.show({
                message: 'Item cannot be deleted',
                color: 'red',
              });
              return false;
            },
          );
        },
      },
      withCloseButton: false,
    });
  };

  if (isRootRoute) {
    return (
      <div className="saved-list">
        <TopSection
          store={'baskets'}
          title="Your lists"
          isSmall={true}
          customActions={
            <div className="d-flex align-items-start align-items-lg-end justify-content-end small flex-column">
              <div className="custom-link" role="button" onClick={handleCreate}>
                CREATE LIST +
              </div>
            </div>
          }
        />
        {isLoadingBaskets && <Loading />}

        {!isLoadingBaskets && (
          <div className="container-fluid--custom">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
              <div className="col mb-4">
                <SavedListItem id="favorites" title="All favorites" products={allFavoriteProducts} hasActions={false} />
              </div>

              {baskets?.map((basket) => {
                const products = basketProducts[basket.id];

                return (
                  <div className="col mb-4" key={basket.id}>
                    <SavedListItem
                      id={basket.id}
                      title={basket.name}
                      products={products}
                      onEdit={() => handleEdit(basket)}
                      onDelete={() => handleDeleteBasket(basket.id)}
                    />{' '}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {!isLoadingBaskets && !hasBaskets && (
          <section className="py-10 text-center">
            <h5>No saved lists found</h5>
          </section>
        )}
      </div>
    );
  }

  return (
    <div className="saved-list-details">
      <TopSection
        isSmall={true}
        rootBreadcrumb={['Saved Lists', Routes.SAVED_LISTS]}
        breadcrumbs={[{ name }]}
        title={name}
        store={'baskets'}
        onFiltersClick={() =>
          toggleOffcanvas(OFFCANVAS.SAVED_LIST_FILTERS, <CategoryFilters key={OFFCANVAS.SAVED_LIST_FILTERS} />)
        }
        onClearClick={handleClearClick}
        customActions={
          !isAllFavorites ? (
            <div className="d-flex align-items-start align-items-lg-end justify-content-end small flex-column">
              <div className="saved-list-details__actions mb-5">
                <div
                  className="saved-list-details__btn p-3"
                  title="Edit"
                  onClick={() => handleEdit(baskets.find((x) => x.id == id))}
                >
                  <Icon name="pencil-square" />
                </div>
                <div
                  className="saved-list-details__btn p-3"
                  title="Delete"
                  onClick={() => handleDeleteBasket(id, true)}
                >
                  <Icon name="x-lg" />
                </div>
              </div>
            </div>
          ) : null
        }
      />

      {initialLoading && <Loading />}

      {noResults && (
        <section className="py-10 text-center">
          <h5>No titles found</h5>
        </section>
      )}

      {!initialLoading && !noResults && (
        <>
          <ProductList
            products={isAllFavorites ? allFavoriteProducts : products}
            keyName={'saved-lists'}
            total={isAllFavorites ? allFavoriteProducts.length : total_count}
            onDelete={isAllFavorites ? null : handleProductDelete}
          />
          {isLoadingMore && (
            <div
              className="fixed-bottom p-3 rounded m-auto d-flex align-items-center bg-primary--main mb-10 justify-content-center"
              style={{ maxWidth: 170 }}
            >
              <div className="spinner-border text-primary me-3" role="status" />
              <h5 className="m-0">Loading ...</h5>
            </div>
          )}
        </>
      )}

      <div ref={ref} className="category__placeholder" />
    </div>
  );
};
