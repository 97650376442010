import { merge, get, isEmpty } from 'lodash';
import { storageFetch, storagePut, storageDrop } from '@mediafellows/chipmunk';
import queryString from 'query-string';
import { history } from 'utils/history';

export interface ILocation {
  pathname: string;
  search: string;
  [x: string]: any;
}

export const rememberLocation = (location: ILocation) => {
  if (
    location?.pathname === '/' // if on sign-in / home page
  )
    return;

  storagePut('restoreLocation', { pathname: location.pathname, search: location.search }, 60);
};

export const rememberedLocation = (): ILocation | null => {
  return storageFetch('restoreLocation');
};

export interface IRestoreOpts {
  withParams: boolean;
  params: { [s: string]: any };
}

const defaultRestoreOpts: IRestoreOpts = {
  withParams: true,
  params: {},
};

export const restoreLocation = (options?: Partial<IRestoreOpts>) => {
  const opts = merge({}, defaultRestoreOpts, options);
  const location = storageFetch('restoreLocation');
  storageDrop('restoreLocation');

  if (!location) {
    window.location.reload();
    return false;
  }

  const params = merge(queryString.parse(location.search), opts.params);

  const args = {
    pathname: location.pathname,
    search: opts.withParams && !isEmpty(params) && `?${queryString.stringify(params)}`,
  };

  history.push(args);
};

export const forgetLocation = () => {
  storageDrop('restoreLocation');
};

export const haveParamsChanged = (prev, next, ...names: string[]): boolean => {
  const last = merge({}, get(prev, 'match.params'), queryString.parse(get(prev, 'location.search')));
  const current = merge({}, get(next, 'match.params'), queryString.parse(get(next, 'location.search')));

  for (const name of names) {
    if (last[name] !== current[name]) return true;
  }

  return false;
};

export const queryStringParams = (location: ILocation = document.location) => {
  return queryString.parse(location.search);
};
