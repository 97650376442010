import React, { useCallback } from 'react';
import { AccountSidemenu, Loading, ProductList, TopSection } from 'components';
import { FetchActions, Modal } from 'types';
import './style.scss';
import useSWR from 'swr';
import { fetchContinueWatching } from 'utils/api/fetch-continue-watching';
import { modals } from '@mantine/modals';

export const ContinueWatching = () => {
  const { data: continueWatching, isLoading } = useSWR(FetchActions.CONTINUE_WATCHING, fetchContinueWatching);

  const handleContinueWatching = useCallback(
    (id) => {
      const product = continueWatching.find((item) => item.id === Number(id));
      modals.openContextModal({
        modal: 'previewAssets',
        id: Modal.PREVIEW_ASSETS,
        innerProps: {
          product,
        },
        withCloseButton: false,
      });
    },
    [continueWatching],
  );

  return (
    <>
      <TopSection title={'Continue Watching'} isSmall={true} />
      <div className="container-fluid--custom">
        <AccountSidemenu />
        <div className="row">
          {isLoading && (
            <div className="col-12">
              <Loading />
            </div>
          )}
          {!isLoading && (
            <div className="col-12 col-md-8">
              <ProductList
                showProgress={true}
                columnsClass={'col-6 col-md-4 col-xl-3'}
                products={continueWatching}
                total={continueWatching.length}
                containerClass={''}
                keyName={'continue-watching'}
                onClick={handleContinueWatching}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
