import { productListSchema } from '@mediafellows/tuco/dist/lib/schemas';
import { chipmunk, tuco } from 'utils';
import { IGetPurposeGroups } from '@mediafellows/tuco/dist/lib/tasks';

export const fetchFeaturedData = (type: 'movies' | 'tv') => {
  return chipmunk.run(
    async () => {
      const data = await tuco('getPurposeGroups', {
        purposeRegex: `^${type}_featured`,
        schema: 'id, name, purpose',
      } as IGetPurposeGroups);

      const { objects = [] } = data || {};
      const group = objects?.[0] || {};

      const productData = await tuco('getPurposeGroupProducts', {
        purpose: group?.purpose,
        schema: productListSchema,
      });

      const { objects: products = [] } = productData || {};
      return products;
    },
    (e: Error) => {
      console.log(`Failed to fetch featured ${type}`, e);
    },
  );
};
