import React, { useCallback, useEffect, useState } from 'react';
import { ProductList } from 'components';
import { chipmunk } from 'utils';
import { ButtonSpinner, Loading, TopSection } from 'components';
import { Model, Order, Sort } from 'types';
import { productSchema } from 'pages/product/schemas';

const ITEMS_PER_PAGE = 24;

interface Res {
  items: any[];
  current_page: number;
  total_pages: number;
  total_count: number;
}

const searchProducts = async (pageNumber, types): Promise<Res> => {
  try {
    const result = await chipmunk.action(Model.PRODUCTS, 'search', {
      body: {
        sorting: {
          [Sort.PRODUCTS_ORIGINAL_RELEASE_DATE]: Order.DESCENDING,
          [Sort.PRODUCTS_YEAR_OF_PRODUCTION]: Order.DESCENDING,
        },
        per: ITEMS_PER_PAGE,
        page: pageNumber,
        search: {
          filters: [
            ['default_layer.meta.product_type', 'in', types],
            ['parent_id', 'not_exist'],
          ],
        },
      },
      schema: productSchema,
    });

    return {
      items: result.objects,
      current_page: result.pagination.current_page,
      total_pages: result.pagination.total_pages,
      total_count: result.pagination.total_count,
    };
  } catch (e) {}
  return {
    items: [],
    current_page: 1,
    total_pages: 1,
    total_count: 0,
  };
};

type AllProductsProps = {
  types: any[];
  title: string;
};

export const AllProducts = ({ types, title }: AllProductsProps) => {
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [products, setProducts] = useState<any>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const root = title.includes('movies') ? 'Movies' : 'Television';

  const doLoadMore = async () => {
    setIsLoadingMore(true);
    const { items, current_page, total_count, total_pages } = await searchProducts(pageNumber + 1, types);
    setTotal(total_count);
    setPageNumber(current_page);
    setHasMore(current_page < total_pages);
    setProducts([...products, ...items]);
    setIsLoadingMore(false);
  };

  const doLoadProducts = useCallback(async () => {
    const { items, current_page, total_count, total_pages } = await searchProducts(1, types);
    setTotal(total_count);
    setHasMore(current_page < total_pages);
    setProducts(items);
  }, [types]);

  useEffect(() => {
    (async () => {
      await doLoadProducts();
    })();
  }, [doLoadProducts]);

  return (
    <>
      <div className="category">
        <TopSection title={title} isSmall={true} />
        {!products.length ? (
          <Loading />
        ) : (
          <>
            <ProductList products={products} keyName={'all-products'} total={total} showType={false} root={root} />

            {hasMore && (
              <div className="category-loadMoreButton">
                <button className="btn btn-lg btn-outline-primary" disabled={isLoadingMore} onClick={doLoadMore}>
                  <ButtonSpinner title="Load More" isLoading={isLoadingMore} />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
