import React from 'react';
import cx from 'classnames';

export type IconName =
  | 'linkedin'
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'menu'
  | 'x-lg'
  | 'x'
  | 'user'
  | 'chevron-right'
  | 'grid-3x3-gap-fill'
  | 'cloud-arrow-down-fill'
  | 'play-circle-fill'
  | 'search'
  | 'list'
  | 'play-fill'
  | 'chevron-down'
  | 'chevron-up'
  | 'arrow-left-circle'
  | 'plus'
  | 'youtube'
  | 'check'
  | 'apple'
  | 'android'
  | 'star-fill'
  | 'star'
  | 'person-fill'
  | 'pencil-square';

interface IIconProps {
  name: IconName;
  className?: string;
  width?: number;
  height?: number;
}

const Icon: React.FC<IIconProps> = ({ name, className, width, height }) => {
  return (
    <svg
      className={cx('bootstrap-icon align-top', className)}
      width={width || 24}
      height={height || 24}
      fill="currentColor"
    >
      <use href={`/static-assets/icons/bootstrap-icons.svg#${name}`} />
    </svg>
  );
};

export default Icon;
