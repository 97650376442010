import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { chipmunk, displayError } from 'utils';
import { Routes } from 'routes';
import { ButtonSpinner, FormBox } from 'components';
import { loadUser, login } from 'store/session-store/session-store';
import { loadBasics } from 'store/basic-store/basic-store';
import { notifications } from '@mantine/notifications';
import { NotificationsClassname } from 'types';

export const AccountConfirmation = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const password = useRef({});
  password.current = watch('password', '');

  const submitCallback = ({ password, password_confirmation }): void => {
    const currentUser = { ...user };
    currentUser['user_password'] = password;
    currentUser['user_password_confirmation'] = password_confirmation;

    chipmunk.run(
      async (chipmunk) => {
        setIsLoading(true);
        await chipmunk.action('um.confirmation', 'update', {
          body: { ...currentUser },
          params: { invitation_token: token },
        });

        await login(currentUser.user_email, currentUser.user_password);
        await loadBasics();
        await loadUser();

        notifications.show({
          message: 'Account has been confirmed!',
          className: NotificationsClassname.SUCCESS,
        });
        history.push(Routes.HOME);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        const msg = err?.object?.description || err.mesage;
        if (msg) {
          notifications.show({
            message: msg,
            color: 'red',
          });
        } else {
          notifications.show({
            message: 'Failed to activate account. Please try again.',
            color: 'red',
          });
        }
      },
    );
  };

  useEffect(() => {
    chipmunk.run(
      async (chipmunk) => {
        const result = await chipmunk.action('um.confirmation', 'get', {
          params: { invitation_token: token },
        });
        const user = result.object;
        user.user_terms_accepted = true;
        setUser(user);
      },
      () => {
        notifications.show({
          message: 'Failed to load user data. Account cannot be activated.',
          color: 'red',
        });
      },
    );
  }, [token]);

  return (
    <FormBox colClass="col-12 col-md-6 col-xl-5 col-xxl-4 mt-13">
      <h3>Setup your password</h3>
      <p className="mt-4">Password should be between 8-12 characters and contain at least one number and symbol.</p>
      <form className={'row g-3 mt-8'} onSubmit={handleSubmit(submitCallback)} noValidate={true}>
        <div className="col-12 mb-3">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
          <input
            id="email"
            type="text"
            placeholder="Ex. johnsmith@isp.com"
            readOnly={true}
            defaultValue={user?.user_email}
            className={cx('form-control form-control-lg')}
          />

          {displayError(errors?.name, 'Name')}
        </div>

        <div className="col-12 mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            id="password"
            type="password"
            placeholder="New password"
            {...register('password', {
              required: true,
              minLength: 8,
              pattern: {
                value: /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
                message:
                  'Password must be 8-12 characters long and contain at least one lowercase, one uppercase, one number and one special character.',
              },
            })}
            autoComplete={'password'}
            className={cx('form-control form-control-lg', { 'is-invalid': errors?.password })}
          />

          {displayError(errors?.password, 'Password')}
        </div>

        <div className="col-12 mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
          <input
            id="confirmPassword"
            type="password"
            placeholder="Confirm new password"
            {...register('password_confirmation', {
              required: true,
              minLength: 8,
              validate: (value) =>
                value === password.current || 'Passwords do not match. Please retype them and try again.',
            })}
            autoComplete={'password'}
            className={cx('form-control form-control-lg', { 'is-invalid': errors?.password_confirmation })}
          />
          {displayError(errors?.password_confirmation, 'Password confirmation')}
        </div>

        <div className="col-12 mb-3">
          <div className="d-flex align-items-center flex-column">
            <div>
              <button type="submit" disabled={isLoading || !user} className="btn btn-lg btn-outline-primary">
                <ButtonSpinner title="Activate account" isLoading={isLoading} />
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormBox>
  );
};
