import React, { useState, useEffect, useCallback } from 'react';
import { ButtonSpinner, FormBox } from 'components';
import { useForm } from 'react-hook-form';
import { chipmunk, displayError, rememberedLocation, restoreLocation, sendPagePing } from 'utils';
import cx from 'classnames';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { Routes } from 'routes';
import { getPurpose } from 'utils/get-purpose';
import './styles.scss';
import { useTimeout } from '@mantine/hooks';
import { useSessionStore } from 'store/session-store/session-store';
import { notifications } from '@mantine/notifications';
import { useHistory } from 'react-router-dom';

export const TwoFactorAuth = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestingCode, setIsRequestingCode] = useState(false);
  const [trusted, setTrusted] = useState(false);
  const history = useHistory();

  const purpose = getPurpose();
  const { start } = useTimeout(() => setIsRequestingCode(false), 5000);

  const requestCode = useCallback(async () => {
    if (!purpose) {
      notifications.show({
        message: 'Cannot request code - missing purpose',
        color: 'red',
      });
      return;
    }

    setIsRequestingCode(true);
    await chipmunk.run(async (chipmunk) => {
      await chipmunk.action('um.protection_check', 'create', {
        body: { purpose },
      });

      notifications.show({
        message: 'Access code requested (via email)',
        color: 'red',
      });
    });
    start();
  }, [purpose, start]);

  useEffect(() => {
    const autorequest = localStorage.getItem('2fa-lgt-autorequest');
    const date = new Date(Date.parse(autorequest));
    const differenceInMinutes = Math.abs(Math.round((date.getTime() - new Date().getTime()) / 1000 / 60));
    if (!localStorage.getItem('2fa-lgt-autorequest') || differenceInMinutes > 10) {
      requestCode();
      localStorage.setItem('2fa-lgt-autorequest', new Date().toString());
    }
  }, [requestCode]);

  const verifyCode = async ({ code }): Promise<void> => {
    const device_id = await getFingerprint();
    setIsLoading(true);

    await chipmunk.run(
      async (chipmunk) => {
        await chipmunk.action('um.protection_check', 'verify', {
          body: { auth_code: code, device_id, trusted },
          params: { purpose },
        });

        useSessionStore.setState({ is2FArequired: false });

        await sendPagePing('logged-in');

        const hasLocation = rememberedLocation();

        if (hasLocation && useSessionStore.getState().is2FARecoRedirect) {
          useSessionStore.setState({ is2FARecoRedirect: false });
          restoreLocation({ params: { '2fa': '1' } });
          return;
        }

        if (hasLocation) {
          restoreLocation();
          return;
        }

        history.push(Routes.HOME);
      },
      () => {
        notifications.show({
          message: 'This code is invalid.',
          color: 'red',
        });
      },
    );

    setIsLoading(false);
  };

  const handleChange = () => {
    setTrusted(!trusted);
  };

  return (
    <FormBox colClass="col-12 col-md-6 col-xl-5 col-xxl-4 mt-13 twofa-form">
      <p>For additional security, we have sent a verification code to your email address.</p>
      <p>Please enter the code below.</p>
      <form className="row g-3" noValidate={true} onSubmit={handleSubmit(verifyCode)}>
        <div className="col-12 mb-3">
          <label htmlFor="code" className="form-label">
            Verification Code
          </label>
          <input
            id="code"
            placeholder={'Enter code here'}
            type="text"
            {...register('code', {
              required: true,
              minLength: 6,
              maxLength: 6,
            })}
            autoComplete={'code'}
            className={cx('form-control', { 'is-invalid': errors?.code })}
          />
          {displayError(errors?.code, 'Code')}
        </div>
        <div className="col-12 mb-3">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="trust-device-checkbox"
              role="button"
              checked={trusted}
              onChange={handleChange}
            />
            <label className="form-check-label align-middle" htmlFor="trust-device-checkbox" role="button">
              Trust this device for 30 days
            </label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <button type="submit" disabled={isLoading} className="btn btn-lg btn-outline-primary">
                <ButtonSpinner title="Verify" isLoading={isLoading} />
              </button>
            </div>
            <div className="twofa-form__resend-code">
              <button disabled={isRequestingCode} onClick={requestCode} className="btn btn-link">
                <ButtonSpinner title="Resend Code" isLoading={isRequestingCode} />
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormBox>
  );
};
