import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Offcanvas as BSOffcanvas } from 'bootstrap';

export type OffcanvasStoreTypes = {
  activeName: string;
  offcanvas: BSOffcanvas;
  component: JSX.Element;
};

const initialState = {
  activeName: '',
  offcanvas: null,
  component: null,
};

export const useOffcanvasStore = create<OffcanvasStoreTypes>()(
  devtools(() => ({
    ...initialState,
  })),
);

export const toggleOffcanvas = (value: string, component: JSX.Element) => {
  useOffcanvasStore.setState((state) => {
    if (state.activeName === value) {
      state.offcanvas?.hide();
      return { activeName: '' };
    }
    state.offcanvas.show();
    return { activeName: value, component };
  });
};
