import { chipmunk } from 'utils/chipmunk';
import { cookie } from 'utils/cookie';

export const getSession = async (): Promise<any> => {
  const params = !cookie.get('sessionId') ? { guest: '1' } : {};

  const session = (
    await chipmunk.action('um.session', 'full', {
      params,
      raw: true,
    })
  ).object;

  return session;
};
