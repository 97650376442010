import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../index';
import config from '../../config';
import { Routes } from 'routes';
import cx from 'classnames';
import './style.scss';

interface FooterProps {
  className?: string;
}

export const Footer = ({ className }: FooterProps) => {
  const yearStart = 2022;
  const yearNow = new Date().getUTCFullYear();
  const yearText = yearNow > yearStart ? yearNow : yearStart;

  return (
    <footer className={cx('footer mt-auto pt-10 pb-10', className)}>
      <div className="footer__glow" />
      <div className="position-relative w-100 h-100 d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-10">
              <Link to={Routes.HOME}>
                <img className="footer__logo" loading="lazy" src="/static-assets/img/logo.svg" alt="Lionsgate Logo" />
              </Link>
              <div className="footer__links d-flex justify-content-center flex-nowrap mt-7">
                <Link to={Routes.ABOUT} className="custom-link mb-4 mb-md-0 me-md-12">
                  About
                </Link>
                <Link to={Routes.HELP} className="custom-link mb-4 mb-md-0 me-md-12">
                  Help
                </Link>
                <Link to={Routes.TERMS} className="custom-link mb-4 mb-md-0 me-md-12">
                  Terms & Conditions
                </Link>
                <Link to={Routes.PRIVACY} className="custom-link mb-4 mb-md-0 me-md-12">
                  Privacy policy
                </Link>
                <Link to={Routes.CONTACTS} className="custom-link">
                  Contact us
                </Link>
              </div>

              <div className="small text-muted mt-6">©{yearText} Lionsgate Entertainment, All Rights Reserved.</div>

              <div className="d-flex justify-content-center mt-9">
                <a className="custom-link" href={config.socialLinks.facebook} rel="noreferrer noopener" target="_blank">
                  <Icon name="facebook" />
                </a>
                <a
                  className="custom-link ms-4"
                  href={config.socialLinks.twitter}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <Icon name="twitter" />
                </a>
                <a
                  className="custom-link ms-4"
                  href={config.socialLinks.instagram}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <Icon name="instagram" />
                </a>
                <a
                  className="custom-link ms-4"
                  href={config.socialLinks.youtube}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <Icon name="youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
