import React, { useCallback, useEffect } from 'react';
import { chipmunk, getThumbText } from 'utils';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonSpinner, HorizontalThumbs, Loading, ProductList, TopSection } from 'components';
import {
  Actions,
  CATEGORIES,
  FetchActions,
  FILM_TYPES,
  MAIN_CATEGORY,
  Model,
  OFFCANVAS,
  Order,
  Sort,
  TV_TYPES,
} from 'types';
import { thumbText } from '../../components/thumbnail/thumbnail';
import { Routes } from 'routes';
import './styles.scss';
import { CategoriesStore, useCategoriesStore } from 'store/categories-store/categories-store';
import { useShallow } from 'zustand/react/shallow';
import { applyFilters } from 'store/apply-filters';
import useSWRInfinite from 'swr/infinite';
import useSWR from 'swr';
import { toggleOffcanvas } from 'store/offcanvas-store';
import { CategoryFilters } from './category-filters';
import { useDidUpdate } from '@mantine/hooks';
import { useBasicStore } from 'store/basic-store/basic-store';
import { productSchema } from 'pages/product/schemas';
import { fetchCollections } from 'utils/api/fetch-collections';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { fetchCollectionItems } from 'utils/api/fetch-collection-items';
import cx from 'classnames';

type CategoryProps = {
  root: string;
};

export const Category = ({ root }: CategoryProps) => {
  const history = useHistory();
  const { genre } = useParams<{ genre: string }>();
  const isRootCategory = !genre;
  const pathnameKey = isRootCategory ? history.location.pathname : '';

  const { data: showcaseData } = useSWR([FetchActions.GENRE_SHOWCASES, pathnameKey], () => fetchCollections('genres'));
  const { objects: showcases = [] } = showcaseData || {};

  const categories = useBasicStore((state) => state.categories);
  const startIsDone = useBasicStore((state) => state.isBasicDataLoaded);

  const isMoviesRoot = root === MAIN_CATEGORY.MOVIES;
  const rootTitle = isMoviesRoot ? 'Movies' : 'Television';
  const mapping = CATEGORIES[root].find((item) => item.frontend === genre);
  const rootLink = isMoviesRoot ? MAIN_CATEGORY.MOVIES : MAIN_CATEGORY.TV;
  const filteredShowcases = showcases.filter((list) => !list.purpose.includes('mobile'));
  const rootShowcases = filteredShowcases?.filter((showcase: UmGenericListShowcase) =>
    showcase?.purpose.toLowerCase().includes(root.toLowerCase()),
  );

  const filteredIds = rootShowcases.map((list) => list.id);

  const { data: showcaseItemsData, isLoading: isLoadingShowcaseData } = useSWR(
    filteredIds?.length ? [`all-genre-showcase-items`, pathnameKey] : null,
    () => fetchCollectionItems({ listIds: [filteredIds] }),
  );

  const { objects: genreShowcaseListItems = [] } = showcaseItemsData || {};

  const { filters, per, page } = useCategoriesStore(
    useShallow(
      ({ filters, per, activeFilters, page }): Partial<CategoriesStore> => ({
        filters,
        per,
        page,
        activeFilters,
      }),
    ),
  );

  const swr = useSWRInfinite(
    (pageIndex) =>
      startIsDone ? ['category-load-products', pageIndex + 1, per, filters, genre, root, pathnameKey] : null,
    (params) => {
      const [, size, per, filters, genre, root] = params;
      const updatedFilters = Object.values(filters)
        .filter((item) => item.payload)
        .map((item) => item.payload);

      if (genre) {
        const category = categories.find((item) => item.name.toLowerCase() === mapping.backend);
        const categoryId = [category?.id || -1];
        updatedFilters.push(['default_layer.meta.category_ids', 'in', categoryId]);
      }

      // permanent filter
      updatedFilters.push(
        ['default_layer.meta.product_type', 'in', root === 'tv' ? TV_TYPES : FILM_TYPES],
        ['parent_id', 'not_exist'],
      );

      return chipmunk.run(
        () =>
          chipmunk.action(Model.PRODUCTS, 'search', {
            body: {
              sorting: {
                [Sort.PRODUCTS_ORIGINAL_RELEASE_DATE]: Order.DESCENDING,
                [Sort.PRODUCTS_YEAR_OF_PRODUCTION]: Order.DESCENDING,
              },
              page: size,
              per,
              search: {
                filters: updatedFilters,
              },
            },
            schema: productSchema,
          }),
        (e: Error) => console.log(e),
      );
    },
    { keepPreviousData: true },
  );

  const { data, isLoading, size, setSize } = swr;

  const lastPageData = data?.length ? data[data.length - 1] : {};
  const { pagination } = lastPageData;
  const { total_count = 0, total_pages } = pagination ?? {};

  const products = data?.flatMap((page) => page.objects) ?? [];
  const initialLoading = !startIsDone || (typeof data === 'undefined' && isLoading);
  const isLoadingMore = !initialLoading && (isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'));
  const noResults = !initialLoading && genreShowcaseListItems?.length === 0;
  const shouldRenderLoadMoreButton = total_pages > 1;

  // reset page on route change
  useDidUpdate(() => {
    useCategoriesStore.setState({
      page: 1,
    });
  }, [genre]);

  // control size from the store
  useEffect(() => {
    if (page !== size) {
      setSize(page);
    }
  }, [page, setSize, size]);

  const breadcrumbs = [{ name: mapping?.frontend }];

  const handleThumbClick = useCallback(
    (_: number, product_id) => {
      history.push(`${Routes.PRODUCT}/${product_id}`);
    },
    [history],
  );

  const handleClearClick = async (): Promise<void> => {
    applyFilters({ action: Actions.RESET, store: useCategoriesStore });
  };

  const handleLoadMore = () => {
    useCategoriesStore.setState((state) => ({
      page: state.page + 1,
    }));
  };

  if (isRootCategory) {
    return (
      <div className="category">
        <TopSection
          title={`Browse ${rootTitle}`}
          isSmall={true}
          extraTitle={
            <Link className="view-all-link" to={`${rootLink}/all`}>
              View All
            </Link>
          }
        />

        {isLoadingShowcaseData && <Loading />}
        {Boolean(rootShowcases?.length) && (
          <div className="container-fluid--custom">
            {rootShowcases.map(({ id, purpose }, index) => {
              const products = genreShowcaseListItems
                .filter((item) => item.list_id === id)
                .map((item) => item.entity)
                .filter(Boolean);
              const thumbTexts: thumbText[] = products.map((item) => getThumbText(item, false));
              const formatParams = purpose?.split('-');
              const genreName = formatParams?.[formatParams.length - 1];
              const isLast = rootShowcases.length - 1 === index;

              if (!products.length) return null;

              return (
                <div className={cx('row', { 'mb-4': !isLast })} key={`${genreName}-${id}`}>
                  <div className="d-flex align-items-baseline">
                    <h3 className="text-capitalize mb-1">{genreName}</h3>
                    <Link className="view-all-link mx-3" to={`${rootLink}/${genreName}`}>
                      View All
                    </Link>
                  </div>
                  <div className="col-12">
                    <HorizontalThumbs scale="vga" onClick={handleThumbClick} items={products} texts={thumbTexts} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="category">
      <TopSection
        title={`Browse ${genre}`}
        breadcrumbs={breadcrumbs}
        onFiltersClick={() =>
          toggleOffcanvas(OFFCANVAS.GENRE_FILTERS, <CategoryFilters key={OFFCANVAS.GENRE_FILTERS} />)
        }
        onClearClick={handleClearClick}
        isSmall={true}
        store={'category'}
      />
      {isLoading && <Loading />}
      {noResults && (
        <section className="py-10 text-center">
          <h5>No titles found</h5>
        </section>
      )}
      {!noResults && (
        <>
          <ProductList products={products} total={total_count} keyName={rootTitle} />
          {shouldRenderLoadMoreButton && (
            <div className="category-loadMoreButton">
              <button className="btn btn-lg btn-outline-primary" disabled={isLoadingMore} onClick={handleLoadMore}>
                <ButtonSpinner title="Load More" isLoading={isLoadingMore} />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
