import React from 'react';
import Icon from '../icon/icon';
import { Thumbnail } from '../thumbnail/thumbnail';
import './styles.scss';
import { Routes } from 'routes';
import { Link } from 'react-router-dom';

export const SavedListItem: React.FC<{
  title: string;
  products: any;
  hasActions?: boolean;
  onEdit?: (basket: any) => void;
  onDelete?: () => void;
  id: string;
}> = ({ title, products, hasActions = true, onEdit, onDelete, id }) => {
  return (
    <div className="saved-listitem position-relative">
      <div className="row p-4 p-sm-9">
        <div className="col-8">
          <h2>{title}</h2>
        </div>
      </div>
      <div className="row px-4 px-sm-9 row-cols-3 g-0">
        {products?.map((product) => (
          <div className="col" key={product.id}>
            <Thumbnail image={product.inherited_preview_image} scale="cga" showOverlay={false} />
          </div>
        ))}
      </div>
      <div className="row p-4 p-sm-9 mt-5">
        <div className="col">
          <Link to={`${Routes.SAVED_LISTS}/${id}`} className="btn btn-outline-primary px-6 btn-lg text-nowrap">
            View List
          </Link>
        </div>
      </div>
      {hasActions && (
        <div className="saved-listitem__actions">
          <div className="saved-listitem__actionsbutton p-3" onClick={onEdit}>
            <Icon name="pencil-square" />
          </div>
          <div className="saved-listitem__actionsbutton p-3" onClick={onDelete}>
            <Icon name="x-lg" />
          </div>
        </div>
      )}
      <div className="saved-listitem__glow" />
    </div>
  );
};
