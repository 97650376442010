import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { General } from 'types';
import { Offcanvas as BSOffcanvas } from 'bootstrap';
import './offcanvas.scss';
import { useOffcanvasStore } from 'store/offcanvas-store';

const body = document.body;

const preventDefault = (event: Event) => {
  event.preventDefault();
};

export const Offcanvas = () => {
  const canvasRef = useRef(null);
  const history = useHistory();
  const component = useOffcanvasStore((state) => state.component);

  useEffect(() => {
    useOffcanvasStore.setState(() => ({
      offcanvas: new BSOffcanvas(canvasRef.current),
    }));
  }, []);

  useEffect(() => {
    if (!canvasRef.current) return;
    const current = canvasRef.current;

    const handleHidden = () => {
      body.classList.remove(General.OFFCANVAS_HIDNIG);
      useOffcanvasStore.setState(() => ({
        activeName: '',
      }));
    };

    const catchClickOutside = (event: MouseEvent) => {
      const target = event.target;
      if (target instanceof HTMLElement && !target.closest('.offcanvas-body--contentwrapper')) {
        document.removeEventListener('click', catchClickOutside);
        const closeButtons = document.querySelectorAll('.offcanvas-body--close');
        for (let c = 0; c < closeButtons.length; c++) {
          const closeButton = closeButtons[c];
          if (closeButton instanceof HTMLDivElement) {
            closeButton.click();
          }
        }
      }
    };

    const handleHide = () => {
      document.removeEventListener('click', catchClickOutside);
      window.removeEventListener('DOMMouseScroll', preventDefault);
      window.removeEventListener('wheel', preventDefault);
      window.removeEventListener('touchmove', preventDefault);
      body.classList.remove(General.OFFCANVAS_ACTIVE);
      body.classList.add(General.OFFCANVAS_HIDNIG);
    };

    const handleShow = () => {
      body.classList.add(General.OFFCANVAS_ACTIVE);
      body.classList.add(General.OFFCANVAS_SHOWING);
    };

    const handleShown = () => {
      document.addEventListener('click', catchClickOutside);
      window.addEventListener('DOMMouseScroll', preventDefault, { passive: false });
      window.addEventListener('wheel', preventDefault, { passive: false });
      window.addEventListener('touchmove', preventDefault, { passive: false });
      body.classList.remove(General.OFFCANVAS_SHOWING);
    };

    current.addEventListener('hidden.bs.offcanvas', handleHidden);
    current.addEventListener('hide.bs.offcanvas', handleHide);
    current.addEventListener('show.bs.offcanvas', handleShow);
    current.addEventListener('shown.bs.offcanvas', handleShown);

    return () => {
      if (!current) return;
      current.removeEventListener('hidden.bs.offcanvas', handleHidden);
      current.removeEventListener('hide.bs.offcanvas', handleHide);
      current.removeEventListener('show.bs.offcanvas', handleShow);
      current.removeEventListener('shown.bs.offcanvas', handleShown);
    };
  }, [history]);

  return (
    <div
      className="offcanvas offcanvas-bottom offcanvas--nav"
      data-bs-scroll="true"
      data-bs-backdrop="false"
      ref={canvasRef}
      tabIndex={-1}
      id="offcanvas"
      aria-labelledby="offcanvas"
    >
      <div className="offcanvas-body px-0 pt-4">
        <div className="container-fluid--custom offcanvas-body--contentwrapper">
          {!!component && component}
          <div className="offcanvas-body--close" data-bs-dismiss="offcanvas"></div>
        </div>
      </div>
    </div>
  );
};

Offcanvas.displayName = 'Offcanvas';
