import React from 'react';

const Loading = () => {
  return (
    <section className="py-10 text-center">
      <div className="d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary me-3" role="status" />
        <h5 className="m-0">Loading data ...</h5>
      </div>
    </section>
  );
};

export default Loading;
