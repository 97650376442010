import { notifications } from '@mantine/notifications';
import { productSchema } from 'pages/product/schemas';
import { Model, NotificationsClassname, Product } from 'types';
import { chipmunk } from 'utils/chipmunk';

export const fetchRecommendationProducts = (productIds: number[]): Promise<Product[]> => {
  return chipmunk.run(
    async (chipmunk) => {
      const res = await chipmunk.action(Model.PRODUCTS, 'search', {
        body: {
          per: productIds.length,
          sort: 'sequence_number',
          include_deleted: false,
          search: {
            filters: [['id', 'in', productIds]],
          },
        },
        schema: productSchema,
      });

      return res.objects;
    },
    () => {
      notifications.show({
        message: 'Failed to get a recommendation products',
        className: NotificationsClassname.ERROR,
      });
    },
  );
};
