import React from 'react';

export const Privacy: React.FC<{}> = () => {
  return (
    <div className="container mt-15 mb-5">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <h1 className="mb-10">Privacy Policy</h1>
          <p>Last Revised May 11, 2011</p>
          <p>
            PLEASE READ THIS PRIVACY POLICY AND THE RELATED TERMS OF SERVICE LOCATED AT{' '}
            <a href="https://www.lionsgate.com/legal/terms-of-use" target="_blank" rel="noopener noreferrer">
              https://www.lionsgate.com/legal/terms-of-use
            </a>{' '}
            CAREFULLY BEFORE USING THIS WEBSITE.
          </p>

          <p>
            This website is operated by Lionsgate Entertainment Corp. on its own behalf or in combination with one of
            its film or television divisions or joint ventures (collectively referred to herein, solely for ease of
            reference, as “Company”, “us” or “we”). This privacy policy (“Privacy Policy”) applies to all of the online
            and mobile websites and interactive applications operated by Company (unless a different policy is provided
            on a particular site or application, in which case such different policy shall control). Protection of
            personal and sensitive information is paramount. This Privacy Policy discloses what type of information is
            collected, the methods used to collect it, how Company utilizes the information to better serve all
            interested parties and the choices you have about the way your information is collected and used. Please
            read this Privacy Policy carefully. Your use of any Company website indicates that you have read, accepted
            and unconditionally agreed to the Company privacy practices, as outlined in this Privacy Policy and any
            other applicable terms. The Terms of Service for this website are incorporated herein by this reference.
          </p>

          <h3 className="my-5">Children</h3>
          <p>
            We recognize the importance of safeguarding the privacy of children and encourage parents to check and
            monitor their children’s use of online activities regularly. This website supports and complies with the
            Children’s Online Privacy Protection Act (“COPPA”) and other applicable laws. This website is not aimed at
            nor intended for children under the age of 13. If you are under the age of 13, please do not use this
            website, and if you are over the age of 13 but under the age of 18, you should use this website only with
            the involvement and permission of a parent or legal guardian. Our website does not accept registration from
            children under the age of 13. No personally identifiable information is purposefully or knowingly collected
            from children under the age of 13 by Company without parental consent. For purposes of this Privacy Policy,
            personally identifiable information (“PII”) is personal information about an individual, such as a first and
            last name, phone number or email address or other individually identifiable information within the meaning
            of COPPA and the Federal Trade Commission’s Online Privacy Protection Rule found at 16 CFR 312.2. If Company
            discovers that a person under the age of 13 has provided Company with any PII, Company will use commercially
            reasonable efforts to delete such person’s PII from its system. Every user hereby agrees that all
            information you provide, including but not limited to age, shall be truthful and correct.
          </p>

          <h3 className="my-5">Information We Collect</h3>
          <p>
            Company strives to provide our visitors with information relevant to their particular desires, needs and/or
            interests. The information we gather helps us to better serve our purpose. We may ask you to provide us with
            demographic information, information regarding your interests or other PII. Providing us with PII about
            yourself is voluntary, and you can always choose not to provide certain information, but then you may not be
            able to take advantage of or participate in some of the website’s features. Company actively and passively
            gathers, analyzes and/or stores PII and other information generally by way of four different sources: (1)
            the PII voluntarily submitted by visitors when using or registering to use or receive our services or
            offers; (2) the PII visitors voluntarily provide when participating in any contests, sweepstakes and/or
            other promotions; (3) the PII visitors voluntarily provide when buying or ordering merchandise through the
            website; and (4) by tracking and analyzing online and mobile data and activity through mechanisms that
            identify site usage, browser types, IP addresses, and other data. This last source of information is
            explained in greater detail over the next several sections of this Privacy Policy.
          </p>

          <h3 className="my-5">Non-Personally Identifiable Information</h3>
          <p>
            When your computer or mobile device contacts our web servers (for example, when you visit this website, scan
            a Microsoft Tag or QR code, or view an HTML e-mail), our web servers automatically collect usage
            information. Such usage information includes information about how our visitors use and navigate our
            website. It can include the number and frequency of visitors to each web page and the length of their stays,
            browser type, referrer data that identifies the web page visited prior and subsequent to visiting our
            website, and IP addresses (see below for more information on IP addresses). We also may determine the
            applicable technology available in order to serve you the most appropriate version of a web page, e-mail,
            advertising or promotional announcement or similar service. This information is used to analyze and improve
            this website and to provide you with a more fulfilling and relevant experience.
          </p>

          <h3 className="my-5">Browser Cookies</h3>
          <p>
            Like many websites, we use browser “cookies”. Cookies are a website’s way of remembering who you are. A
            cookie is a small text file that is stored on your computer’s hard drive or stored temporarily in your
            computer’s memory. There are two kinds of cookies: those that are “session” oriented and those that are
            “persistent”. Session cookies delete from your computer when you close your browser. Persistent cookies
            retain information for later use tomorrow, next week, next month, or whenever they are set to expire. We use
            cookies to help us to identify account holders and to optimize their experience on our website. Cookies also
            allow us to hold selections in a shopping cart when a user leaves this website without checking out. Also,
            we will use cookies to monitor and maintain information about your use of this website. Most web browsers
            accept cookies automatically. You can delete cookies manually or set your browser to automatically delete
            cookies on a pre-determined schedule. For example, in the Internet Explorer menu bar, select: Tools
            â†’Internet Optionsâ†’Browsing Historyâ†’Delete to view manual and automatic options. If you decline to
            accept cookies, you may not be able to take advantage of or participate in certain features of this website.
          </p>

          <h3 className="my-5">Flash Cookies</h3>
          <p>
            Our website enables the use of the Adobe Flash Player. Adobe’s Flash Player is used by the vast majority of
            websites that offer video and other interactive content. By default, your use of the Adobe Flash Player
            generates “flash cookies” (also known as “persistent identification elements” or “local shared objects”).
            Adobe provides a short disclosure about Flash Cookies in its End User License Agreement, stating “Use of the
            web players, specifically the Flash Player, will enable the Software to store certain user settings as a
            local shared object on our computer. These settings are not associated with you, but allow you to configure
            certain settings within the Flash Player.” The Adobe Flash Player (and similar applications) use flash
            cookies to remember user settings, preferences and usage similar to the browser cookies referenced above,
            but flash cookies can store more information than browser cookies and are managed through a different
            interface than the one provided by your web browser. You can control the degree to which you accept flash
            cookies by accessing your Adobe Flash Player management tools directly through the settings manager for
            Adobe Flash, located at{' '}
            <a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html"
              target="blank"
            >
              http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html
            </a>
            . If you do not allow flash cookies to take any disc space on your computer, you may not be able to take
            advantage of or participate in certain features on the website. Users with a computer running the Windows
            operating system can view flash cookie files in this folder: \Documents and Settings\[username]\Application
            Data\Macromedia\Flash Player. Users with a computer running the Apple operating system can view flash cookie
            files in this folder: /users/[username]/Library/Preferences/macromedia/Flash Player. Flash cookies, or LSO
            files are stored typically with an “.SOL” extension.
          </p>
          <p>
            Although the Adobe Flash Player is used by the vast majority of websites that offer video content and/or
            games, it is not the only technology being used in the ever-shifting online and mobile content environment.
            HTML5 is an increasingly popular web standard used for presenting content, especially content delivered to
            mobile devices. HTML is the mark-up language used for the World Wide Web. Almost all web pages you visit on
            the internet are based around HTML code. HTML5 is simply the fifth and latest iteration of this mark-up
            language that allows for more interactive web pages. One of the real benefits of HTML5 is its potential to
            standardize the highly fragmented rich-media universe. Some HTML5 code may allow your response to
            advertising and other activities to be monitored across websites and such information to be stored on your
            computer or mobile device. Technology solutions that allow users to opt-out of or block this sort of
            tracking are being developed through browser add-ons and other tools.
          </p>

          <h3 className="my-5">Web Beacons</h3>
          <p>
            Our web pages or e-mail messages may contain a small graphic image called a web beacon, which is sometimes
            also called a "clear gif", that allows us to monitor and collect certain limited information about our
            users, such as the type of browser requesting the web beacon, the IP address of the computer that the web
            beacon is sent to and the time the web beacon was viewed. Web beacons can be very small or invisible to the
            user, but, in general, any electronic image viewed as part of a web page or e-mail, including HTML based
            content, can act as a web beacon. We may use web beacons to count visitors to our web pages or to monitor
            how our users navigate our website, and we may include web beacons in e-mail messages in order to count how
            many of the messages we sent were actually opened or acted upon. We use web beacons to compile aggregate
            statistics about our website and our marketing campaigns.
          </p>

          <h3 className="my-5">Use Of IP Addresses</h3>
          <p>
            An IP address is a number that is assigned to your computer or network when you are linked to the Internet.
            When you request pages from this website, our servers log your IP address. We may use IP address for a
            number of purposes, such as system administration, to report aggregate information to our business partners
            or to audit the use of the website. We may associate your IP address with the PII you provide. We also may
            collect and store information about you that we receive from other sources to enable us to update and
            correct the information contained in our database and to provide product recommendations and special offers
            that we think will interest you.
          </p>

          <h3 className="my-5">Sensitive Information</h3>
          <p>
            There are two ways sensitive information (e.g. credit card numbers) may be obtained. When a visitor makes a
            purchase, his/her information may be sent directly to a third party authorization center that will relay to
            Company whether to proceed with the transaction. The information is not at all stored in our system. The
            second way is when a visitor pre-orders merchandise. In this case, the information is sent to a non-public
            server and stored until the transaction is complete.
          </p>
          <p>
            Company uses all possible efforts to ensure the safety of your sensitive information. No website, internet
            transmission, computer system or wireless connection is completely secure. Company cannot guarantee that
            unauthorized access, hacking, data loss, or other breaches will never occur. Your use of the Company website
            is at your own risk. Company urges you to take steps to keep your information safe by memorizing your
            password or keeping it in a safe place (separate from your account information), logging out of your
            account, and closing your web browser. In order to help us protect your sensitive information and PII
            further, you should be careful about providing your password to others.
          </p>
          <p>
            Whenever you give Company sensitive or confidential information, Company will take commercially reasonable
            steps to protect the information by establishing a secure connection with your web browser. Company uses the
            Security Socket Layer (“SSL”), an industry standard security protocol for encrypting sensitive information.
            Unfortunately, no security measures are perfect or impenetrable and data transmission over the Internet
            cannot be guaranteed 100% secure. We cannot and do not ensure or warrant the security of any information you
            transmit to Company and you do so at your own risk. Unless otherwise specified herein or on the site where
            you make a purchase, credit card numbers are used only for payment processing and are not retained for
            marketing purposes.
          </p>

          <h3 className="my-5">External Links</h3>
          <p>
            Company may contain links to, or advertisements about, non-Company websites. Other sites may also reference,
            advertise, or link to Company websites. Company does not endorse or sponsor other websites, is not
            responsible for the privacy practices or the content of non-Company sites, expressly disclaims any
            statements or assertions made on such websites, and denies all liability associated with your use of, and
            the content on, such other websites and advertisements. Please be advised that the practices described in
            this Privacy Policy do not apply to information gathered through other websites. We encourage you to be
            aware of when you leave this website and to read the privacy policies of each and every website that you
            visit.
          </p>

          <h3 className="my-5">Consent to Processing in the United States</h3>
          <p>
            This website is operated in the United States. By providing any information to Company, all users,
            including, without limitation, users in the member states of the European Union, Canada or elsewhere outside
            of the United States, fully understand and unambiguously consent to this Privacy Policy. By using our
            website, participating in any of our services and/or providing us with your information, you consent and
            agree to the collection, transfer, storage and processing of your information to and in the United States.
          </p>

          <h3 className="my-5">Sharing Information</h3>
          <p>
            We may provide information to select outside companies when we believe their products or services may be of
            interest to you. If you opt in to receive communications from a third party, your information will be
            subject to the third party's privacy policy and not this Privacy Policy. Therefore, if you later decide that
            you do not want that third party to use your information, you will need to contact the third party directly.
            In addition, Company may share your PII with third parties under the following circumstances:
          </p>
          <p>
            Third parties providing services on our behalf: We may engage third parties to perform services in
            connection with the operation of our business. Examples of these services include: (a) payment processing
            and authorization, (b) fraud protection and credit risk reduction, (c) product customization, (d) order
            fulfillment, (e) shipping, (f) marketing and promotional material distribution, (g) website evaluation, (h)
            data collection, storage, management, analysis and, where applicable, cleansing, and (i) any other services
            designed to assist us in maximizing our business potential. These third parties may have access to this
            website’s user information, including PII, to the extent it is needed to perform their duties and functions.
          </p>
          <dl>
            <dt>Company Security:</dt>
            <dd>
              We may release information about our users, including PII, when legally required to do so, at the request
              of governmental authorities conducting an investigation, or to verify or enforce compliance with the
              policies governing our website and applicable laws. We may also disclose such user information whenever we
              believe disclosure is necessary to protect the rights, property or safety of Company, or any of our
              respective affiliates, business partners, customers or others.
            </dd>
            <dt>Aggregate Information:</dt>
            <dd>
              We may disclose non-identifying, aggregated user statistics to third parties for a variety of purposes,
              including describing our services to prospective partners and other third parties. Examples of such
              non-personal information include the number of users who visited this website during a specific time
              period or purchased a specific product through this website.
            </dd>
            <dt>Promotions:</dt>
            <dd>
              If you choose to enter a contest, sweepstakes or promotion, your PII may be disclosed to third parties in
              connection with such promotion, including without limitation for purposes of posting your entry with
              attribution or otherwise as permitted in the official rules for the promotion in question, fulfilling your
              prize or including your name on a winners list. Also, by entering a promotion, we may require you to
              consent to the use of your PII or other information, such as name, voice or likeness, in advertising,
              promotional and marketing materials. In addition, we may offer certain promotional content, such as a
              sweepstakes, sponsored by or co-branded with a third party, who may obtain the PII that you submit.
            </dd>
            <dt>Corporate change:</dt>
            <dd>
              In addition, we reserve the right to disclose, share and/or otherwise transfer user information, including
              without limitation PII, in connection with a corporate merger, acquisition, consolidation, the sale of a
              portion of our business or the sale of substantially all of Company’s assets, or other fundamental
              corporate change, whatever form it may take. You will be notified via e-mail or prominent notice on our
              website prior to a change of ownership or control of your personal information, if your personal
              information will be used contrary to this policy. For clarification and the removal of doubt, Company
              specifically reserves the right to transfer or share a copy of PII collected pursuant to this Privacy
              Policy from this website, off-line or otherwise, to the buyer of that portion of its business relating to
              that information.
            </dd>
          </dl>

          <h3 className="my-5">Third Party Advertisements</h3>
          <p>
            We may from time to time use various outside ad-serving or other companies to serve advertisements on our
            website. In the process of serving advertisements, such companies may use cookies, and may use information
            about your visits to our website and other websites in order to provide advertisements on our website about
            products and services that may be of interest to you. By delivering ads on our website, and the use of
            cookies, the ad-server is able to record what ads a user has seen, and what pages the user was viewing when
            the ads were delivered. This web usage information may be combined with other ad delivery information the
            ad-server has collected from your visits to our website and other websites not affiliated with Company. This
            non-personally identifiable information is used as part of the ad-serving process for reporting, and to
            select and direct the ads you see on our website and on other websites not affiliated with us.
          </p>

          <h3 className="my-5">How Can I Access, Correct and Update Personal Information?</h3>
          <p>
            You can access, correct and update certain personal information that you have provided to us by emailing us
            at <a href="mailto:general-inquiries@lionsgate.com">general-inquiries@lionsgate.com</a>. Please be aware
            that we will process your request as quickly as we reasonably can, but it may not be possible to update our
            systems immediately. In addition, you should be aware that it is not always possible to completely remove or
            delete all of your information from our databases without some residual data because of backups and other
            reasons.
          </p>

          <h3 className="my-5">Your choices/unsubscribe</h3>
          <p>
            You will have an opportunity to unsubscribe from receiving promotional material from this website by
            clicking on an "unsubscribe" hyperlink contained in promotional emails we send you. Additionally you may
            email us at <a href="mailto:general-inquiries@lionsgate.com">general-inquiries@lionsgate.com</a>. Because
            customer lists often are prepared well in advance of an offering (sometimes a few months before the offer is
            made), you may continue to receive some offers after you send us a request not to use your information for
            specified marketing purposes. Please be aware that we will process your request as quickly as we reasonably
            can, but it may not be possible to update our systems immediately. We appreciate your patience and
            understanding in giving us time to carry out your request.
          </p>
          <p>
            Even if you are removed from any such list, if you order online, we will send you an email confirming your
            order and may need to contact you by phone, email, or regular mail if we have questions about your order. In
            addition, you should be aware that it is not always possible to completely remove or delete all of your
            information from our databases without some residual data because of backups and other reasons.
          </p>
          <p>
            If you want to opt out of having your PII shared with third parties for marketing purposes, you must
            consistently opt-out at each of our websites where you register and whenever you provide PII to us. Please
            bear in mind that this opt-out choice for "third parties" will not apply to Company, Company licensees
            and/or co-branding participants, as set forth below. Please also keep in mind that any opt-out choices you
            make will not apply in situations where (a) you either have made, simultaneously make, or later make a
            specific request for information from us, or (b) Company uses your PII for either operational purposes
            (e.g., to complete transactions requested by you, or to send you administrative communications either about
            your account with us or about features of our websites, including any future changes to this Privacy Policy,
            or any other policy or terms that affect you and your use of our website) or fulfillment purposes (e.g.,
            sharing with companies that facilitate communications and transactions with you).
          </p>
          <p>
            Finally, if you do not exercise your opt-out choices upon registration or otherwise at the time the PII is
            provided, it may take some time to process your opt-out choices. Our systems require time to update, and
            promotional mailings using PII shared before your opt-out may already be in process. Thus, your PII might
            continue to be shared with third parties for promotional purposes for some time after you make your request,
            For similar reasons, if your PII is shared with a third party, Company largely or completely loses control
            over how that information is used. Thus, even after you have opted out of allowing us to share your PII, you
            might continue to receive promotional materials from third parties unless and until you duly register your
            opt-out request with each third party in question.
          </p>

          <h3 className="my-5">Notice to California Residents/Your California Privacy Rights</h3>
          <p>
            California residents are entitled once a year to request and obtain certain information regarding our
            disclosure, if any, of personal information to third parties for their direct marketing purposes during the
            immediately prior calendar year (e.g. requests made in 2011 will receive information about 2010 sharing
            activities). As set forth in this Privacy Policy, we comply with this law by offering our visitors the
            ability to tell us not to share your personally identifiable information with third parties for their direct
            marketing purposes. To make such a request, consistently exercise your opt-out choice whenever you provide
            PII to us or send an e-mail to{' '}
            <a href="mailto:general-inquiries@lionsgate.com">general-inquiries@lionsgate.com</a>. You must include this
            website as the subject line, and your full name, e-mail address, and postal address in your message. Please
            see the "Your Choices/Unsubscribe” section of this Privacy Policy for additional information.
          </p>

          <h3 className="my-5">Forums/Message Boards</h3>
          <p>
            Please review our restrictions on use and guidelines for user forums in our Terms of Use. In addition,
            please note that this Privacy Policy does not apply to any information you may disclose publicly in user
            forums (such as chat rooms, message boards, or similar web pages). You should keep in mind that whenever you
            publicly disclose information about yourself online, that information could be collected and used by people
            that you do not know. In addition, certain message boards (and similar user forums) may display IP addresses
            and possibly PII along with the message poster's name and message. Company assumes no responsibility for any
            action or policies of any third parties who collect any information users may disclose in user forums or
            other public areas, if any, of this website.
          </p>

          <h3 className="my-5">Film Ratings</h3>
          <p>
            If a motion picture that has been rated by the Motion Picture Association of America (“MPAA”) is the subject
            of this website, you may visit{' '}
            <a href="http://www.mpaa.org" target="blank">
              www.mpaa.org
            </a>{' '}
            for a description of the ratings system,{' '}
            <a href="http://www.filmratings.com" target="blank">
              www.filmratings.com
            </a>{' '}
            for a discussion on the reasons for the ratings on recent motion picture releases or visit{' '}
            <a href="http://www.parentalguide.org" target="blank">
              www.parentalguide.org
            </a>
            , which is intended to provide parents with information about each of the ratings systems that have been
            developed in the electronic game, music, cable and television broadcast industries.
          </p>

          <h3 className="my-5">Revisions to Privacy Policy</h3>
          <p>
            We reserve the right, in our sole discretion, to modify, update, add to, discontinue, remove or otherwise
            change any portion of this Privacy Policy, in whole or in part, at any time. When we amend this Privacy
            Policy, we will revise the “last updated” date located at the top of this Privacy Policy. For changes to
            this Privacy Policy that we consider to be material, we will place a notice on the website by revising the
            link to read substantially as “Updated Privacy Policy” for a reasonable amount of time. If you provide
            information to us or access or use the website in any way after this Privacy Policy has been changed, you
            will be deemed to have unconditionally consented and agreed to such changes. The most current version of
            this Privacy Policy will be available on the website and will supersede all previous versions of this
            Privacy Policy.
          </p>

          <h3 className="my-5">Choice of Law</h3>
          <p>
            This Privacy Policy, including all revisions and amendments thereto, is governed by the internal laws of the
            State of California, without regard to its conflict or choice of law principles which would require
            application of the laws of another jurisdiction.
          </p>

          <h3 className="my-5">Arbitration</h3>
          <p>
            By using this website in any way, you unconditionally consent and agree that: (1) any claim, dispute, or
            controversy (whether in contract, tort, or otherwise) you may have against the officers, directors and
            employees of Company and its parent, subsidiaries, affiliates (all such individuals and entities
            collectively referred to herein as the “Company Entities”) arising out of, relating to, or connected in any
            way with the website or the determination of the scope or applicability of this agreement to arbitrate, will
            be resolved exclusively by final and binding arbitration administered by JAMS and conducted before a sole
            arbitrator in accordance with the rules of JAMS; (2) this arbitration agreement is made pursuant to a
            transaction involving interstate commerce, and shall be governed by the Federal Arbitration Act (“FAA”), 9
            U.S.C. §§ 1-16; (3) the arbitration shall be held in Los Angeles, California; (4) the arbitrator’s decision
            shall be controlled by the terms and conditions of this Privacy Policy and any of the other agreements
            referenced herein that the applicable user may have entered into in connection with the website; (5) the
            arbitrator shall apply California law consistent with the FAA and applicable statutes of limitations, and
            shall honor claims of privilege recognized at law; (6) there shall be no authority for any claims to be
            arbitrated on a class or representative basis, arbitration can decide only your and/or the applicable
            Company Entity’s individual claims; the arbitrator may not consolidate or join the claims of other persons
            or parties who may be similarly situated; (7) the arbitrator shall not have the power to award punitive
            damages against you or any Company Entity; (8) in the event that the administrative fees and deposits that
            must be paid to initiate arbitration against any Company Entity exceed $125 USD, and your are unable (or not
            required under the rules of JAMS) to pay any fees and deposits that exceed this amount, Company agrees to
            pay them and/or forward them on your behalf, subject to ultimate allocation by the arbitrator. In addition,
            if you are able to demonstrate that the costs of arbitration will be prohibitive as compared to the costs of
            litigation, Company will pay as much of your filing and hearing fees in connection with the arbitration as
            the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive; and (9) with the
            exception of subpart (6) above, if any part of this arbitration provision is deemed to be invalid,
            unenforceable or illegal, or otherwise conflicts with the rules of JAMS, then the balance of this
            arbitration provision shall remain in effect and shall be construed in accordance with its terms as if the
            invalid, unenforceable, illegal or conflicting provision were not contained herein. If, however, subpart (6)
            is found to be invalid, unenforceable or illegal, then the entirety of this Arbitration Provision shall be
            null and void, and neither you nor Company shall be entitled to arbitrate their dispute. For more
            information on JAMS and/or the rules of JAMS, visit their Website at{' '}
            <a href="http://www.jamsadr.com" target="blank">
              www.jamsadr.com
            </a>
            .
          </p>
          <p>
            For any questions, suggestions, or concerns related to this Privacy Policy, please email{' '}
            <a href="mailto:general-inquiries@lionsgate.com">general-inquiries@lionsgate.com</a>. Alternatively, you may
            contact Company via postal mail at Privacy Policy Questions, c/o Lionsgate, Investor Relations, 2700
            Colorado Ave., Suite 200, Santa Monica, CA 90404.
          </p>
        </div>
      </div>
    </div>
  );
};
