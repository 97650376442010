import { ConferenceStore } from '@mediafellows/react-video-conference';
import { chipmunk } from 'utils';

export class RootStore {
  public conferenceStore: ConferenceStore;

  constructor() {
    this.conferenceStore = new ConferenceStore(chipmunk);
  }
}
