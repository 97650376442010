import { useEffect } from 'react';
import { pigeon } from 'utils/pigeon';
import { loadSession, loadUser, useSessionStore } from 'store/session-store/session-store';
import { addPurpose } from 'utils/add-purpose';
import { loadBasics, loadSalesContacts } from 'store/basic-store/basic-store';

export const useLoadGeneralData = (): void => {
  const session = useSessionStore((state) => state.session);
  const initialLoadDone = useSessionStore((state) => state.initialLoadDone);
  const is2FArequired = useSessionStore((state) => state.is2FArequired);

  useEffect(() => {
    (async () => {
      await loadSession();
    })();
  }, []);

  useEffect(() => {
    if (!initialLoadDone) {
      return;
    }

    loadBasics();

    const purpose = session?.user?.mfa_auth_method;
    if (purpose) {
      addPurpose(purpose);
    }

    if (session && !is2FArequired) {
      pigeon(session?.user?.id);
    }

    (async () => {
      if (!session || session.isPublic) return;
      const userId = session?.user?.id;

      if (userId) {
        await loadUser();
        await loadSalesContacts();
      }
    })();
  }, [session, initialLoadDone, is2FArequired]);
};
