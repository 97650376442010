import { notifications } from '@mantine/notifications';
import { useSessionStore } from 'store/session-store/session-store';
import { addPurpose } from 'utils/add-purpose';

const getPurpose = (obj) => {
  const purpose = obj?.payload?.purpose;

  if (purpose) {
    return purpose;
  }

  try {
    return getPurpose(JSON.parse(obj.error.response.text));
  } catch (e) {
    console.log('Failed to get purpose', e);
  }
};

export const errorHandler2FA = (e) => {
  const is2FArequired = useSessionStore.getState().is2FArequired;

  const purpose = getPurpose(e.object);

  if (purpose) {
    addPurpose(purpose);
  }

  if (is2FArequired) {
    return;
  }
  notifications.show({
    message: 'Two Factor Authentication is Required',
    color: 'red',
  });
  useSessionStore.setState({ is2FArequired: true });
};
