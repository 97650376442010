import createChipmunk, { IConfig, IChipmunk } from '@mediafellows/chipmunk';
import { cleanConfig } from '@mediafellows/chipmunk';

import { cookie } from './cookie';
import { errorHandler2FA } from 'utils/error-handler-2fa';
import { notifications } from '@mantine/notifications';
import { logout } from 'store/session-store/session-store';

declare const ENDPOINTS;
declare const AFFILIATION_ID;

const asyncLogout = async () => await logout();

const sessionId = cookie.get('sessionId');
const config: Partial<IConfig> = {
  endpoints: ENDPOINTS,
  headers: {
    'Affiliation-Id': AFFILIATION_ID,
    'Session-Id': sessionId,
  },
  cache: {
    enabled: true,
    default: 'storage',
  },

  errorInterceptor: (err) => {
    if (err.status === 419) {
      notifications.show({
        message: 'Your session has expired. Please sign in again.',
        color: 'red',
      });

      asyncLogout();

      return true;
    }
    if (err.status === 418 || err.status === 503) {
      //window.location.href = '/maintenance';
      notifications.show({
        message: 'System Is Under Maintenance',
        color: 'red',
      });

      return true;
    }

    if (err.status === 420) {
      errorHandler2FA(err);
      return true;
    }

    return false;
  },
};

export const tuco = (task, opts?) => {
  return chipmunk
    .action(`tuco.request`, `task`, {
      raw: true,
      body: {
        task,
        opts,
        config: cleanConfig(chipmunk.currentConfig()),
      },
    })
    .catch((e) => {
      if (e.status === 420) {
        errorHandler2FA(e);
      }
    });
};

// Uncomment this if you want to test chimpunk
// const testChipmunk = createChipmunk(config);
// window.chipmunk = testChipmunk;

export const chipmunk: IChipmunk = createChipmunk(config);

window['chipmunk'] = chipmunk;
