import React from 'react';
import { Routes } from 'routes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Product } from 'types';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';

export type HeroCarouselProps = {
  assets: any[];
};

export const HeroCarousel = ({ assets }: HeroCarouselProps) => {
  return (
    <div className="row h-100 my-3">
      <div className="col-12 h-100">
        <Swiper
          className="home-swiper h-100"
          modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          effect={'coverflow'}
          centeredSlides={true}
          breakpoints={{
            768: {
              coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 300,
                modifier: 1,
                slideShadows: true,
              },
              slidesPerView: 2,
              initialSlide: 1,
            },
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
        >
          {assets?.map((asset, index) => {
            const product = asset.products?.find((product) => product.ancestry_ids?.length === 1) as Product;

            if (!product) return null;
            return (
              <SwiperSlide className="home-swiper__slide" key={`hero-${index}`}>
                <div
                  className="home-swiper__slide-bg d-flex flex-column justify-content-end p-4"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), url(${asset.preview_image?.url})`,
                  }}
                >
                  <div>
                    <Link to={`${Routes.PRODUCT}/${product?.product_id}`}>
                      <button className="btn btn-outline-primary">View More</button>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
