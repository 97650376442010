import useSWR from 'swr';
import { fetchBasketData } from 'utils/api/fetch-basked-data';
import { formatBasketData } from 'utils/format-basket-data';

export const useBaskets = (userId: number | string) => {
  const swrOptions = { keepPreviousData: true };
  const swrKey = userId ? ['basket-data', userId] : null;
  const swr = useSWR(swrKey, () => fetchBasketData(userId), swrOptions);

  const { data, isLoading: isBasketsLoading, mutate: mutateBaskets } = swr;
  let basketData: any[] = data?.objects ?? [];
  basketData = formatBasketData(basketData);

  return { basketData, isBasketsLoading, mutateBaskets };
};
