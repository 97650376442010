import { Model } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { errorHandler } from 'utils/error-handler';

export const fetchVideoURL = (videoID: number) => {
  return chipmunk.run(
    async ({ action }) => {
      const { object: videoUrl } = await action(Model.ASSET_VIDEO_URL, 'get', {
        params: {
          video_url_id: videoID,
        },
      });
      return videoUrl;
    },
    (e: Error) => {
      errorHandler(e, { title: 'Failed to load video url' });
    },
  );
};
