import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { chipmunk } from 'utils/chipmunk';
import { Model, Order, Product, Sort } from 'types';
import { errorHandler } from 'utils/error-handler';
import { productSchema } from 'pages/product/schemas';

export const fetchSimilarProducts = (product: IObject): Promise<Product[]> => {
  return chipmunk.run(
    async ({ action }) => {
      const { objects } = await action(Model.PRODUCTS, 'search', {
        body: {
          sort: Sort.ORIGINAL_RELEASE_DATE,
          order: Order.DESCENDING,
          per: 7,
          search: {
            filters: [
              ['preview_asset_id', 'exist'],
              ['id', 'ne', product.id],
              ['type', 'eq', product.type],
              ['category_ids', 'in', product?.default_layer?.meta.category_ids ?? []],
            ],
          },
        },
        schema: productSchema,
      });

      return objects;
    },
    (e: Error) => {
      errorHandler(e, { title: 'Failed to load similar products' });
    },
  );
};
