import { chipmunk } from 'utils';

export type ImgResizerSizes = 'cga' | 'vga' | 'hd' | 'xga' | 'fhd';

export interface IImgResizerOpts {
  method?: 'contain' | 'cover' | 'fill' | 'inside' | 'outside';
  width?: number;
  height?: number;
  size?: ImgResizerSizes;
}

export const resizedUrl = (url: string | Required<{ url: string }>, opts: IImgResizerOpts): string => {
  const method = opts['method'] ?? 'inside';
  const size = opts['size'] ?? 'xga';

  let width, height;
  if (opts['width']) width = opts['width'];
  if (opts['height']) height = opts['height'];

  if (!width && !height) {
    if (size === 'cga') {
      width = 320;
      height = 200;
    } else if (size === 'vga') {
      width = 640;
      height = 480;
    } else if (size === 'hd') {
      width = 1280;
      height = 720;
    } else if (size === 'xga') {
      width = 1024;
      height = 768;
    } else if (size === 'fhd') {
      width = 1920;
      height = 1080;
    }
  }

  const imgResizer = chipmunk.currentConfig().endpoints?.imgResizer;
  const call = `${method}/${width}x${height}/${url}`;

  return `${imgResizer}/${call}`;
};
