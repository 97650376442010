import React from 'react';
import cx from 'classnames';
import './styles.scss';

interface StripesProps {
  children?: any;
  className?: string;
}

export const Stripes: React.FC<StripesProps> = (props) => {
  const { children, className } = props || {};

  return (
    <div className={cx('stripes position-absolute overflow-hidden w-100 h-100', className)}>
      <div className="stripes__container">
        <div className="stripes__stripe1"></div>
        <div className="stripes__stripe2"></div>
        <div className="stripes__stripe3"></div>
        <div className="stripes__stripe4"></div>
        {children}
      </div>
    </div>
  );
};
