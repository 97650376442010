import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { productVideosAssetSchema } from 'pages/product/schemas';
import { chipmunk } from 'utils/chipmunk';

const mapVideos = (assets) => {
  return assets.map((asset) => {
    return {
      product_id: asset.products?.[0]?.product_id ?? null,
      language_name: asset.languages?.[0]?.name ?? null,
      id: asset.id,
      name: asset.name,
      classification: asset.classification,
      duration: asset.duration,
      preview_image: asset.preview_image,
    };
  });
};

export const fetchAllProductsVideos = (productId: number): Promise<IObject[]> => {
  return chipmunk.run(async ({ unfurl }) => {
    const filters = [
      ['preview_image_id', 'exist'],
      ['main_classification', 'eq', 'video'],
    ];

    const res = await unfurl('am.asset', 'search', {
      body: {
        product_ancestry_id: productId,
        search: {
          filters,
        },
      },
      schema: productVideosAssetSchema,
    });
    const videos = mapVideos(res.objects).sort((a, b) => a.product_id - b.product_id || a.name.localeCompare(b.name));

    return videos;
  });
};
