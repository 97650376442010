import React from 'react';

export const About: React.FC<{}> = () => {
  return (
    <div className="container mt-15 mb-5">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <h1 className="mb-10">About Lionsgate</h1>
          <p>
            Lionsgate (NYSE: LGF.A, LGF.B) encompasses world-class motion picture and television studio operations
            aligned with the STARZ premium global subscription platform to bring a unique and varied portfolio of
            entertainment to consumers around the world. The Company’s film, television, subscription and location-based
            entertainment businesses are backed by a 17,000-title library and a valuable collection of iconic film and
            television franchises. A digital age company driven by its entrepreneurial culture and commitment to
            innovation, the Lionsgate brand is synonymous with bold, original, relatable entertainment for audiences
            worldwide.
          </p>
        </div>
      </div>
    </div>
  );
};
