import { IResult } from '@mediafellows/chipmunk';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { IGetPurposeGroupEntities } from '@mediafellows/tuco/dist/lib/tasks';
import { Category, Language } from 'types';
import { tuco } from 'utils/chipmunk';
import { errorHandler } from 'utils/error-handler';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type ZBasicStore = {
  categories: Category[];
  languages: Language[];
  isLoadingSalesContacts: boolean;
  salesContacts: IObject[];
  isBasicDataLoaded: boolean;
};

export const useBasicStore = create<ZBasicStore>()(
  devtools(() => ({
    categories: [],
    languages: [],
    isLoadingSalesContacts: true,
    salesContacts: [],
    isBasicDataLoaded: false,
  })),
);

export const loadBasics = async () => {
  try {
    const data = (await tuco('gcuiBasics', { isMm3: true })) as IResult;
    const { categories = [], languages = [] } =
      (data?.object as { categories: Category[]; languages: Language[] }) || {};

    useBasicStore.setState({ categories, languages, isBasicDataLoaded: true });
  } catch (error) {
    errorHandler(error, { title: 'Failed to load basic data' });
  }
};

export const loadSalesContacts = async () => {
  try {
    useBasicStore.setState({ isLoadingSalesContacts: true });
    const data = await tuco('getPurposeGroupUsers', {
      purpose: 'sales_contacts',
    } as IGetPurposeGroupEntities);
    const { objects: contacts = [] } = data || {};
    useBasicStore.setState({ salesContacts: contacts });
  } catch (error) {
    errorHandler(error, { title: 'Failed to load sales contacts' });
  } finally {
    useBasicStore.setState({ isLoadingSalesContacts: false });
  }
};
