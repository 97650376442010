import { productListSchema } from '@mediafellows/tuco/dist/lib/schemas';
import { chipmunk, tuco } from 'utils/chipmunk';
import { errorHandler } from 'utils/error-handler';

export const fetchHeroProducts = () => {
  return chipmunk.run(
    async () => {
      const data = await tuco('gcuiCarousel', {
        purpose: 'hero_slider_home',
        productSchema: productListSchema.replace('product_state,', 'product_state,product_type'),
      });

      const { objects: products = [] } = data || {};
      return products;
    },
    (e: Error) => {
      errorHandler(e, { title: 'failed to load hero products' });
    },
  );
};
