import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { fetchProduct } from 'utils/api/fetch-product';
import { fetchMarketingAssets } from 'utils/api/fetch-marketing-assets';
import { fetchScreeners } from 'utils/api/fetch-screeners';
import { fetchTrailers } from 'utils/api/fetch-trailers';
import { fetchSeasons } from 'utils/api/fetch-seasons';
import { fetchAllProductsVideos } from 'utils/api/fetch-all-product-videos';
import { fetchAllVideos } from 'utils/api/fetch-all-videos';
import { fetchSimilarProducts } from 'utils/api/fetch-similar-products';
import { Model, Product3Types, Recommendation } from 'types';
import { chipmunk } from 'utils/chipmunk';

const isFeaturedVideo = (video: IObject) => ['video/promo', 'video/trailer'].includes(video.classification);

const getFeaturedVideo = (screeners: IObject[], featuredVideo: IObject, allVideosData: IObject[]) => {
  if (featuredVideo) {
    return featuredVideo;
  }
  if (screeners?.length) {
    return screeners[0];
  }

  if (allVideosData) {
    return allVideosData.find(isFeaturedVideo);
  }
};

export type fetchAllProductDataResult = {
  product?: IObject;
  similarProducts?: IObject[];
  marketingAssets?: IObject;
  screeners?: IObject[];
  trailers?: IObject[];
  seasons?: IObject[];
  allVideos?: IObject[];
  featuredVideo?: IObject;
  episodes_count?: {
    effective_episodes_count: number;
  };
};

export const fetchAllProductData = async (
  productId: number,
  type: 'product' | 'recommendation',
  recommendation?: Recommendation,
): Promise<fetchAllProductDataResult> => {
  const product = await fetchProduct(productId);
  const isProductType = type === 'product';

  const { objects: ancestryInfos } = await chipmunk.action(Model.PRODUCT_ANCESTRY_INFO, 'get', {
    params: { product_ids: productId },
  });

  const episodes_count = ancestryInfos[0]?.effective_episodes_count;

  const isSeries = product?.type === Product3Types.SERIES;

  const [similarProducts, marketingAssets, initialScreeners, initialTrailers, seasonsData, allVideos] =
    await Promise.all([
      fetchSimilarProducts(product),
      fetchMarketingAssets(productId, type),
      !isSeries ? fetchScreeners(productId) : [],
      !isSeries ? fetchTrailers(productId) : [],
      isSeries ? fetchSeasons(productId) : null,
      isSeries && isProductType ? fetchAllProductsVideos(productId) : [],
    ]);
  const seasons = seasonsData?.descendents.filter((item) => item.type === Product3Types.SEASON);

  const allVideosData = isSeries && seasonsData?.descendents ? await fetchAllVideos(seasons) : null;

  const featuredVideo = getFeaturedVideo(
    seasons ? seasons[0].screeners : initialScreeners,
    marketingAssets.find(isFeaturedVideo),
    allVideosData,
  );

  if (recommendation) {
    if (isSeries) {
      seasons.map((season) => {
        season.screeners = season.screeners.filter((screener) => recommendation.asset_ids.includes(screener.id));
        season.trailers = season.trailers.filter((trailer) => recommendation.asset_ids.includes(trailer.id));
      });
    } else {
      initialScreeners.filter((screener) => recommendation.asset_ids.includes(screener.id));
      initialTrailers.filter((trailer) => recommendation.asset_ids.includes(trailer.id));
    }
  }

  return {
    product,
    similarProducts,
    marketingAssets,
    screeners: initialScreeners,
    trailers: initialTrailers,
    seasons: seasons || [],
    allVideos,
    featuredVideo,
    episodes_count,
  };
};
